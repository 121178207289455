import gql from 'graphql-tag';

const INCLUDE_ALL_COUNTS = gql`
    mutation includeAllCounts($checked: Bool!)  {
        includeAllCounts(checked: $checked) @client {
            null
        }
    }
`

export default INCLUDE_ALL_COUNTS