import gql from 'graphql-tag';

const ADD_ITEM_UPC = gql`
    mutation addItemUpc($upccode: String!, $locationid: String!) {
        addItemUpc(upccode: $upccode, locationid: $locationid) @client {
            upccode
        }
    }
`

export default ADD_ITEM_UPC