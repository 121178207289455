import React, { Component } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Grid } from '@material-ui/core';


const Loading = () => {
  
    return (
      <Grid style={{ width: "100%", height: "100vh" }} container justify="center" alignContent="center" alignItems="center" >
            <CircularProgress size={80} />
      </Grid>
    )
}

export default Loading
