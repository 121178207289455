import React, { Component } from 'react'
import Modal from '@material-ui/core/Modal'
import { withStyles } from '@material-ui/core/styles';
// import Fab from '@material-ui/core/Fab'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import AddCircleOutline from '@material-ui/icons/AddCircleOutline'
import Typography from '@material-ui/core/Typography'
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'
import CircularProgress from '@material-ui/core/CircularProgress'
import Formcontrol from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import TextField from '@material-ui/core/TextField'
import { compose, graphql } from 'react-apollo'
import INCREMENT_COUNT_SKU from '../mutations/INCREMENT_COUNT_SKU'
import INCREMENT_COUNT_UPC from '../mutations/INCREMENT_COUNT_UPC'
import NEW_ITEM_SKU from '../mutations/NEW_ITEM_SKU'
import NEW_ITEM_UPC from '../mutations/NEW_ITEM_UPC'
import firebase from '../../firebaseConfig'
import ITEM_DETAILS from '../../Receiving/queries/ITEM_DETAILS'
import GET_COUNTED_ITEMS from '../queries/GET_COUNTED_ITEMS'
import gql from 'graphql-tag'

export class ManualEntry extends Component {

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      loader: false,
      value: '',
      option: 'sku',
      added: false,
      error: ''
    }

    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
  }

  onSubmit(e) {
    e.preventDefault();
    this.setState({
      added: false,
      loader: true,
      error: ''
    })
    let exists = false,
        self   = this;

    const { getCountedItems: { countedItems }, client } = this.props;
    const token = sessionStorage.getItem('token');
    const { countLocation } = client.readQuery({
      query: gql`{ countLocation @client { internalid, name, __typename } }`
    })

    console.log(countLocation)

    if (this.state.option === "sku") {

      countedItems.forEach(item => {

        // Check if item exists in the table
        if (item.name.toUpperCase() === this.state.value.trim()) {

          exists = true;

          this.props.incrementCountSku({ variables: { sku: this.state.value.trim() } })

          client.writeData({
            data: { 
              // lastModified: item.upccode, 
              lastModified: item.name, 
            }
          })

          this.setState({
            added: true,
            loader: false,
            value: ''
          })
        }
      });

      if (!exists) {
        firebase.auth().onAuthStateChanged(user => {
          if (user && self._isMounted) {

            // user.getIdToken().then(idToken => {

              client.query({
                query: ITEM_DETAILS,
                variables: { token: token, sku: this.state.value.trim(), locationid: countLocation.internalid }
              }).then(({ data: { findItemDetails } }) => {

                console.log(findItemDetails);

                // Call mutation to add item
                if (findItemDetails.err === null) {

                  this.props.newItemSku({ variables: { sku: this.state.value.trim(), locationid: countLocation.internalid } });

                  client.writeData({
                    data: { 
                      lastModified: findItemDetails.name.split(":")[1].trim()
                    }
                  })

                  this.setState({
                    added: true,
                    loader: false,
                    value: ''
                  })
                }
                else {
                  
                  this.setState({
                    error: findItemDetails.err,
                    loader: false
                  })
                }
              })
                .catch(err => {
                  console.error(err);
                  this.setState({
                    error: 'Invalid Sku!',
                    loader: false
                  })
                })
            // })
          }
        })
      }
    }
    else {

      countedItems.forEach(item => {
        if (item.upccode === this.state.value.trim()) {
          exists = true;

          // increment qty upc
          this.props.incrementCountUpc({ variables: { upccode: this.state.value.trim() } })

          client.writeData({
            data: { 
              // lastModified: item.upccode
              lastModified: item.name
            }
          })

          this.setState({
            added: true,
            loader: false,
            value: ''
          })
        }
      })

      if (!exists) {

        // fetch item details
        firebase.auth().onAuthStateChanged(user => {

          if (user && self._isMounted) {

            // user.getIdToken().then(idToken => {

              client.query({
                query: ITEM_DETAILS,
                variables: { token: token, upccode: this.state.value.trim(), locationid: countLocation.internalid }
              }).then(({ data: { findItemDetails } }) => {

                // Call mutation to add item
                if (findItemDetails.err === null) {
                  this.props.newItemUpc({ variables: { upccode: findItemDetails.upccode, locationid: countLocation.internalid } })

                  client.writeData({
                    data: { 
                      lastModified: findItemDetails.name.split(":")[1].trim()
                    }
                  })

                  this.setState({
                    added: true,
                    loader: false,
                    value: ''
                  })
                }
                else {
                  this.setState({
                    error: findItemDetails.err,
                    loader: false
                  })
                }
              })
                .catch(err => {
                  console.error(err);
                  this.setState({
                    error: 'Invalid Upc Code!',
                    loader: false
                  })
                })
            // })
          }
        })
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { classes, disabled } = this.props;
    return (
      <div>
        <Button variant="contained" size="small" disabled={disabled === true ? true : false} className={classes.rightIcon}
        style={{ marginLeft: "0" }}
          onClick={(e) => { this.setState({ open: true }) }}
        >
          Add Manually
          <AddCircleOutline className={classes.rightIcon} />
        </Button>
        <Modal open={this.state.open} >
          <div style={getModalStyle()} className={classes.paper} >
            <Typography variant="h5" id="modal-title">
              Add Item
            </Typography>
            <Divider style={{ width: "100%" }} />
            <form style={{ marginTop: "20px" }} onSubmit={this.onSubmit} >
              <div>
                <Formcontrol fullWidth>
                  <FormLabel></FormLabel>
                  <RadioGroup
                    value={this.state.option} row
                    onChange={(e) => { this.setState({ option: e.target.value, value: '' }) }}
                  >
                    <FormControlLabel
                      value="sku"
                      control={<Radio color="secondary" />}
                      label="Sku"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      value="upccode"
                      control={<Radio color="secondary" />}
                      label="Upc"
                      labelPlacement="end"
                    />
                  </RadioGroup>
                </Formcontrol>
                <div>
                  <TextField
                    fullWidth
                    value={this.state.value}
                    inputProps={{
                      'required': true
                    }}
                    onChange={(e) => { this.setState({ value: e.target.value.toUpperCase() }) }}
                    label={this.state.option === "sku" ? "Sku" : "Upc"}
                    placeholder={this.state.option === "sku" ? "Ex: BA-1256-117-W-OLIVE-O" : ""}
                  />
                </div>
                {
                  this.state.loader ?
                    <div style={{ marginTop: "30px", textAlign: "center" }} >
                      <CircularProgress />
                    </div>
                    :
                    <div style={{ marginTop: "30px" }}>
                      <Button type="submit" variant="contained" color="primary" >
                        SUBMIT
                      </Button>
                      <Button style={{ marginLeft: "20px" }} variant="contained"
                        onClick={(e) => { this.setState({ open: false }) }}
                      >
                        EXIT
                      </Button>
                    </div>
                }
              </div>
              <div>
                {
                  this.state.added ?
                    <p style={{ color: "green" }}>
                      Item has been added!
                    </p> :
                    null
                }
              </div>
              <div>
                {
                  this.state.error === '' ?
                    null
                    :
                    <p style={{ color: "red" }}>
                      {this.state.error}
                    </p>
                }
              </div>
            </form>
          </div>
        </Modal>
      </div>
    )
  }
}

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const styles = theme => ({
  paper: {
    position: 'absolute',
    width: theme.spacing.unit * 40,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: 'none',
    [theme.breakpoints.down('xs')]: {
      width: theme.spacing.unit * 40,
      padding: theme.spacing.unit * 2
    }
  },
  rightIcon: {
    marginLeft: theme.spacing.unit / 2,
  }
})

const ComposedComponent = compose(
  graphql(GET_COUNTED_ITEMS, { name: 'getCountedItems' }),
  graphql(NEW_ITEM_UPC, { name: 'newItemUpc' }),
  graphql(NEW_ITEM_SKU, { name: 'newItemSku' }),
  graphql(INCREMENT_COUNT_SKU, { name: 'incrementCountSku' }),
  graphql(INCREMENT_COUNT_UPC, { name: 'incrementCountUpc' }),
)(ManualEntry)

export default withStyles(styles)(ComposedComponent)
