import GET_COUNTED_ITEMS from '../queries/GET_COUNTED_ITEMS'
// import gql from 'graphql-tag'

const removeItem = (_, { internalid }, { cache }) => {

    const previousState = cache.readQuery({
        query: GET_COUNTED_ITEMS
    })

    cache.writeQuery({
        query: GET_COUNTED_ITEMS,
        data: {
          countedItems: previousState.countedItems.filter((item) => item.internalid !== internalid )
        }
    })

    return null
  }

  export default removeItem