import ITEM_DETAILS from '../../Receiving/queries/ITEM_DETAILS'
import GET_COUNTED_ITEMS from '../queries/GET_COUNTED_ITEMS'
// import gql from 'graphql-tag'


const newItemSku = (_, { sku, locationid }, { cache }) => {

    const token = sessionStorage.getItem('token');

    const { findItemDetails } = cache.readQuery({
        query: ITEM_DETAILS,
        variables: { sku: sku, token: token, locationid: locationid }
    })

    const previousState = cache.readQuery({
        query: GET_COUNTED_ITEMS
    })

    const newItem = {
        quantity: 1,
        internalid: findItemDetails.internalid,
        name: findItemDetails.name.split(':')[1].trim(),
        upccode: findItemDetails.upccode,
        location_average_cost: findItemDetails.location_average_cost ? findItemDetails.location_average_cost : findItemDetails.average_cost,
        on_hand: parseInt(findItemDetails.on_hand)
        // __typename: "ItemDetails"
    }

    cache.writeQuery({
        query: GET_COUNTED_ITEMS,
        data: {
            countedItems: [
                newItem,
                ...previousState.countedItems,
            ]
        }
    })

    return null
}

export default newItemSku