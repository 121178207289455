import React, { Component } from 'react';
import gql from 'graphql-tag';
import logo from '../assets/Oak+Fort-logo-thin-black.svg'
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import firebase from '../firebaseConfig'
import CircularProgress from '@material-ui/core/CircularProgress'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import LogRocket from 'logrocket';

const LOGIN_QUERY = gql`
    query( $email: String!, $password: String!, $app: String!) {
            checkAccount (email: $email, password: $password, app: $app){
                uname
                passwd
                token
                err
        }
    }
`

export class Login extends Component {

    constructor(props) {
        super(props);

        this.state = {
            error: false,
            errMessage: '',
            isLoading: false,
            email: '',
            password: ''
        }
    }

    handleClose = () => {
        this.setState({ error: false });
    };

    onSubmit = async (e) => {
        e.preventDefault();

        const { client } = this.props;
        this.setState({
            error: false,
            isLoading: true
        })

        try {
            // Check if user has a Netsuite Account
            let { data } = await client.query({
                query: LOGIN_QUERY,
                variables: { email: this.state.email, password: this.state.password, app: 'inventory' }
            })

            const { uname, passwd, token } = data.checkAccount;
            sessionStorage.setItem('token', token);

            // Check if creds were valid
            if (uname && uname !== null && passwd && passwd !== null) {

                // Attach Log Rocket session to the user
                LogRocket.identify(this.state.email);

                firebase.auth().signInWithEmailAndPassword(uname, passwd)
                .then(() => {
                    this.setState({
                        isLoading: false
                    })
                    this.props.history.push('/home');
                })
                .catch(err => {
                    console.error(err)
                    this.setState({
                        isLoading: false
                    })
                    alert(err);
                })
            }
            else {
                // Else show the error message
                this.setState({
                    error: true,
                    errMessage: data.checkAccount.err,
                    isLoading: false
                })
            }

        } catch (err) {
            console.error(err);
            this.setState({
                error: true,
                isLoading: false
            })
        }
    }

    render() {
        return (
            <div className="login-container">
                <img src={logo} className="App-logo" alt="logo" />
                <Divider style={{ width: "100%" }} />
                <Typography variant="h2" style={{ marginTop: "30px", fontSize: "2em" }} gutterBottom>
                    Welcome to Oak and Fort Inventory System
                </Typography>
                <form onSubmit={this.onSubmit} className="login-form" >
                    <Typography variant="h6" style={{ marginBottom: "40px" }}>
                        Please sign in with your Netsuite account.
                            </Typography>
                    <div style={{ width: "100%" }} >
                        <TextField
                            variant="outlined"
                            className="inputStyle"
                            fullWidth
                            value={this.state.email}
                            label="Email"
                            placeholder="your-email@oakandfort.com"
                            inputProps={{ type: 'email', required: true }}
                            onChange={(e) => { this.setState({ email: e.target.value }) }}
                        />
                    </div>
                    <div style={{ width: "100%", marginTop: "20px" }} >
                        <TextField
                            variant="outlined"
                            className="inputStyle"
                            fullWidth
                            value={this.state.password}
                            label="Password"
                            onChange={(e) => { this.setState({ password: e.target.value }) }}
                            inputProps={{ type: 'password', required: true }}
                        />
                    </div>
                    <div style={{ padding: "10px 0", width: "100%" }} >
                        {
                            this.state.error ?
                                <Typography style={{ color: "red" }} variant="subtitle1" id="modal-title">
                                    {this.state.errMessage}
                                </Typography>
                                :
                                null
                        }
                    </div>
                    {
                        this.state.isLoading ?
                            <div style={{ textAlign: "center" }} >
                                <CircularProgress />
                            </div>
                            :
                            <div style={{ marginTop: "30px", width: "100%" }}>
                                <Button fullWidth type="submit" variant="contained" color="primary" size="large"  >
                                    Submit
                                </Button>
                            </div>
                    }
                </form>
            </div>
        )
    }
}


//   Login.propTypes = {
//     classes: PropTypes.object.isRequired,
//   };

export default Login
