// import ITEM_DETAILS from '../queries/ITEM_DETAILS'
import SCANNED_ITEMS from '../queries/SCANNED_ITEMS'
import gql from 'graphql-tag'


const addItemUpc = (_, { upccode, locationid }, { cache }) => {

    const { scannedItems } = cache.readQuery({
        query: SCANNED_ITEMS
    })
    let parsedItems = JSON.parse(scannedItems);

    if(!parsedItems[upccode]) {
        const { itemDetails } = cache.readQuery({
            query: gql`{ itemDetails @client }`
        })
        const parsedItemDetails = JSON.parse(itemDetails);
    
        const newItem = {
            actualqty: 1,
            baseprice: parsedItemDetails.baseprice,
            internalid: parsedItemDetails.internalid,
            name: parsedItemDetails.name,
            upccode: parsedItemDetails.upccode,
            expectedqty: 0,
            verified: false,
            notes: '',
            __typename: "ItemDetails"
        }
        parsedItems[upccode] = newItem;
    
        cache.writeData({
            data: { scannedItems: JSON.stringify(parsedItems) }
        })
    }

    return null
}

export default addItemUpc