import GET_CHECKBOXES from '../queries/GET_CHECKBOXES'

const toggleCheckbox = (_, { key }, { cache }) => {

    const previousState = cache.readQuery({
        query: GET_CHECKBOXES
    })

    cache.writeQuery({
        query: GET_CHECKBOXES,
        data: {
            submitCheckboxes: previousState.submitCheckboxes.map(item => {
                if(item.key !== key ) {
                    return item
                }
                
                return {
                    ...item,
                    checked: !item.checked
                }
            })
        }
    })

    return null
}

export default toggleCheckbox