import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid';
import Stepper from '@material-ui/core/Stepper'
import StepLabel from '@material-ui/core/StepLabel'
import Step from '@material-ui/core/Step'
import Button from '@material-ui/core/Button'
import Home from '@material-ui/icons/Home'
import gql from 'graphql-tag'
import { withApollo, Query, compose, graphql } from 'react-apollo'
import LocationForm from './LocationForm'
import CountItems from './CountItems'
import SaveCount from './SaveCount'

const GET_COUNT_STEP = gql`
  {
    countStep @client
  }
`

const LocationFormWithClient = withApollo(LocationForm)
const CountItemsWithClient = withApollo(CountItems)
const SaveCountWithClient = withApollo(SaveCount)


function getSteps() {
  return ['Location Details', 'Count Items', 'Verify/Submit FIC'];
}

function getStepContent(stepIndex) {
  switch (stepIndex) {
    case 0:
      return <LocationFormWithClient />
    case 1:
      return <CountItemsWithClient />;
    case 2:
      return <SaveCountWithClient />;
    default:
      return 'Unknown stepIndex';
  }
}

export class Count extends Component {

  downloadAllFiles(parsed_documents) {

    const { getCountOption: { countOption } } = this.props;

    for(let i = 0; i < parsed_documents.length ; i++) {
       let anchor = document.createElement("a");
       anchor.setAttribute('href', "data:text/csv;charset=utf-8," + encodeURIComponent(parsed_documents[i]));
       anchor.setAttribute('download', (countOption === "worksheet" ? "Inventory_Worksheet_" : "Inventory_Adjustment_" )  + (i+1) + "_" + new Date().getTime() + ".csv");
       anchor.click();
       anchor.remove();
    }

  }

  render() {
    const { classes, client, getDocuments: { documents }, getCountOption: { countOption } } = this.props;
    const steps = getSteps();

    let parsed_documents = [];

    if(documents) {
      parsed_documents = JSON.parse(documents);
    }

    return (
      <div className={classes.countContainer}>
        <Paper className={classes.root}>
          <Typography variant="h5" gutterBottom>
            Inventory Count
          </Typography>
        </Paper>
        <Query query={GET_COUNT_STEP} >
          {
            ({ data: { countStep } }) => {

              return (
                <Grid container spacing={40} justify="center" alignItems="center" className={classes.outerGrid} >
                  <Grid item xs={12} >
                    <Paper elevation={1}>
                      <Stepper activeStep={countStep} alternativeLabel >
                        {steps.map(label => (
                          <Step key={label} >
                            <StepLabel>
                              {label}
                            </StepLabel>
                          </Step>
                        ))}
                      </Stepper>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} >
                    <Grid container >
                      <Grid item xs={12} >
                        {countStep === steps.length ? (
                          <div>
                            <div style={{ fontSize: "64px", color: 'green' }} >
                              <i className="fas fa-check-circle"></i>
                            </div>
                            <Typography variant="h6" style={{ color: 'green' }} >
                              {
                                countOption === "worksheet" ?
                                <span>Inventory Worksheet has been generated successfully.</span>
                                :
                                <span> Inventory Adjustment has been generated successfully. </span>
                              }
                              
                            </Typography>
                            <Button onClick={(e) => this.downloadAllFiles(parsed_documents)} style={{ marginTop: "30px", marginRight: "20px" }} size="large" color="primary" variant="contained" >
                              {
                                countOption === "worksheet" ?
                                <span>Download All Worksheets</span>
                                :
                                <span>Download All Adjustments</span>
                              }
                              <span style={{ marginLeft: "10px" }} className="fas fa-download"></span>
                            </Button>

                            {/* <Typography variant="h6" style={{ color: 'black' }} >
                              Inventory Count # - {inventoryCountId}
                            </Typography> */}

                            <Button size="large" style={{ marginTop: "30px" }} color="primary" variant="contained"
                              onClick={(e) => {

                                client.writeData({
                                  data: {
                                    countStep: 0,
                                    submitLocation: null,
                                    submitCheckboxes: [],
                                    fetchCounts: false,
                                  }
                                })
                              }}
                            >
                              Done
                             {/*<Home style={{ marginLeft: "10px" }} />*/}
                            </Button>
                            <Button size="large" style={{ marginTop: "30px", marginLeft: "20px" }} color="primary" variant="contained"
                              onClick={(e) => {
                                client.writeData({
                                  data: {
                                    countStep: 0,
                                    submitLocation: null,
                                    submitCheckboxes: [],
                                    fetchCounts: false,
                                  }
                                })
                                this.props.history.push('/home');
                              }}
                            >
                              Home
                             <Home style={{ marginLeft: "10px" }} />
                            </Button>
                          </div>
                        ) :
                          (
                            <div>
                              {getStepContent(countStep)}
                            </div>
                          )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )
            }
          }
        </Query>
      </div>
    )
  }
}

const styles = theme => ({
  root: {
    // ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
  },
  countContainer: {
    minHeight: "60vh",
    marginTop: '65px',
    textAlign: "center",
    background: "#f5f5f5"
  },
  outerGrid: {
    paddingTop: "20px",
    maxWidth: '100%',
    margin: "0 auto",
  }
})

Count.propTypes = {
  classes: PropTypes.object.isRequired
}

const composedComponent = compose(
  graphql(gql`{ inventoryCountId @client}`, { name: 'inventoryCountId' }),
  graphql(gql`{documents @client}`, { name: 'getDocuments' }),
  graphql(gql`{countOption @client}`, { name: 'getCountOption' }),
)(Count)

export default withStyles(styles)(composedComponent);
