import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import Today from '@material-ui/icons/Today'

export class SearchCriteria extends Component {

  render() {

    const { startDate, endDate, submitLocation } = this.props;


    return (
        <div style={{padding: "20px", backgroundColor: "#fff", fontSize: "14px"}}>
        <Grid container style={{textAlign: "left"}} spacing={16} >
            <Grid item xs={6}  >
               <strong>Location:</strong> { submitLocation }
            </Grid>
            <Grid item xs={6} style={{textAlign: "right"}}  >
                <Today style={{ verticalAlign: "middle" }} /> <span> { new Date().toDateString() } </span>
            </Grid>
            <Grid item xs={12} >
                <Divider style={{ width: "100%" }} />
            </Grid>
            <Grid item xs={6}  >
                <strong> Start Date: </strong> { new Date(startDate).toDateString() }
            </Grid>
            <Grid item xs={6} style={{textAlign: "right"}} >
                <strong> End Date: </strong> { new Date(endDate).toDateString() }
            </Grid>
        </Grid>
      </div>
    )
  }
}

export default SearchCriteria
