import gql from 'graphql-tag';

const TOGGLE_ALL_CONFIRM_DIFF = gql`
    mutation toggleAllConfirmDiff($checked: Bool!) {
        toggleAllConfirmDiff(checked: $checked) @client {
            checked
        }
    }
`

export default TOGGLE_ALL_CONFIRM_DIFF