import gql from 'graphql-tag';

const ADD_CHECKBOX = gql`
    mutation addCheckbox($key: String! , $checked: Bool!) {
        addCheckbox(key: $key, checked: $checked) @client {
            checked
        }
    }
`

export default ADD_CHECKBOX