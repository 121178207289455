import SCANNED_ITEMS from '../queries/SCANNED_ITEMS'

const toggleAllConfirmDiff = (_, { checked }, { cache }) => {
    const { scannedItems } = cache.readQuery({
        query: SCANNED_ITEMS
    })

    let parsedItems = JSON.parse(scannedItems)

    for (const key in parsedItems) {
        parsedItems[key].verified = checked;
    }

    cache.writeData({
        data: {
            scannedItems: JSON.stringify(parsedItems)
        }
    })

    return null
}

export default toggleAllConfirmDiff