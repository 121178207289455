import gql from 'graphql-tag';

const NEW_ITEM_UPC = gql`
    mutation newItemUpc($upccode: String!, $locationid: String) {
        newItemUpc(upccode: $upccode, locationid: $locationid) @client {
            upccode
        }
    }
`

export default NEW_ITEM_UPC