import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#000'
      },
      secondary: {
        main: '#2196f3'
      },
      error: {
        main: "#f00"
      }
    },
    typography: {
      useNextVariants: true,
    },
})

export default theme;
