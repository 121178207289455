import LogRocket from 'logrocket';

if(window.location.hostname.indexOf('localhost') === -1) {
    LogRocket.init('nl6p5j/o-f-inventory-system', {
        console: {
          isEnabled: {
            log: false,
            debug: false,
            warn: false,
            info: false
          }
        },
        shouldCaptureIP: false
    });
}


