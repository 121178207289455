import React from 'react';
import Loading from  '../components/Loading'
import { Route, Redirect } from 'react-router-dom';

function PrivateRoute({ isLoading, isAuthenticated, component: Component, ...rest }) {
    return (
      <Route
        {...rest}
        render={props => {
  
          if(isLoading) {
            return <Loading></Loading>
          }
  
          return isAuthenticated ? 
          <Component {...props} /> :
          <Redirect
              to={{
                pathname: "/",
                state: { from: props.location }
              }}
            />
        }
        }
      />
    )
}

export default PrivateRoute