import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
// import { Paper } from '@material-ui/core';

const CustomTableCell = withStyles(theme => ({
    head: {
        //   backgroundColor: theme.palette.common.black,
        //   color: theme.palette.common.white,
        color: "black",
        letterSpacing: "1px",
        textTransform: "uppercase"
    }
}))(TableCell);

const CustomExpansionPanel = withStyles(theme => ({
    root: {
        boxShadow: 'none',
        marginLeft: "10px",
        marginTop: "10px",
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            border: '1px solid rgba(0,0,0,.125)',
            marginLeft: "10px",
            marginTop: "10px",
            borderBottom: "none"
        }
    },
    expanded: {
        margin: "auto"
    }
}))(ExpansionPanel);

const CustomExpansionPanelSummary = withStyles({
    root: {
        backgroundColor: '#eee',
        // border: '1px solid rgba(0,0,0,.125)',
        marginBottom: -1,
        // minHeight: "auto",
        // '&$expanded': {
        //   minHeight: "auto",
        //   margin: "12px 0"
        // },
    }
})(ExpansionPanelSummary)

const CustomExpansionPanelDetails = withStyles(theme => ({
    root: {
        padding: 0,
        boxShadow: "none",
        display: "block"
    },
}))(ExpansionPanelDetails);

export class OrderStatusModal extends Component {

    constructor(props) {

        super(props);
    }

    render() {

        const { order: { details }, classes } = this.props;

        let completed = [],
            incomplete = [],
            submissions = [];

        if (details.itemFulfillmentsList && details.itemFulfillmentsList.complete) {
            for (let key in details.itemFulfillmentsList.complete) {
                let item = details.itemFulfillmentsList.complete[key];
                completed.push({
                    key: key,
                    internalid: item.internalid,
                    items: item.items
                })
            }
        }

        if (details.itemFulfillmentsList && details.itemFulfillmentsList.incomplete) {
            for (let key in details.itemFulfillmentsList.incomplete) {
                let item = details.itemFulfillmentsList.incomplete[key];
                incomplete.push({
                    key: key,
                    internalid: item.internalid,
                    items: item.items
                })
            }
        }

        for (let key in details.submissions) {
            let submission = details.submissions[key];
            submissions.push(submission);
        }

        submissions.reverse();

        return (
            <div style={getModalStyle()} className={classes.paper}>
                <Grid container justify="space-around" spacing={24}>
                    <Grid xs={12} item>
                        <Typography style={{ backgroundColor: "#fff", padding: "10px 0" }} variant="h6">
                            Order: {details.transactionNum}
                        </Typography>
                        
                        {
                            details.sentFrom ?
                            <Typography style={{padding: "5px 0"}} variant="subtitle1">
                                Sent From: { details.sentFrom }
                            </Typography>
                            :
                            null
                        }
                        <Typography style={{ backgroundColor: "#fff", padding: "5px 0", color: "#616161" }} variant="subtitle2" >
                            Status: {details.status}
                        </Typography>
                        <Divider style={{ width: "100%" }} />
                    </Grid>

                    <Grid item xs={12}>
                        {/* <Divider style={{ width: "100%" }} /> */}
                    </Grid>
                    <Grid item xs={12}>
                        <CustomExpansionPanel style={{ borderRadius: "0" }}>
                            <CustomExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography>
                                    Order Summary
                                </Typography>
                            </CustomExpansionPanelSummary>
                            <CustomExpansionPanelDetails>
                                {/* <Paper style={{borderRadius: "0"}}> */}
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <CustomTableCell padding="dense">Item</CustomTableCell>
                                            <CustomTableCell padding="dense">Quantity Fulfilled</CustomTableCell>
                                            <CustomTableCell padding="dense">Quantity Received</CustomTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            details.summary.map(item => (
                                                <TableRow key={item.internalid}>
                                                    <TableCell> {item.description} </TableCell>
                                                    <TableCell> {item.quantityFulfilled} </TableCell>
                                                    <TableCell> {item.quantityReceived} </TableCell>
                                                </TableRow>
                                            ))
                                        }
                                    </TableBody>
                                </Table>
                                {/* </Paper> */}
                            </CustomExpansionPanelDetails>
                        </CustomExpansionPanel>
                    </Grid>
                    <Grid item xs={12}>
                        {/* <Divider style={{ width: "100%" }} /> */}
                    </Grid>

                    <Grid item xs={12}>
                        <Typography style={{ padding: "10px 20px", letterSpacing: "1px", backgroundColor: "#e3f2fd", color: "#0d47a1", textTransform: "uppercase", borderLeft: "5px solid #2196f3" }}>
                            Previous Submissions
                        </Typography>
                        {
                            submissions.map((submission, index) => (
                                <CustomExpansionPanel key={index}>
                                    <CustomExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography>
                                            Submitted on <strong>{submission.lastUpdated}</strong> by <strong>{submission.employeeName}</strong>
                                        </Typography>
                                    </CustomExpansionPanelSummary>
                                    <CustomExpansionPanelDetails>
                                        {
                                            submission.status ?
                                            <Typography style={{padding: "25px"}}>
                                                Status: <strong>{ submission.status }</strong>
                                            </Typography>
                                            :
                                            null
                                        }
                                        
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <CustomTableCell padding="dense" >Item</CustomTableCell>
                                                    <CustomTableCell padding="dense">Expected Quantity</CustomTableCell>
                                                    <CustomTableCell padding="dense"> Quantity Scanned </CustomTableCell>
                                                    <CustomTableCell padding="dense">Quantity Received </CustomTableCell>
                                                    <CustomTableCell padding="dense">Variance</CustomTableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    submission.submitted.items.map((item, index) => (
                                                        <TableRow key={item.internalid} >
                                                            <TableCell> {item.name.split(':')[1]} </TableCell>
                                                            <TableCell> {item.expectedqty} </TableCell>
                                                            <TableCell> 
                                                                {item.adjustedActualQtyFrom ? item.adjustedActualQtyFrom : item.actualqty} 
                                                            </TableCell>
                                                            <TableCell> {item.actualqty} </TableCell>
                                                            <TableCell>
                                                                {
                                                                    item.positiveVariance ? 
                                                                    item.positiveVariance
                                                                    :
                                                                    item.negativeVariance ? -item.negativeVariance : 0
                                                                }
                                                            </TableCell>
                                                        </TableRow>
                                                    ))
                                                }
                                            </TableBody>
                                        </Table>
                                    </CustomExpansionPanelDetails>
                                </CustomExpansionPanel>
                            ))
                        }

                    </Grid>

                    <Grid item xs={12}>
                        {/* <Divider style={{ width: "100%" }} /> */}
                    </Grid>
                    {
                        details.itemFulfillmentsList ?
                            <Grid xs={12} item>
                                <Typography style={{ padding: "10px 20px", letterSpacing: "1px", backgroundColor: "#e8f5e9", color: "#1b5e20", textTransform: "uppercase", borderLeft: "5px solid #4caf50" }}>
                                    Fullfilments Completely Received
                                </Typography>

                                {details.itemFulfillmentsList.complete ?
                                    completed.map((elem, index) => (
                                        <CustomExpansionPanel key={elem.key} style={{ borderRadius: "0" }} >
                                            <CustomExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                                <Typography> {elem.key} </Typography>
                                            </CustomExpansionPanelSummary>
                                            <CustomExpansionPanelDetails>
                                                {/* <Paper style={{width: "100%", borderRadius: "0"}}> */}
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <CustomTableCell padding="dense" >Item</CustomTableCell>
                                                            <CustomTableCell padding="dense">Quantity Received</CustomTableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {
                                                            elem.items.map((item, index) => (
                                                                <TableRow key={item.internalId} >
                                                                    <TableCell> {item.description} </TableCell>
                                                                    <TableCell> {item.quantity} </TableCell>
                                                                </TableRow>
                                                            ))
                                                        }
                                                    </TableBody>
                                                </Table>
                                                {/* </Paper> */}
                                            </CustomExpansionPanelDetails>
                                        </CustomExpansionPanel>
                                    ))
                                    :
                                    <Typography style={{ padding: "10px" }}>
                                        No records found!
                            </Typography>
                                }
                            </Grid>
                            :
                            null
                    }

                    <Grid item xs={12}>
                        {/* <Divider style={{ width: "100%" }} /> */}
                    </Grid>
                    {
                        details.itemFulfillmentsList ?
                            <Grid xs={12} item>
                                <Typography style={{ padding: "10px 20px", letterSpacing: "1px", backgroundColor: "#ffebee", color: "#f44336", textTransform: "uppercase", borderLeft: "5px solid #f44336" }}>
                                    Fullfilments Not Received Completely
                                </Typography>
                                {details.itemFulfillmentsList.incomplete ?
                                    incomplete.map((elem, index) => (
                                        <CustomExpansionPanel key={elem.key} style={{ borderRadius: "0" }}>
                                            <CustomExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                                <Typography> {elem.key} </Typography>
                                            </CustomExpansionPanelSummary>
                                            <CustomExpansionPanelDetails>
                                                {/* <Paper style={{width: "100%", borderRadius: "0"}}> */}
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <CustomTableCell padding="dense" >Item</CustomTableCell>
                                                            <CustomTableCell padding="dense">Quantity Fulfilled</CustomTableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {
                                                            elem.items.map((item, index) => (
                                                                <TableRow key={item.internalId} >
                                                                    <TableCell> {item.description} </TableCell>
                                                                    <TableCell> {item.quantity} </TableCell>
                                                                </TableRow>
                                                            ))
                                                        }
                                                    </TableBody>
                                                </Table>
                                                {/* </Paper> */}
                                            </CustomExpansionPanelDetails>
                                        </CustomExpansionPanel>
                                    ))
                                    :
                                    <Typography style={{ padding: "10px" }}>
                                        No records found!
                            </Typography>
                                }
                            </Grid>
                            :
                            null
                    }

                </Grid>

            </div>
        )
    }
}

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        // top: `${top}%`,
        // left: `${left}%`,
        // transform: `translate(-${top}%, -${left}%)`,
    };
}

const styles = theme => ({
    paper: {
        // position: 'absolute',
        // // width: theme.spacing.unit * 80,
        width: "100%",
        maxWidth: "800px",
        marginLeft: "auto",
        marginRight: "auto",
        padding: "0 20px",
        paddingBottom: "100px"
        // // backgroundColor: theme.palette.background.paper,
        // boxShadow: theme.shadows[5],
        // padding: theme.spacing.unit * 5,
        // outline: 'none',
        // overflowY: 'scroll',
        // maxHeight: '80vh',
        // backgroundColor: "#fff",
        // "&::-webkit-scrollbar": {
        //     width: "10px"
        // },
        // "&::-webkit-scrollbar-track": {
        //     background: "transparent"
        // },
        // "&::-webkit-scrollbar-thumb": {
        //     borderRadius: "0",
        //     backgroundColor: "#bdbdbd"
        // }
    },
});

export default withStyles(styles)(OrderStatusModal)
