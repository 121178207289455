import React, { Component } from 'react';
import './App.css';
import { ApolloProvider, withApollo } from 'react-apollo';
import { BrowserRouter as Router } from 'react-router-dom';
import { MuiThemeProvider } from '@material-ui/core/styles';
import firebase from './firebaseConfig'
import Login from './components/Login';
import Navbar from './components/Navbar';
import Home from './components/Home';
import Receive from './Receiving/components/Receive';
import Count from './Counting/components/Count';
// import FICStatus from './Counting/components/FICStatus';
import ReceiveStatus from './Receiving/components/ReceiveStatus';
import theme from './theme'
import client from './initializeClient'
import LoginRoute from './utils/LoginRoute'
import PrivateRoute from './utils/PrivateRoute'
// import LogRocket from 'logrocket';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles';
import './LogRocketConfig'

class App extends Component {

  constructor(props) {
    super(props)

    this.state = {
      isAuthenticated: false,
      isLoading: true,
      showWarning: false
    }

    this.events = [
      "load",
      "mousemove",
      "mousedown",
      "click",
      "scroll",
      "keypress"
    ]

    
    this.warn = this.warn.bind(this);
    this.destroy = this.destroy.bind(this);
    this.resetTimeout = this.resetTimeout.bind(this);
    this.setupInactivityEvents = this.setupInactivityEvents.bind(this);
  }

  componentDidMount() {
    firebase.auth().onAuthStateChanged(user => {

      if (user) {

        this.setState({
          isAuthenticated: true,
          isLoading: false
        })

        this.clearTimeout_app();
        this.setupInactivityEvents();

        // if(user.uid.trim() !== "") {
        //   LogRocket.identify(user.uid);
        // }
      }
      else {

        this.setState({
          isAuthenticated: false,
          isLoading: false,
          showWarning: false
        })
        client.writeData({ data: { loggedIn: false } });
        sessionStorage.removeItem('token');
        this.destroy()
      }
    })
  }

  setupInactivityEvents() {
    for(let i in this.events) {
      window.addEventListener(this.events[i], this.resetTimeout);
    }

    this.startTimeout();
  }

  warn() {
    this.setState({
      showWarning: true
    })
  }

  resetTimeout() {

    if(this.state.showWarning) {
      this.setState({
        showWarning: false
      })
    }
    this.clearTimeout_app();
    this.startTimeout();
  }

  startTimeout() {

    this.warnTimeout = setTimeout(this.warn, 85 * 60 * 1000);
    this.logoutTimeout = setTimeout(this.logout, 90 * 60 * 1000);
  }


  clearTimeout_app() {

    if (this.warnTimeout) clearTimeout(this.warnTimeout);
    if (this.logoutTimeout) clearTimeout(this.logoutTimeout);
  }

  logout() {

    firebase.auth().signOut().then(() => {

      client.clearStore();
      sessionStorage.clear();
    })
    .catch((err) => {

      console.error(err)
      alert(err)
    })
  }

  destroy() {
    this.setState({
      showWarning: false
    })
    this.clearTimeout_app();

    for (var i in this.events) {
      window.removeEventListener(this.events[i], this.resetTimeout);
    }
  }

  render() {

    const { classes } = this.props;
    return (
      <ApolloProvider client={client} >
        <MuiThemeProvider theme={theme} >
          <div className="App">
            <Router>
              <div>
                <header className="App-header">
                  {
                    this.state.isAuthenticated ? <Navbar></Navbar>
                    :
                    null
                  }
                </header>
                <LoginRoute exact path='/' isLoading={this.state.isLoading} isAuthenticated={this.state.isAuthenticated} component={withApollo(Login)} />
                <PrivateRoute path='/home' isLoading={this.state.isLoading} isAuthenticated={this.state.isAuthenticated} component={Home} />                
                <PrivateRoute path='/receive' isLoading={this.state.isLoading} isAuthenticated={this.state.isAuthenticated} component={ withApollo(Receive)} />
                <PrivateRoute path='/count' isLoading={this.state.isLoading} isAuthenticated={this.state.isAuthenticated} component={withApollo(Count)} />
                <PrivateRoute path='/orderstatus' isLoading={this.state.isLoading} isAuthenticated={this.state.isAuthenticated} component={withApollo(ReceiveStatus)} />
              </div>
            </Router>

            <Modal open={this.state.showWarning}>
              <div style={getModalStyle()} className={classes.paper}>
                <Typography variant="subtitle1" style={{ color: "red" }}>
                  You will be logged out automatically in 5 minutes if there is no activity.
                </Typography>  
              </div>
            </Modal>
          </div>
        </MuiThemeProvider>
      </ApolloProvider>
    );
  }
}

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const styles = theme => ({
  paper: {
    position: 'absolute',
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: 'none',
    textAlign: "center"
  }
})

export default withStyles(styles)(App);



