export const locations = [
    { value: '248', subsidiary: 3, subsidiaryname: 'Oak and Fort Group : Oak and Fort Corp. : Oak and Fort US Group Inc. : Oak and Fort Enterprise US Inc.', label: 'Century City', departmentid: 47, departmentname: 'Retail' },
    { value: '249', subsidiary: 2, subsidiaryname: 'Oak and Fort Group : Oak and Fort Corp. : Oak and Fort Canada', label: 'Chinook', departmentid: 46, departmentname: 'Retail' },
    // { value: '250', subsidiary: 2, subsidiaryname: 'Oak and Fort Group : Oak and Fort Corp. : Oak and Fort Canada', label: 'Ecom CAN', departmentid: 50, departmentname: 'Retail' },
    { value: '361', subsidiary: 2, subsidiaryname: 'Oak and Fort Group : Oak and Fort Corp. : Oak and Fort Canada', label: 'Virtual WH Ontario Warehouse Sale Reserve', departmentid: 46, departmentname: 'Retail' },
    { value: '251', subsidiary: 3, subsidiaryname: 'Oak and Fort Group : Oak and Fort Corp. : Oak and Fort US Group Inc. : Oak and Fort Enterprise US Inc.', label: 'Ecom USA', departmentid: 44, departmentname: 'Retail' },
    { value: '252', subsidiary: 2, subsidiaryname: 'Oak and Fort Group : Oak and Fort Corp. : Oak and Fort Canada', label: 'Gastown', departmentid: 46, departmentname: 'Retail' },
    { value: '253', subsidiary: 3, subsidiaryname: 'Oak and Fort Group : Oak and Fort Corp. : Oak and Fort US Group Inc. : Oak and Fort Enterprise US Inc.', label: 'Hayes Street', departmentid: 47, departmentname: 'Retail' },
    { value: '357', subsidiary: 3, subsidiaryname: 'Oak and Fort Group : Oak and Fort Corp. : Oak and Fort US Group Inc. : Oak and Fort Enterprise US Inc.', label: 'Bellevue Square', departmentid: 47, departmentname: 'Retail' },
    { value: '254', subsidiary: 2, subsidiaryname: 'Oak and Fort Group : Oak and Fort Corp. : Oak and Fort Canada', label: 'Head Office', departmentid: 46, departmentname: 'Retail' },
    { value: '349', subsidiary: 2, subsidiaryname: 'Oak and Fort Group : Oak and Fort Corp. : Oak and Fort Canada', label: 'Markville', departmentid: 46, departmentname: 'Retail' },
    { value: '255', subsidiary: 2, subsidiaryname: 'Oak and Fort Group : Oak and Fort Corp. : Oak and Fort Canada', label: 'Metrotown', departmentid: 46, departmentname: 'Retail' },
    
    { value: '377', subsidiary: 2, subsidiaryname: 'Oak and Fort Group : Oak and Fort Corp. : Oak and Fort Canada', label: 'Polo Park', departmentid: 46, departmentname: 'Retail' },
    { value: '378', subsidiary: 2, subsidiaryname: 'Oak and Fort Group : Oak and Fort Corp. : Oak and Fort Canada', label: 'Square One', departmentid: 46, departmentname: 'Retail' },
    

    { value: '372', subsidiary: 2, subsidiaryname: 'Oak and Fort Group : Oak and Fort Corp. : Oak and Fort Canada', label: 'Guildford Town Centre', departmentid: 46, departmentname: 'Retail' },
    { value: '356', subsidiary: 2, subsidiaryname: 'Oak and Fort Group : Oak and Fort Corp. : Oak and Fort Canada', label: 'West Edmonton Mall', departmentid: 46, departmentname: 'Retail' },
    { value: '366', subsidiary: 2, subsidiaryname: 'Oak and Fort Group : Oak and Fort Corp. : Oak and Fort Canada', label: 'Vaughan Mills', departmentid: 46, departmentname: 'Retail' },
    { value: '357', subsidiary: 3, subsidiaryname: 'Oak and Fort Group : Oak and Fort Corp. : Oak and Fort US Group Inc. : Oak and Fort Enterprise US Inc.', label: 'Bellevue Square', departmentid: 46, departmentname: 'Retail' },
    
    { value: '370', subsidiary: 2, subsidiaryname: 'Oak and Fort Group : Oak and Fort Corp. : Oak and Fort Canada', label: 'McArthur Glen Outlet', departmentid: 46, departmentname: 'Retail' },
    { value: '358', subsidiary: 6, subsidiaryname: 'Oak and Fort Group : Oak and Fort Corp. : Oak and Fort Canada', label: 'Granville Street', departmentid: 46, departmentname: 'Retail' },
    { value: '355', subsidiary: 2, subsidiaryname: 'Oak and Fort Group : Oak and Fort Corp. : Oak and Fort Canada', label: 'Fairview Mall', departmentid: 46, departmentname: 'Retail' },
    { value: '257', subsidiary: 2, subsidiaryname: 'Oak and Fort Group : Oak and Fort Corp. : Oak and Fort Canada', label: 'Oakridge', departmentid: 46, departmentname: 'Retail' },
    { value: '258', subsidiary: 2, subsidiaryname: 'Oak and Fort Group : Oak and Fort Corp. : Oak and Fort Canada', label: 'Park Royal', departmentid: 46, departmentname: 'Retail' },
    { value: '259', subsidiary: 2, subsidiaryname: 'Oak and Fort Group : Oak and Fort Corp. : Oak and Fort Canada', label: 'Queen Street', departmentid: 46, departmentname: 'Retail' },
    { value: '260', subsidiary: 2, subsidiaryname: 'Oak and Fort Group : Oak and Fort Corp. : Oak and Fort Canada', label: 'Richmond', departmentid: 46, departmentname: 'Retail' },
    { value: '125', subsidiary: 3, subsidiaryname: 'Oak and Fort Group : Oak and Fort Corp. : Oak and Fort US Group Inc. : Oak and Fort Enterprise US Inc.', label: 'Roosevelt', departmentid: 47, departmentname: 'Retail' },
    { value: '262', subsidiary: 2, subsidiaryname: 'Oak and Fort Group : Oak and Fort Corp. : Oak and Fort Canada', label: 'Sherway Gardens', departmentid: 46, departmentname: 'Retail' },
    { value: '263', subsidiary: 2, subsidiaryname: 'Oak and Fort Group : Oak and Fort Corp. : Oak and Fort Canada', label: 'Shops at Don Mills', departmentid: 46, departmentname: 'Retail' },
    { value: '73', subsidiary: 3, subsidiaryname: 'Oak and Fort Group : Oak and Fort Corp. : Oak and Fort US Group Inc. : Oak and Fort Enterprise US Inc.', label: 'Soho', departmentid: 47, departmentname: 'Retail' },
    { value: '265', subsidiary: 3, subsidiaryname: 'Oak and Fort Group : Oak and Fort Corp. : Oak and Fort US Group Inc. : Oak and Fort Enterprise US Inc.', label: 'Soma', departmentid: 47, departmentname: 'Retail' },
    { value: '266', subsidiary: 2, subsidiaryname: 'Oak and Fort Group : Oak and Fort Corp. : Oak and Fort Canada', label: 'Southgate', departmentid: 46, departmentname: 'Retail' },
    { value: '267', subsidiary: 2, subsidiaryname: 'Oak and Fort Group : Oak and Fort Corp. : Oak and Fort Canada', label: 'Toronto Eaton Centre', departmentid: 46, departmentname: 'Retail' },
    { value: '348', subsidiary: 3, subsidiaryname: 'Oak and Fort Group : Oak and Fort Corp. : Oak and Fort US Group Inc. : Oak and Fort Enterprise US Inc.', label: 'Valley Fair', departmentid: 47, departmentname: 'Retail' },
    { value: '274', subsidiary: 2, subsidiaryname: 'Oak and Fort Group : Oak and Fort Corp. : Oak and Fort Canada', label: 'WH Burbidge', departmentid: 44, departmentname: 'Supply Chain' },
    { value: '275', subsidiary: 3, subsidiaryname: 'Oak and Fort Group : Oak and Fort Corp. : Oak and Fort US Group Inc. : Oak and Fort Enterprise US Inc.', label: 'WH Cali', departmentid: 44, departmentname: 'Supply Chain' },
    { value: '276', subsidiary: 6, label: 'WH Loft 82', departmentid: 44, departmentname: 'Supply Chain' },
    { value: '71', subsidiary: 3, subsidiaryname: 'Oak and Fort Group : Oak and Fort Corp. : Oak and Fort US Group Inc. : Oak and Fort Enterprise US Inc.', label: 'WH NYM', departmentid: 44, departmentname: 'Supply Chain' },
    { value: '278', subsidiary: 1, label: 'WH Oak and Fort Corp.', departmentid: 44, departmentname: 'Supply Chain' },
    { value: '77', subsidiary: 3, subsidiaryname: 'Oak and Fort Group : Oak and Fort Corp. : Oak and Fort US Group Inc. : Oak and Fort Enterprise US Inc.', label: 'Wicker Park', departmentid: 47, departmentname: 'Retail' },
    { value: '282', subsidiary: 2, subsidiaryname: 'Oak and Fort Group : Oak and Fort Corp. : Oak and Fort Canada', label: 'Yorkdale', departmentid: 46, departmentname: 'Retail' },
    { value: '268', subsidiary: 2, subsidiaryname: 'Oak and Fort Group : Oak and Fort Corp. : Oak and Fort Canada', label: 'Virtual WH Burbidge', departmentid: 44, departmentname: 'Supply Chain' },
    { value: '269', subsidiary: 2, subsidiaryname: 'Oak and Fort Group : Oak and Fort Corp. : Oak and Fort Canada', label: 'Virtual WH Burbidge : Sellable Damages', departmentid: 44, departmentname: 'Supply Chain' },
    { value: '364', subsidiary: 2, subsidiaryname: 'Oak and Fort Group : Oak and Fort Corp. : Oak and Fort Canada', label: 'Virtual WH Burbidge : Non-Sellable Damages', departmentid: 44, departmentname: 'Supply Chain' },
    { value: '270', subsidiary: 2, subsidiaryname: 'Oak and Fort Group : Oak and Fort Corp. : Oak and Fort Canada', label: 'Virtual WH Burbidge : Warehouse Sale Reserve', departmentid: 44, departmentname: 'Supply Chain' },
    { value: '271', subsidiary: 3, subsidiaryname: 'Oak and Fort Group : Oak and Fort Corp. : Oak and Fort US Group Inc. : Oak and Fort Enterprise US Inc.', label: 'Virtual WH California', departmentid: 44, departmentname: 'Supply Chain' },
    { value: '272', subsidiary: 3, subsidiaryname: 'Oak and Fort Group : Oak and Fort Corp. : Oak and Fort US Group Inc. : Oak and Fort Enterprise US Inc.', label: 'Virtual WH California - Sellable Damages', departmentid: 44, departmentname: 'Supply Chain' },
    { value: '273', subsidiary: 3, subsidiaryname: 'Oak and Fort Group : Oak and Fort Corp. : Oak and Fort US Group Inc. : Oak and Fort Enterprise US Inc.', label: 'Virtual WH California - Warehouse Sale Reserve', departmentid: 44, departmentname: 'Supply Chain' },
    { value: '122', subsidiary: 3, subsidiaryname: 'Oak and Fort Group : Oak and Fort Corp. : Oak and Fort US Group Inc. : Oak and Fort Enterprise US Inc.', label: 'WH US Enterprise', departmentid: 44, departmentname: 'Supply Chain' }]
    
    export default locations;