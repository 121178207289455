import SCANNED_ITEMS from '../queries/SCANNED_ITEMS'


const writeLineNotes = (_, { upccode, value }, { cache }) => {

    const { scannedItems } = cache.readQuery({
        query: SCANNED_ITEMS
    })

    let parsedItems  = JSON.parse(scannedItems);
    parsedItems[upccode].notes = value;
    
    cache.writeData({
        data: {
            scannedItems: JSON.stringify(parsedItems)
        }
    })
    return null
}

export default writeLineNotes