import React, { Component } from 'react'
import Typography from '@material-ui/core/Typography';
// import LocalShippingRounded from '@material-ui/icons/LocalShippingRounded';
// import AddShoppingCart from '@material-ui/icons/AddShoppingCart';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import theme from '../theme';

const Home = ({ classes }) => {

  return (
    <div className="home-container">
      <Typography className={classes.typography} variant="h2" gutterBottom>
        OAK + FORT Inventory System
        </Typography>
      <Grid className={classes.homeActions} container justify="space-evenly" alignItems="flex-start">
        <Grid item xs={12} md={6} style={{ marginBottom: "30px" }}>
          <Grid container justify="center" direction="column" alignItems="center"  >
            <Avatar className={classes.avatar} component={Link} to="/receive" >
              {/* <LocalShippingRounded className={classes.icon} /> */}
              <i className="fas fa-truck-loading home-icon"></i>
            </Avatar>
            <Typography style={{ marginTop: "20px" }} variant="subtitle1" gutterBottom >
              Click here to receive an order
              </Typography>
          </Grid>
        </Grid>

        <Grid item xs={12} md={6} style={{ marginBottom: "30px" }}>
          <Grid container justify="center" direction="column" alignItems="center" >
            <Avatar className={classes.avatar} component={Link} to="/count" >
              <i className="fas fa-clipboard-list home-icon"></i>
            </Avatar>
            <Typography variant="subtitle1" style={{ marginTop: "20px" }} gutterBottom >
              Click here to do an inventory count
                </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6} style={{ marginBottom: "30px" }}>
          <Grid container justify="center" direction="column" alignItems="center" >
            <Avatar className={classes.avatar} component={Link} to="/orderstatus" >
              {/* <AddShoppingCart className={classes.icon} /> */}
              <i className="fas fa-info-circle home-icon"></i>
            </Avatar>
            <Typography variant="subtitle1" style={{ marginTop: "20px" }} gutterBottom >
              Click here to Check Order status
                </Typography>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}


const styles = theme => ({
  homeActions: {
    // minHeight: "80vh",
    padding: '90px',
    [theme.breakpoints.down('xs')]: {
      padding: '20px'
    }
  },
  avatar: {
    width: '250px',
    height: '250px',
    // backgroundColor: '#64b5f6'
    backgroundColor: "#eee",
    // backgroundColor: "#D8DBE2"
    transition: "background-color 0.2s ease-in",
    '&:hover': {
      border: "none",
      backgroundColor: "#000",
      boxShadow: "0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)",

      '& .home-icon': {
        color: "white",
        fontSize: "65px"
      }
    },
    [theme.breakpoints.down('xs')]: {
      width: '150px',
      height: '150px',
    }
  },
  icon: {
    fontSize: "60px",
    color: "#000"
  },
  typography: {
    padding: "60px 0 0 0",
    fontSize: "3em",
    fontWeight: "400",
    [theme.breakpoints.down('xs')]: {
      padding: '20px',
      fontSize: "2.5em"
    }
  }
})

Home.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Home)
