import GET_CHECKBOXES from '../queries/GET_CHECKBOXES'

const addCheckbox = (_, { key, checked }, { cache }) => {

    const previousState = cache.readQuery({
        query: GET_CHECKBOXES
    })

    let exists = false;

    previousState.submitCheckboxes.forEach(item => {
        if(item.key === key) {
            exists = true;
        }
    });

    if(!exists) {
        const newItem = {
            key: key,
            checked: checked
             // __typename: "ItemDetails"
         }
     
         cache.writeQuery({
             query: GET_CHECKBOXES,
             data: {
                 submitCheckboxes: [
                     ...previousState.submitCheckboxes,
                     newItem,
                 ]
             }
         })
    }

    return null
}

export default addCheckbox