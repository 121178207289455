import React, { Component } from 'react'
import Modal from '@material-ui/core/Modal'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import CircularProgress from '@material-ui/core/CircularProgress'
import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider } from 'material-ui-pickers'
import { DatePicker } from 'material-ui-pickers'
import locations from '../../utils/Locations'
import Select from 'react-select'

export class DateRangeModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            startDate: new Date(),
            endDate: new Date(),
            selectedLocation: null,
            locations: [],
            location: '',
            loader: false
        }
        this.saveData = this.saveData.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    saveData() {
        const { client } = this.props;
        let self = this;
        if(this.state.selectedLocation !== null) {
            client.writeData({
                data: {
                    startDate: self.state.startDate.toString(),
                    endDate: self.state.endDate.toString(),
                    submitLocation: self.state.selectedLocation.label,
                    fetchCounts: true
                }
            })
        }
        else {
            alert('Please enter a valid location.');
        }
    }

    handleChange = (selectedLocation) => {
        this.setState({ selectedLocation });
    }

    render() {
        const { open, classes, client } = this.props;
        const { selectedLocation } = this.state;

        return (
            <div>
                <Modal open={open}>
                    <div style={getModalStyle()} className={classes.paper} >
                        <Typography variant="h5" id="modal-title">
                            Enter date range for this FIC period
                        </Typography>
                        <Divider style={{ width: "100%" }} />
                        {
                            this.state.loader ?
                                <CircularProgress />
                                :
                                <form style={{ marginTop: "30px" }} onSubmit={(e) => {
                                    e.preventDefault();
                                    this.saveData()
                                }}
                                >
                                    <div className={ classes.inputPadding }>
                                        <Select
                                            value={selectedLocation}
                                            onChange={this.handleChange}
                                            options={locations}
                                            placeholder="Select your Location..."
                                        />
                                    </div>
                                    <div style={{ margin: "10px 0" }} >
                                        <MuiPickersUtilsProvider utils={DateFnsUtils} >
                                            <DatePicker value={this.state.startDate}
                                                label="Start Date"
                                                onChange={date => this.setState({ startDate: date })}
                                                animateYearScrolling
                                                fullWidth
                                                required
                                            />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                    <div style={{ margin: "10px 0" }} >
                                        <MuiPickersUtilsProvider utils={DateFnsUtils} >
                                            <DatePicker value={this.state.endDate}
                                                label="End Date"
                                                onChange={date => this.setState({ endDate: date })}
                                                animateYearScrolling
                                                fullWidth
                                                required
                                            />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                    <div style={{ textAlign: "left", marginTop: "30px" }} >
                                        {
                                            this.state.fetchLoader ?
                                                <CircularProgress />
                                                :
                                                <div>
                                                    <Button type="submit" variant="contained" color="primary">
                                                        Continue
                                                    </Button>
                                                    <Button variant="outlined" style={{ marginLeft: "20px" }}
                                                        onClick={(e) => {
                                                            client.writeData({
                                                                data: { countStep: 0 }
                                                            })
                                                        }}
                                                    >
                                                        Cancel
                                                    </Button>
                                                </div>
                                        }

                                    </div>
                                </form>
                        }
                    </div>
                </Modal>
            </div>
        )
    }
}

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const styles = theme => ({
    root: {
        width: '100%',
        margin: "0 auto",
        // marginTop: theme.spacing.unit * 3,
    },
    paper: {
        position: 'absolute',
        width: theme.spacing.unit * 50,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 4,
        outline: 'none',
        textAlign: "center",
        [theme.breakpoints.down('xs')]: {
            width: theme.spacing.unit * 40,
            padding: theme.spacing.unit * 3
        }
    },
    rightIcon: {
        marginLeft: theme.spacing.unit,
    },
    inputPadding: {
        marginBottom: "20px"
    }
})


export default withStyles(styles)(DateRangeModal)
