import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
// import Button from '@material-ui/core/Button'
import StepLabel from '@material-ui/core/StepLabel'
// import Today from '@material-ui/icons/Today'
import FetchTransactionDetails from './FetchTransactionDetails'
import ScanItems from './ScanItems'
import ReceiveItems from './ReceiveItems'
import ShowResponse from './ShowResponse'
import { Query } from 'react-apollo';
import gql from 'graphql-tag'
import {withApollo} from 'react-apollo'

const FetchTransactionDetailsWithClient = withApollo(FetchTransactionDetails)
const ScanItemsWithClient = withApollo(ScanItems);
const ReceiveItemsWithClient = withApollo(ReceiveItems);
const ShowResponseWithClient = withApollo(ShowResponse);

function getSteps() {
  return ['Enter Transaction Number', 'Scan Items', 'Submit'];
}

function getStepContent(stepIndex) {
  switch (stepIndex) {
    case 0:
      return <FetchTransactionDetailsWithClient />
    case 1:
      return <ScanItemsWithClient />;
    case 2:
      return <ReceiveItemsWithClient />;
    default:
      return 'Unknown stepIndex';
  }
}

const GET_RECEIVE_STEP = gql`
  {
    receiveStep @client
  }
`

export class Receive extends Component {

  constructor(props) {
    super(props);

    this.state = {
      activeStep: 0
    }
  }

  componentDidMount() {
    const { client } = this.props;
    client.writeData({
      data: { confirmDiff: [] }
    })
  }

  render() {
    const { classes } = this.props;
    const steps = getSteps();

    return (
      <div className={classes.receiveContainer} >

        <Paper className={classes.root}>
          <Typography variant="h5" gutterBottom>
            Receive Order
          </Typography>
        </Paper>

        <Query query={GET_RECEIVE_STEP}>
          {
            ({ data: { receiveStep } }) => {
              return (
                <Grid container spacing={40} justify="center" alignItems="center" className={classes.outerGrid} >
                  <Grid item xs={12} >
                    <Grid container justify="center">
                      <Grid item xs={12}>
                        <Paper elevation={1}>
                          <Stepper activeStep={receiveStep} alternativeLabel >
                            {steps.map(label => (
                              <Step key={label} >
                                <StepLabel>
                                  {label}
                                </StepLabel>
                              </Step>
                            ))}
                          </Stepper>
                        </Paper>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} >
                    <Grid container >
                      <Grid item xs={12} >
                        { receiveStep === steps.length ? (
                          <ShowResponseWithClient history={this.props.history} />
                        ) :
                          (
                            <div>
                              {getStepContent(receiveStep)}
                            </div>
                          )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )
            }
          }
        </Query>

      </div>
    )
  }
}

const styles = theme => ({
  receiveContainer: {
    minHeight: "100vh",
    marginTop: '65px',
    textAlign: "center",
    background: "#f5f5f5"
  },
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit,
  },
  outerGrid: {
    paddingTop: "20px",
    maxWidth: '100%',
    margin: "0 auto"
  }
})

Receive.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Receive)
