import gql from 'graphql-tag';

const REMOVE_ITEM = gql`
    mutation removeItem($internalid: String!) {
        removeItem(internalid: $internalid) @client {
            internalid
        }
    }
`

export default REMOVE_ITEM