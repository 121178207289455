import React, { Component } from 'react'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { Grid } from '@material-ui/core';
import { compose, graphql } from 'react-apollo'
import SUBMIT_ITEMS from '../mutations/SUBMIT_ITEMS'
import gql from 'graphql-tag';
// import TRANSACTION_QUERY from '../queries/TRANSACTION_QUERY'
import CircularProgress from '@material-ui/core/CircularProgress'
import SET_QUANTITY_UPC from '../mutations/SET_QUANTITY_UPC'
import { FormControl, InputLabel, Input } from '@material-ui/core'
import SCANNED_ITEMS from '../queries/SCANNED_ITEMS'
import firebase from '../../firebaseConfig'
import axios from 'axios';

export class ReceiveItems extends Component {

  constructor(props) {
    super(props);

    this.state = {
      error: '',
      success: false,
      open: false,
      isLoading: false,
      irmemo: ''
    }

    this.submitItems = this.submitItems.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
  }

  submitItems() {
    const { client, scannedItemsQuery: { scannedItems } } = this.props;
    let payload = {}
    this.setState({ isLoading: true });
    const self = this;

    const { currentOrder, receiveDuration } = client.readQuery({
      query: gql`{ currentOrder @client, receiveDuration @client } `
    });

    let orderDetails   = JSON.parse(currentOrder),
        finalItems     = [],
        varianceReport = 'UPC,Expected Quantity,Actual Quantity\n',
        parsedItems    = JSON.parse(scannedItems);


        

    let cst_varianceReport = 'transaction_internalid,transaction,employeename,location,countduration,irmemo,memo\n' //internalid,upccode,expectedqty,actualqty,itemmemo,name 
        cst_varianceReport += orderDetails.internalid + "," + orderDetails.documentnumber + "," + sessionStorage.getItem('emp') + "," + sessionStorage.getItem('loc') + "," + (receiveDuration + "") + "," + this.state.irmemo + "," + orderDetails.memo  + "\n"
        cst_varianceReport += 'internalid,upccode,expectedqty,actualqty,itemmemo,name\n'


    for (const key in parsedItems) {
      const element = parsedItems[key];
      if (parseInt(element.expectedqty) > 0 || parseInt(element.actualqty) > 0) {
        finalItems.push({
          actualqty: element.actualqty,
          baseprice: element.baseprice,
          expectedqty: element.expectedqty,
          internalid: element.internalid,
          upccode: element.upccode,
          name: element.name,
          itemmemo: element.notes ? element.notes : " "
        })

        varianceReport += element.upccode + "," + element.expectedqty + "," + element.actualqty + "\n"

        var n = element.notes ? element.notes : " "
        cst_varianceReport +=  element.internalid + "," + element.upccode + "," + element.expectedqty + "," + element.actualqty + "," + n + "," + element.name + "\n"
      }

    }

    firebase.auth().onAuthStateChanged(user => {
      if (user && self._isMounted) {

        payload = {
          tranid: orderDetails.documentnumber,
          memo: orderDetails.memo,
          items: finalItems,
          countDuration: receiveDuration + "",
          irmemo: this.state.irmemo,
          token: "12345"
        }



        firebase.auth()
              .currentUser
              .getIdToken(true)
              .then(token => {

        
                  return axios({ method: "post",
                                 url: 'https://3huv5xrb2d.execute-api.ca-central-1.amazonaws.com/dev/get_upload_url', // dev = 'https://89whj8fhmh.execute-api.ca-central-1.amazonaws.com/dev/get_upload_url',//'https://3huv5xrb2d.execute-api.ca-central-1.amazonaws.com/dev/get_upload_url',
                                 headers: {
                                    Authorization: token
                                 },
                                 data: {
                                    transaction: orderDetails.documentnumber.toUpperCase(),
                                    location: sessionStorage.getItem('loc')
                                 }
                              })
                              .then(res => {
                                  

                                  let upload_url = res['data']['upload_url'];

                             
                                  return axios({ method: "put",
                                                 url: upload_url,
                                                 headers: {
                                                  'Content-Type': 'text/csv',
                                                },
                                                 data: cst_varianceReport})
                                              .then(() => {

                                                  let receiveResponse = {
                                                    msg: "Order has been submitted! You will receive an email once the data has been processed. Please go to Status page to check the order status",
                                                    err: null,
                                                    varianceReport: varianceReport,
                                                    __typename: "response"
                                                  }
                                          
                                                  self.setState({
                                                    isLoading: false,
                                                    irmemo: ''
                                                  })
                                          
                                                  return client.writeData({ data: { receiveResponse: receiveResponse, receiveStep: 3 } })
                                              })
                                              .catch(err => {

                                                console.log("err uploading submission: ", err)

                                                return true;
                                              })
                                 
                                  

                              })
                              .catch(err => {

                                  console.log("error fetching upload url: ", err)
                                  self.setState({ isLoading: false });
                                  return true;
                              })


          
              })
              .catch(err => {
                  console.log("error refreshing token: ", err)
              })


       




        /*
        // let db_node = firebase.database().ref(`${user.uid}/physical_stores/store_node`);
        let db_node = firebase.database().ref(`receiving/physical_stores/store_node`);

        db_node.child(orderDetails.receivinglocation.locationname).child('post_transaction').child(orderDetails.documentnumber).update({
          store: orderDetails.receivinglocation.locationname,
          transaction: orderDetails.documentnumber.toUpperCase(),
          status: 'submit',
          data: payload
        })
          .then(() => {

            let receiveResponse = {
              msg: "Order has been submitted! You will receive an email once the data has been processed. Please go to Status page to check the order status",
              err: null,
              varianceReport: varianceReport,
              __typename: "response"
            }

            this.setState({
              isLoading: false,
              irmemo: ''
            })

            client.writeData({ data: { receiveResponse: receiveResponse, receiveStep: 3 } })

          })
          .catch(err => {
            console.error(err)

            this.setState({
              isLoading: false,
            })
            alert('The order could not be received. Please contact the IT team.')
          })
          */

      }


    })
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { client, setQuantityUpc } = this.props;
    

    const { currentOrder } = client.readQuery({
      query: gql`{ currentOrder @client }`
    });
    let orderDetails = JSON.parse(currentOrder);

    return (
      <Grid container justify="center" spacing={24} >
        <Grid item xs={12} >
          <Typography variant="h5">
            Receive the scanned items
                  </Typography>
        </Grid>
        <Grid item xs={12} >
          {
            this.state.isLoading ?
              <div style={{ textAlign: "center" }} >
                <div style={{ fontSize: "56px" }}>
                  <span className="fas fa-cloud-upload-alt"></span>
                </div>
                <CircularProgress style={{ fontSize: "48px", marginTop: "20px" }} />
              </div> :
              <div style={{ maxWidth: "300px", margin: "0 auto" }} >
                <div style={{ margin: "30px 0" }}>
                  <FormControl fullWidth >
                    <InputLabel htmlFor="employeeName">Memo</InputLabel>
                    <Input required id="component-simple" value={this.state.irmemo}
                      placeholder="Any notes"
                      onChange={(e) => this.setState({ irmemo: e.target.value })} />
                  </FormControl>
                </div>
                <Button variant="contained" color="primary"
                  onClick={(e) => { this.submitItems() }}
                >
                  Submit
                </Button>

                <Button style={{ marginLeft: "20px" }} variant="contained"
                  onClick={(e) => {
                    e.preventDefault();

                    orderDetails.items.forEach((item, i) => {
                      setQuantityUpc({
                        variables: {
                          upccode: item.upccode,
                          quantity: 0
                        }
                      })
                      if (i === orderDetails.items.length - 1) {
                        this.props.client.writeData({
                          data: {
                            confirmDiff: [],
                            employeeName: '',
                            lastScanned: '',
                            receiveStep: 0,
                            receiveDuration: '',
                          }
                        })
                      }
                    })
                  }}
                >
                  Cancel
                  </Button>
              </div>

          }
        </Grid>
        <Grid item xs={12} >

        </Grid>
      </Grid>
    )
  }
}

export default compose(
  graphql(
    SUBMIT_ITEMS, { name: 'postTransactionToReceive' }
  ),
  graphql(SET_QUANTITY_UPC, { name: 'setQuantityUpc' }),
  graphql(SCANNED_ITEMS, { name: 'scannedItemsQuery' })
)(ReceiveItems)
