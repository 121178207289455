import gql from 'graphql-tag';

const WRITE_LINE_NOTES = gql`
    mutation writeLineNotes($upccode: String!, $value: String!) {
        writeLineNotes(upccode: $upccode, value: $value) @client {
            upccode
        }
    }
`

export default WRITE_LINE_NOTES