import gql from 'graphql-tag';

const INCREMENT_COUNT_SKU = gql`
    mutation incrementCountSku($sku: String!) {
        incrementCountSku(sku: $sku) @client {
            sku
        }
    }
`

export default INCREMENT_COUNT_SKU