// import ITEM_DETAILS from '../queries/ITEM_DETAILS'
import SCANNED_ITEMS from '../queries/SCANNED_ITEMS'
import gql from 'graphql-tag'


const addItemSku = (_, { sku, upccode, locationid }, { cache }) => {

    const { scannedItems } = cache.readQuery({query: SCANNED_ITEMS})
    let parsedItems = JSON.parse(scannedItems);

    if(parsedItems[upccode]) {
        parsedItems[upccode].actualqty = parsedItems[upccode].actualqty + 1;
    }
    else {

        const { itemDetails } = cache.readQuery({
            query: gql`{ itemDetails @client }`
        })

        const parsedItemDetails = JSON.parse(itemDetails);
        
        const newItem = {
            actualqty: 1,
            baseprice: parsedItemDetails.baseprice ? parsedItemDetails.baseprice : 0,
            internalid: parsedItemDetails.internalid,
            name: parsedItemDetails.name,
            upccode: parsedItemDetails.upccode,
            expectedqty: 0,
            verified: false,
            notes: '',
            __typename: "ItemDetails"
        }
        parsedItems[upccode] = newItem;
    }

    cache.writeData({
        data: { scannedItems: JSON.stringify(parsedItems) }
    })

    return null
  }

  export default addItemSku