import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import firebase from '../../firebaseConfig'
// import TRANSACTION_QUERY from '../queries/TRANSACTION_QUERY';
// import LOCATION_QUERY from '../queries/LOCATION_QUERY';
import gql from 'graphql-tag';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal'
import CircularProgress from '@material-ui/core/CircularProgress'
import locations from '../../utils/Locations'
import Select from 'react-select'


import axios from 'axios';


export class FetchTransactionDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tno: '',
            location: '',
            employeeName: '',
            options: [],
            selectedLocation: null,
            inputValue: '',
            open: false,
            error: '',
            isLoading: false,
        }
        this.onSubmit = this.onSubmit.bind(this)
        this.handleClick = this.handleClick.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.handleChange = this.handleChange.bind(this);
        this.fetchOrderDetails = this.fetchOrderDetails.bind(this);
        this._isMounted = false
    }

    handleClick() {
        this.setState({
            open: true
        })
    }

    handleClose() {
        this.setState({
            open: false
        })
    }

    componentDidMount() {
        this._isMounted = true;
    }

    handleChange = (selectedLocation) => {
        this.setState({ selectedLocation });
    }

    onSubmit(e) {
        e.preventDefault();
        const { client } = this.props;
        const { tno, selectedLocation } = this.state;
        const self = this;

        if (selectedLocation === null) {
            this.setState({
                open: true,
                error: 'Please select your location'
            })
            return
        }

        this.setState({ isLoading: true })

        // Fetch uid if user is logged in
        firebase.auth().onAuthStateChanged(user => {

            if (user && self._isMounted) {

                if (!this.state.employeeName || this.state.employeeName.trim() == '' || this.state.employeeName.trim() == ' ')
                {
                    alert('Please enter employee name!')
                    this.setState({ isLoading: false, employeeName: '' })
                }
                else
                {
                    client.writeData({
                        data: { transactionNo: tno, employeeName: this.state.employeeName.trim() }
                    })
    
                    firebase.auth()
                            .currentUser
                            .getIdToken(true)
                            .then(token => {
    
    
                                sessionStorage.setItem('loc', selectedLocation.value)
                                sessionStorage.setItem('emp', this.state.employeeName.trim())
                                let db_node = firebase.database().ref(`receiving/physical_stores/store_node`);
    
                                //let sample_expired = 'eyJhbGciOiJSUzI1NiIsImtpZCI6IjU1NGE3NTQ3Nzg1ODdjOTRjMTY3M2U4ZWEyNDQ2MTZjMGMwNDNjYmMiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vb2FrLWFuZC1mb3J0LWludmVudG9yeS1mZWRjMSIsImF1ZCI6Im9hay1hbmQtZm9ydC1pbnZlbnRvcnktZmVkYzEiLCJhdXRoX3RpbWUiOjE1OTU4MDY2NTksInVzZXJfaWQiOiJxTHVUOFVjZ0hSUm9BR0NrR2d6WlVkNno4anIxIiwic3ViIjoicUx1VDhVY2dIUlJvQUdDa0dnelpVZDZ6OGpyMSIsImlhdCI6MTU5NTgxODM1MCwiZXhwIjoxNTk1ODIxOTUwLCJlbWFpbCI6ImNvdW50aW5nbWFzdGVyQG9ha2FuZGZvcnQuY29tIiwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJmaXJlYmFzZSI6eyJpZGVudGl0aWVzIjp7ImVtYWlsIjpbImNvdW50aW5nbWFzdGVyQG9ha2FuZGZvcnQuY29tIl19LCJzaWduX2luX3Byb3ZpZGVyIjoicGFzc3dvcmQifX0.qFxsld7w7AXidRIIoJgixTFFu-zXF7favdB--TLVLBDKrffT1i6VWlvFkKd3LEZ7_6ZXAbey3l1ysv9scx3xaS2wdVAYB1-UZYV2biBkfEVO7GG9WjS1y-IfXkHR68BND6ulfZmhDON2tkjGTsGgMwuLmtyxz8a2LOAqwJBgX-BUUDBUHs2IfhCnxLYAg0_QcLh5C4CNYlse3FZLzufWT4S4dTn3569l2UgltgiDa5P5U2PI0dhBZeOOWeCpFG6bLsLLvG5nEU4QzQb7AFmowIcdSVN1dX8DIkrpJy9i27qcJ7p0pvyC8SIaSfAtzbbWou1dTqAAfY5ACkJHB79ujA'
    
                                return axios({ method: "post",
                                               url: 'https://3huv5xrb2d.execute-api.ca-central-1.amazonaws.com/dev/get_transaction', //'https://89whj8fhmh.execute-api.ca-central-1.amazonaws.com/dev/get_transaction', //'https://3huv5xrb2d.execute-api.ca-central-1.amazonaws.com/dev/get_transaction',
                                               headers: {
                                                Authorization: token
                                               },
                                               data: {
                                                transaction: tno.toUpperCase(),
                                                location: selectedLocation.value,
                                                locationname: selectedLocation.label
                                               }
                                            })
                                            .then(res => {
                                                
                                               
    
                                                let orderDetails = res.data.transaction;


                            
                                                
                                                if(orderDetails.status.toLowerCase() === "closed" ) {
                                                    this.setState({
                                                        open: true,
                                                        isLoading: false,
                                                        error: 'This order is Closed!'
                                                    })
                                                }
                                                else if( orderDetails.status.toLowerCase() === "pendingfulfillment") {
                                                    this.setState({
                                                        open: true,
                                                        isLoading: false,
                                                        error: 'This order needs to be fulfilled first!'
                                                    })
                                                }
                                                else {
    
                                                    self.fetchOrderDetails(db_node);
    
                                                        return
                                                    /*
                                                    self.fetchOrderDetails(db_node);
    
                                                    if(orderDetails.status.toLowerCase() === "sent")
                                                    {
                                                        self.fetchOrderDetails(db_node);
    
                                                        return
                                                    }
                                                    
    
                                                    
                                                    let orderLocation = orderDetails.receivinglocation.locationid;
                                                    if (orderLocation === selectedLocation.value) {
                                                        
                                                        if(orderDetails.status.toLowerCase() !== "received") {
    
                                                            
    
                                                            let scannedItems = {};
                                                            orderDetails.items.forEach(element => {
                                                                scannedItems[element.upccode] = {
                                                                    ...element,
                                                                    verified: false,
                                                                    notes: ''
                                                                };
                                                            });
                                                    
                                                            const { receiveStep } = client.readQuery({ query: gql`{ receiveStep @client }` })
                                                            self.setState({ isLoading: false });
                                                    
                                                            client.writeData({
                                                                data: {
                                                                    scannedItems: JSON.stringify(scannedItems),
                                                                    currentOrder: JSON.stringify(orderDetails),
                                                                    receiveStep: receiveStep + 1
                                                                }
                                                            })
    
                                                            self.fetchOrderDetails(db_node);
    
                                                            
    
                                                            
                                                        }
                                                        else {
                                                            this.setState({
                                                                open: true,
                                                                isLoading: false,
                                                                error: 'This order has already been received'
                                                            })
                                                        }
                                                        
                                                    }
                                                    else {
                                                        this.setState({
                                                            open: true,
                                                            isLoading: false,
                                                            error: 'This order is not meant to be received by your store!'
                                                        })
                                                    }
                                                    */
                                                }
    
    
                                                
    
                                            })
                                            .catch(err => {
    
                                                console.log("error fetching transaction details: ", err)
                                                self.setState({ isLoading: false });
                                                return true;
                                            })
                        
                            })
                            .catch(err => {
                                console.log("error refreshing token: ", err)
                            })
    
    
                    /*
                    // let db_node = firebase.database().ref(`${user.uid}/physical_stores/store_node`);
                    let db_node = firebase.database().ref(`receiving/physical_stores/store_node`);
    
                    // Add the query to database
                    db_node.child(selectedLocation.label).child('get_transaction').child(tno.toUpperCase()).remove()
                        .then(() => {
                            db_node.child(selectedLocation.label).child('get_transaction')
                                .update({
                                    [tno.toUpperCase()]: {
                                        store: selectedLocation.label,
                                        transaction: tno.toUpperCase(),
                                        employeename: this.state.employeeName,
                                        date: new Date(),
                                        status: "submit"
                                    }
                                }).then(() => {
    
                                    self.fetchOrderDetails(db_node);
                                })
                                .catch(err => {
                                    console.error(err);
                                    alert(err);
                                })
                        })
                        .catch(err => {
                            console.error(err);
                            alert(err);
                        })
    
                        */
                }
                
                
            }

            
        })


        /*
        firebase.auth().onAuthStateChanged(user => {
            if (user) {
                firebase.auth().currentUser.getIdToken().then(idToken => {
                    client.writeData({
                        data: { idToken: idToken, transactionNo: tno, employeeName: this.state.employeeName }
                    })

                    client.query({
                        query: TRANSACTION_QUERY,
                        variables: { tranid: tno, token: idToken }
                    })
                        .then(({ data: { getTransactionToReceive } }) => {

                            if(getTransactionToReceive.status.toLowerCase() === "closed" ) {
                                this.setState({
                                    open: true,
                                    isLoading: false,
                                    error: 'This order is Closed!'
                                })
                            }
                            else if( getTransactionToReceive.status.toLowerCase() === "pendingfulfillment") {
                                this.setState({
                                    open: true,
                                    isLoading: false,
                                    error: 'This order needs to be fulfilled first!'
                                })
                            }
                            else {
                                let orderLocation = getTransactionToReceive.receivinglocation.locationid;
                                if (orderLocation === selectedLocation.value) {

                                    if(getTransactionToReceive.status.toLowerCase() !== "received") {

                                        let scannedItems = {};

                                        getTransactionToReceive.items.forEach(element => {
                                            scannedItems[element.upccode] = {
                                                ...element,
                                                verified: false,
                                                notes: ''
                                            };
                                        });

                                        const { receiveStep } = client.readQuery({query: gql`{ receiveStep @client }`})
                                        this.setState({isLoading: false})
                                        client.writeData({
                                            data: {
                                                receiveStep: receiveStep + 1,
                                                scannedItems: JSON.stringify(scannedItems)
                                            }
                                        })
                                    }
                                    else {
                                        this.setState({
                                            open: true,
                                            isLoading: false,
                                            error: 'This order has already been received'
                                        })
                                    }
                                }
                                else {
                                    this.setState({
                                        open: true,
                                        isLoading: false,
                                        error: 'This order is not meant to be received by your store!'
                                    })
                                }
                            }
                            
                        })
                        .catch((err) => {
                            console.error(err);
                            this.setState({
                                open: true,
                                isLoading: false,
                                error: 'Please enter a valid Transaction Number!'
                            })
                        })
                })
                    .catch(err => {
                        console.error(err)
                        alert(err);
                    })
            }
        })
        */
    }

    fetchOrderDetails(db_node) {
        const { client } = this.props;
        const { tno, selectedLocation } = this.state;
        const self = this;

        let timeOutCounter = null

        //console.log(selectedLocation.label)

        
        // Check if node has been created for the TO/PO 
        let retrieved_node = db_node.child(selectedLocation.label).child('get_transaction').child(tno.toUpperCase());

        // Fetch the order info
        retrieved_node.on('value', function (snapshot) {

           console.log('snapshot: ', snapshot.val())

            if (self._isMounted && snapshot.val() && snapshot.val() !== null) {


                if (snapshot.val().data.fetch_status.toLowerCase() === "wrong receiving location") {
                    self.setState({
                        open: true,
                        isLoading: false,
                        error: 'This order is not meant to be received by your store!'
                    })
                }
                else if(snapshot.val().data.fetch_status.toLowerCase() === "invalid order number") {

                    clearTimeout(timeOutCounter)

                    self.setState({
                        open: true,
                        isLoading: false,
                        error: 'Please enter a valid Transaction Number!'
                    })
                }
                else if (snapshot.val().data.fetch_status.toLowerCase() === "done") {

                    let orderDetails = snapshot.val().data;
                    let status = orderDetails.status;

                    console.log('status: ', status)

                    if (status.toLowerCase() === "closed") {
                        self.setState({
                            open: true,
                            isLoading: false,
                            error: 'This order is Closed!'
                        })
                    }
                    else {

                       
                        if(status.toLowerCase() === "pendingbilling")
                        {
                            self.setState({
                                open: true,
                                isLoading: false,
                                error: 'This order is pending billing. Check transaction in Netsuite or contact ICT.'
                            })
                        }
                        else if(status.toLowerCase() === "partiallyfulfilled")
                        {
                            self.setState({
                                open: true,
                                isLoading: false,
                                error: 'This order is partially fulfilled. Check transaction in Netsuite or contact ICT.'
                            })
                        }
                        else if (status.toLowerCase() !== "received") {

                            self.setState({ isLoading: false });

                            let receiveItems = false;

                            if(orderDetails.transactionItems && orderDetails.transactionItems.length > 0)
                            {
                                orderDetails.transactionItems.forEach(item => {
                                    if(item.expectedtotal !== item.receivedtotal) {
                                        receiveItems = true;
                                    }
                                })
                            }
                            

                            if(receiveItems) {
                                let scannedItems = {};
                                orderDetails.items.forEach(element => {
                                    scannedItems[element.upccode] = {
                                        ...element,
                                        verified: false,
                                        notes: ''
                                    };
                                });

                                const { receiveStep } = client.readQuery({ query: gql`{ receiveStep @client }` })
                                self.setState({ isLoading: false });

                                client.writeData({
                                    data: {
                                        scannedItems: JSON.stringify(scannedItems),
                                        currentOrder: JSON.stringify(orderDetails),
                                        receiveStep: receiveStep + 1
                                    }
                                })
                            }
                            else {
                                self.setState({
                                    open: true,
                                    isLoading: false,
                                    error: 'The items in the order needs to be fulfilled before you can receive them!'
                                })
                            }
                        }
                        else {
                            self.setState({
                                open: true,
                                isLoading: false,
                                error: 'This order has already been received'
                            })
                        }

                    }
                }
            }
            else
            {
                clearTimeout(timeOutCounter)

                timeOutCounter = setTimeout(() => {
                        self.setState({
                            open: true,
                            isLoading: false,
                            error: 'Unable to load this transaction. Please check if order number is valid.'
                        })
                    },180000)
            }
        })

    

    }

    componentWillUnmount() {
        this._isMounted = false
    }

    render() {
        const { classes } = this.props;
        const { selectedLocation } = this.state;

        return (
            <div className={classes.root} >
                <Card className={classes.fetchTransactionDetailsForm} >
                    <CardContent>
                        <form onSubmit={this.onSubmit} >
                            <div style={{ marginBottom: "20px" }} >
                                <FormControl fullWidth >
                                    <InputLabel htmlFor="tno">Transaction Number</InputLabel>
                                    <Input required value={this.state.tno}
                                        onChange={(e) => this.setState({ tno: e.target.value })}
                                        fullWidth
                                        placeholder="Ex: TO00045"
                                    />
                                </FormControl>
                            </div>
                            <div style={{ marginBottom: "20px" }} >
                                <Select
                                    classNamePrefix="toms-select-component"
                                    styles={{
                                        menu: provided => ({ ...provided, zIndex: 9999 })
                                    }}
                                    value={selectedLocation}
                                    onChange={this.handleChange}
                                    options={locations}
                                    placeholder="Enter your location..."

                                />
                            </div>
                            <div style={{ marginBottom: "20px" }}>
                                <FormControl fullWidth >
                                    <InputLabel htmlFor="employeeName">Employee Name</InputLabel>
                                    <Input required id="component-simple" value={this.state.employeeName}
                                        onChange={(e) => this.setState({ employeeName: e.target.value })} />
                                </FormControl>

                            </div>
                            {
                                this.state.isLoading ?
                                    <div style={{ textAlign: "center" }} >
                                        <CircularProgress />
                                    </div>
                                    :
                                    <div style={{ marginTop: "30px" }}>
                                        <Button
                                            type="submit"
                                            fullWidth size='large'
                                            variant="contained" color="primary"
                                        >
                                            Submit
                                    </Button>
                                    </div>
                            }

                        </form>
                    </CardContent>
                </Card>
                <Modal
                    open={this.state.open}
                    onClose={this.handleClose}
                >
                    <div style={getModalStyle()} className={classes.paper}>
                        <Typography variant="subtitle1" style={{ color: "red" }} id="simple-modal-description">
                            {this.state.error}
                        </Typography>
                        <Button style={{ marginTop: "20px" }} color="primary" variant="outlined" size="small"
                            onClick={(e) => { this.setState({ open: false }) }}
                        >
                            OK
                        </Button>
                    </div>
                </Modal>
            </div>
        )
    }
}

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const styles = theme => ({
    paper: {
        position: 'absolute',
        width: theme.spacing.unit * 50,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 4,
        outline: 'none',
        textAlign: "center",
        [theme.breakpoints.down('xs')]: {
            width: theme.spacing.unit * 40,
            padding: theme.spacing.unit * 3
        }
    },
    root: {
        maxWidth: "500px", 
        margin: "0 auto", 
        padding: "30px",
        [theme.breakpoints.down('xs')]: {
            padding: "0",
        }
    },
    fetchTransactionDetailsForm: {
        padding: "30px", 
        overflow: "inherit",
        [theme.breakpoints.down('xs')]: {
            padding: "10px",
        }
    }
});

FetchTransactionDetails.propTypes = {
    classes: PropTypes.object.isRequired
}

export default withStyles(styles)(FetchTransactionDetails)
// export default FetchTransactionDetails
