import SCANNED_ITEMS from '../queries/SCANNED_ITEMS'


const toggleConfirmDiff = (_, { upccode, checked }, { cache }) => {

    const { scannedItems } = cache.readQuery({
        query: SCANNED_ITEMS
    })

    let parsedItems = JSON.parse(scannedItems)
    parsedItems[upccode].verified = checked;
    cache.writeData({
        data: {
            scannedItems: JSON.stringify(parsedItems)
        }
    })
    return null
}

export default toggleConfirmDiff