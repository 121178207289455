import React, { Component } from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core/styles';

const CustomTableCell = withStyles(theme => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
      letterSpacing: "1px",
      textTransform: "uppercase"
    }
  }))(TableCell);

export class CountRecordTable extends Component {
  render() {
    const { details, classes } = this.props;
    return (
      <div style={{ width: "100%" }} >
        <Table>
            <TableHead>
                <TableRow>
                    <CustomTableCell padding="dense" className={classes.hideColumn} >Item ID</CustomTableCell>
                    <CustomTableCell padding="dense">Item Name</CustomTableCell>
                    <CustomTableCell padding="dense" className={classes.hideColumn} >UPC Code</CustomTableCell>
                    {/* <CustomTableCell padding="dense">Retail Price</CustomTableCell> */}
                    <CustomTableCell padding="dense">Quantity</CustomTableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    details.countedItems.map((item, index) => (
                        <TableRow key={index} >
                            <TableCell padding="dense" className={classes.hideColumn}> {item.internalid} </TableCell>
                            <TableCell padding="dense" > {item.name} </TableCell>
                            <TableCell padding="dense" className={classes.hideColumn}> {item.upccode} </TableCell>
                            {/* <TableCell padding="dense"> {item.baseprice} </TableCell> */}
                            <TableCell padding="dense"> {item.quantity} </TableCell>
                        </TableRow>
                    ))
                }
            </TableBody>
        </Table>
      </div>
    )
  }
}

const styles = (theme) => ({
  hideColumn: {
    [theme.breakpoints.down('xs')]: {
      display: "none"
    }
  }
})

export default withStyles(styles)(CountRecordTable)
