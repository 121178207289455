import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import '@material-ui/core/TextField'
import CircularProgress from '@material-ui/core/CircularProgress'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import gql from 'graphql-tag'
import Modal from '@material-ui/core/Modal'
import Typography from '@material-ui/core/Typography'
import locations from '../../utils/Locations'
import Select from 'react-select'
import firebase from '../../firebaseConfig'

export class LocationForm extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      submitLoader: false,
      selectedLocation: null,
      name: '',
      memo: '',
      open: false,
      error: '',
      email: ''
    }
    this.onSubmit = this.onSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentWillMount() {

    const self = this;
    this._mounted = true;

    firebase.auth().onAuthStateChanged(user => {
      if(user && self._mounted) {
        this.setState({
          email: user.email
        })
      }
    })
  }

  onSubmit(e) {
    e.preventDefault();
    const { client } = this.props;
    const { selectedLocation, name, memo } = this.state;

    this.setState({
      submitLoader: true
    })

    if (selectedLocation !== null) {

      const { countStep } = client.readQuery({
        query: gql`{ countStep @client }`
      })

      this.setState({
        submitLoader: false
      })

      client.writeData({
        data: {
          countLocation: { internalid: selectedLocation.value, name: selectedLocation.label, subsidiary: selectedLocation.subsidiary, subsidiaryname: selectedLocation.subsidiaryname, __typename: 'locations'  },
          employeeName: name,
          memo: memo,
          countStep: countStep + 1
        }
      })
    }
    else {
      this.setState({
        error: 'Please enter a valid location.',
        open: true,
        submitLoader: false
      })
    }
  }

  handleChange = (selectedLocation) => {
    this.setState({ selectedLocation });
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  render() {
    const { isLoading, selectedLocation } = this.state
    const { classes, client } = this.props;
    return (
      <div style={{ maxWidth: "500px", margin: "0 auto" }} >
        {
          isLoading ?
            <CircularProgress />
            :
            <Card className={classes.root}>
              <CardContent>
                <form onSubmit={this.onSubmit} >
                  <div className={ classes.inputPadding }>
                    <Select
                      value={selectedLocation}
                      onChange={this.handleChange}
                      options={locations}
                      placeholder="Select your Location..."
                    />
                  </div>
                  <div className={classes.inputPadding} >
                    <TextField
                      inputProps={{
                        'required': true
                      }}
                      fullWidth
                      label="Your Name"
                      value={this.state.name}
                      onChange={(e) => this.setState({ name: e.target.value })}
                    />
                  </div>
                  <div className={classes.inputPadding} >
                    <TextField
                      inputProps={{
                        'required': true
                      }}
                      fullWidth
                      label="Memo"
                      value={this.state.memo}
                      onChange={(e) => this.setState({ memo: e.target.value })}
                    />
                  </div>
                  <div style={{ marginTop: "30px" }}>
                    {
                      this.state.submitLoader ?
                        <CircularProgress />
                        :
                        <Button size="large" variant="contained" color="primary" fullWidth type="submit" >
                          Count Items
                        </Button>
                    }
                  </div>
                </form>
                <div style={{ marginTop: "20px" }} >
                  <Typography variant="subtitle1" >
                    OR
                  </Typography>
                  <Button fullWidth size="large" color="primary" variant="outlined" style={{marginTop: "20px"}}
                    onClick={(e) => { 

                      if(this.state.email === "countingmaster@oakandfort.com" || this.state.email === "countingmaster_dev@oakandfort.com") {
                        client.writeData({
                          data: { countStep: 2 }
                        })
                      }
                      else {
                        alert("You are not authorized to submit FIC !");
                      }
                    }}
                  >
                    Verify/Submit FIC
                  </Button>
                </div>
              </CardContent>
            </Card>
        }
        <Modal
          open={this.state.open}

        >
          <div style={getModalStyle()} className={classes.paper}>
            <Typography variant="subtitle1" style={{ color: "red" }} id="simple-modal-description">
              {this.state.error}
            </Typography>
            <Button style={{ marginTop: "20px" }} color="primary" variant="outlined" size="small"
              onClick={(e) => { this.setState({ open: false }) }}
            >
              OK
            </Button>
          </div>
        </Modal>
      </div>
    )
  }
}

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

LocationForm.propTypes = {
  classes: PropTypes.object.isRequired
}

const styles = theme => ({
  root: {
    padding: "30px",
    [theme.breakpoints.down('xs')]: {
      padding: "20px 10px"
    }
  },
  paper: {
    position: 'absolute',
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: 'none',
    textAlign: "center"
  },
  inputPadding: {
    marginBottom: "20px"
  }
})

export default withStyles(styles)(LocationForm)
