import gql from 'graphql-tag';

const ITEM_DETAILS = gql`
query ($upccode: String, $internalid: String, $name: String, $sku: String, $token: String!, $locationid: String){
    findItemDetails(upccode: $upccode, internalid: $internalid, name: $name, sku: $sku, token: $token, locationid: $locationid ) {
      err
      internalid
      name
      upccode
      merchandise_hierarchy
      location_average_cost
      average_cost
      on_hand
    }
   }
`

export default ITEM_DETAILS;