import gql from 'graphql-tag';

const INCREMENT_COUNT_UPC = gql`
    mutation incrementCountUpc($upccode: String!) {
        incrementCountUpc(upccode: $upccode) @client {
            upccode
        }
    }
`

export default INCREMENT_COUNT_UPC