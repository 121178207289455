import gql from 'graphql-tag';

const SUBMIT_FIC = gql`
mutation( $finalCount: Fic!, $token: String!) {
    submitFic(finalCount: $finalCount, token: $token) {
        msg
        err
        tranid
    }
}
`
export default SUBMIT_FIC;
