import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import Today from '@material-ui/icons/Today'
// import theme from '../theme';
import {withStyles} from '@material-ui/core/styles';

export class LocationDetails extends Component {
  render() {
    const { locationDetails, classes } = this.props;

    return (
      <div className={classes.root}>
        <Grid container className={classes.container} spacing={16} >
            <Grid item xs={12} sm={6}  >
               <strong>Location:</strong> { locationDetails.countLocation.name }
            </Grid>
            <Grid item xs={12} className={classes.divider} >
                <Divider style={{ width: "100%" }} />
            </Grid>
            <Grid item item xs={12} sm={6} className={classes.alignRight}  >
                <Today style={{ verticalAlign: "middle" }} /> <span> { new Date().toDateString() } </span>
            </Grid>
            <Grid item xs={12} >
                <Divider style={{ width: "100%" }} />
            </Grid>
            <Grid item item xs={12} sm={6} >
                <strong> Employee Name: </strong> { locationDetails.employeeName }
            </Grid>
            <Grid item xs={12} className={classes.divider} >
                <Divider style={{ width: "100%" }} />
            </Grid>
            <Grid item item xs={12} sm={6} className={classes.alignRight} >
                <strong> Memo: </strong> { locationDetails.memo }
            </Grid>
        </Grid>
      </div>
    )
  }
}

const styles = (theme) => ({
  root: {
    padding: "20px", 
    backgroundColor: "#fff", 
    margin: "10px", 
    borderLeft: "5px solid black",
    [theme.breakpoints.down('xs')]: {
      margin: '0'
    }
  },
  container: {
    textAlign: "left",
    [theme.breakpoints.down('xs')]: {
      textAlign: 'left'
    }
  },
  alignRight: {
    textAlign: 'right',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'left'
    }
  },
  divider: {
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      display: 'block'
    }
  }
})

export default withStyles(styles)(LocationDetails);
