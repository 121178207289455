import React, { Component } from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Modal from '@material-ui/core/Modal';
import TextField from '@material-ui/core/TextField'
import Fab from '@material-ui/core/Fab';
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Delete from '@material-ui/icons/Delete'
import { withStyles } from '@material-ui/core/styles';
import { compose, graphql } from 'react-apollo'
import gql from 'graphql-tag'
import BarcodeReader from 'react-barcode-reader';
import ITEM_DETAILS from '../../Receiving/queries/ITEM_DETAILS'
import GET_COUNTED_ITEMS from '../queries/GET_COUNTED_ITEMS'
import NEW_ITEM_UPC from '../mutations/NEW_ITEM_UPC';
import INCREMENT_COUNT_UPC from '../mutations/INCREMENT_COUNT_UPC'
import SET_COUNT_UPC from '../mutations/SET_COUNT_UPC'
import REMOVE_ITEM from '../mutations/REMOVE_ITEM'
import firebase from '../../firebaseConfig'

const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    letterSpacing: "1px",
    textTransform: "uppercase"
  }
}))(TableCell);

export class CountItemsTable extends Component {

  constructor(props) {
    super(props)
    this.state = {
      loader: false,
      open: false,
      error: '',
      buffer: []
    }

    this.handleScan = this.handleScan.bind(this);
    this.deleteRow = this.deleteRow.bind(this);
    this.updateTable = this.updateTable.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
  }

  handleScan(data) {
    const { buffer } = this.state;  

    if (data.split("").length > 9) {
      let index = buffer.length;
      this.setState({
        buffer: [...buffer, { upc: data, processed: false }]
      })
      this.updateTable(index);
    }
  }

  updateTable(index) {
    const { client, start, newItemUpc } = this.props;
    let self = this;

    const token = sessionStorage.getItem('token');

    if (start && this.state.buffer[index] && !this.state.buffer[index].processed) {
      this.setState({
        loader: true
      })

      let data = this.state.buffer[index];

      firebase.auth().onAuthStateChanged(user => {

        if (user && self._isMounted) {

          const { countLocation } = client.readQuery({
            query: gql`{ countLocation @client { internalid, name, __typename } }`
          })

          client.query({
            query: ITEM_DETAILS,
            variables: { token: token, upccode: data.upc, locationid: countLocation.internalid }
          })
          .then(({ data: { findItemDetails } }) => {
              // console.log(findItemDetails)
              if(findItemDetails.err && findItemDetails.err != null) {
                alert(findItemDetails.err);
              }
              else {
                if(this.state[findItemDetails.upccode]) {
                  this.setState({ [findItemDetails.upccode]: this.state[findItemDetails.upccode] + 1 });
                }
                else {
                  this.setState({ [findItemDetails.upccode]: 1 });
                }
  
                // Call mutation to add item
                newItemUpc({ variables: { upccode: findItemDetails.upccode, locationid: countLocation.internalid } });
  
                client.writeData({
                  data: { lastModified: findItemDetails.name.split(":")[1].trim() }
                });
              }

              this.setState({
                loader: false
              })
          })
          .catch(err => {
              console.log(err);
              this.setState({
                loader: false,
                open: true,
                error: 'Invalid UPC Code!'
              })
              alert(err)
          })
        }
      })

      const { buffer } = this.state;
      this.setState({
        buffer: buffer.map((item, i) => {
          if (i !== index) {
            return item
          }
          return {
            ...item,
            processed: true
          }
        })
      })
    }
    else {
      this.setState({
        loader: false,
        open: true,
        error: 'Click on Start Scan first'
      })
    }
  }

  deleteRow(internalid) {
    const { removeItem } = this.props;
    removeItem({ variables: { internalid: internalid } })
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { getCountedItems: { countedItems }, lastModified: { lastModified }, start, classes, client } = this.props;

    return (
      <div style={{ backgroundColor: "#fff", marginTop: "40px" }} >
        <Table >
          <TableHead>
            <TableRow>
              <CustomTableCell padding="dense" className={classes.hideColumn} >Item ID</CustomTableCell>
              <CustomTableCell padding="dense">Item Name</CustomTableCell>
              <CustomTableCell padding="dense" className={classes.hideColumn}>UPC Code</CustomTableCell>
              {/* <CustomTableCell padding="dense">Retail Price</CustomTableCell> */}
              <CustomTableCell padding="dense">Quantity</CustomTableCell>
              <CustomTableCell padding="dense"></CustomTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              countedItems && countedItems.map((item, index) => (

                <TableRow key={index} >
                  <TableCell padding="dense" className={classes.hideColumn}> {item.internalid} </TableCell>
                  <TableCell padding="dense"> {item.name} </TableCell>
                  <TableCell padding="dense" className={classes.hideColumn} > {item.upccode} </TableCell>
                  {/* <TableCell padding="dense"> {item.baseprice} </TableCell> */}
                  <TableCell padding="dense"
                    className={(lastModified === item.upccode || lastModified === item.name) && start ? "blink" : ""}
                    onBlur={(e) => {
                      e.target.classList.remove('blink')
                    }}
                  >
                    {
                      start ?
                        <TextField name={item.upccode}
                          // value={ this.state[item.upccode] === "undefined" ? 0 : this.state[item.upccode] }
                          value={item.quantity}
                          inputProps={{
                            type: 'number',
                            className: 'qtyInput'
                          }}
                          onKeyPress={(e) => {  }}
                          onChange={(e) => {
                            e.preventDefault();

                            let val = e.target.value;
                            if (parseInt(val) > 0) {

                              this.setState({ [e.target.getAttribute("name")]: e.target.value });
                              this.props.setCountUpc({ variables: { upccode: item.upccode, quantity: parseInt(e.target.value) } })
                              client.writeData({ data: { lastModified: item.name }})

                            } else {

                              this.setState({ [e.target.getAttribute("name")]: 0 });
                              this.props.setCountUpc({ variables: { upccode: item.upccode, quantity: 0 } })
                            }
                          }}
                        />
                        :
                        parseInt(item.quantity)

                    }
                  </TableCell>
                  <TableCell padding="dense">
                    <Fab disabled={start ? false : true} size="small" color="secondary"
                      onClick={(e) => {
                        this.deleteRow(item.internalid)
                      }}
                    >
                      <Delete />
                    </Fab>
                  </TableCell>
                </TableRow>
              ))
            }
          </TableBody>
        </Table>
        {
          this.state.loader ?
            <div style={{ textAlign: "center" }}>
              <CircularProgress />
            </div>
            :
            null
        }

        <BarcodeReader
          onScan={
            (data) => {
              this.handleScan(data);
            }
          }
          onError={
            (data) => {
              this.handleScan(data);
            }
          }
          
          // onError={(err) => { console.log(err) }}
          // minLength={12}
        />

        <Modal open={this.state.open} >
          <div style={getModalStyle()} className={classes.paper} >
            <Typography variant="subtitle1" style={{ color: "red" }} id="simple-modal-description">
              {this.state.error}
            </Typography>
            <Button style={{ marginTop: "20px" }} color="primary" variant="outlined" size="small"
              onClick={(e) => { this.setState({ open: false }) }}
            >
              OK
            </Button>
          </div>
        </Modal>
      </div>
    )
  }
}

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const styles = theme => ({
  paper: {
    position: 'absolute',
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: 'none',
    textAlign: "center"
  },
  hideColumn: {
    // display: 'block',
    [theme.breakpoints.down('xs')]: {
      display: "none"
    }
  }
})

const ComposedTable = compose(
  graphql(GET_COUNTED_ITEMS, { name: 'getCountedItems' }),
  graphql(NEW_ITEM_UPC, { name: 'newItemUpc' }),
  graphql(INCREMENT_COUNT_UPC, { name: 'incrementCountUpc' }),
  graphql(REMOVE_ITEM, { name: 'removeItem' }),
  graphql(gql`{lastModified @client}`, { name: 'lastModified' }),
  graphql(SET_COUNT_UPC, { name: 'setCountUpc' })
)(CountItemsTable)

export default withStyles(styles)(ComposedTable)
