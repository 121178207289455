import GET_COUNTED_ITEMS from '../queries/GET_COUNTED_ITEMS'

const incrementCountUpc = (_, { upccode }, { cache }) => {

    const previousState = cache.readQuery({
        query: GET_COUNTED_ITEMS
    })

    cache.writeQuery({
        query: GET_COUNTED_ITEMS,
        data: {
            countedItems: previousState.countedItems.map((item) => {
                if (item.upccode !== upccode) {
                    return item
                }
                return {
                    ...item,
                    quantity: parseInt(item.quantity) + 1
                }
            })
        }
    })
    return null
}

export default incrementCountUpc