import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'

export class OrderDetails extends Component {
    render() {

        const { orderDetails, classes } = this.props;
        return (
            <Grid container spacing={16} style={{ padding: "10px 20px", backgroundColor: "#fff", borderLeft: "5px solid black" }} >
                <Grid item xs={6}>
                    <p className={classes.tranDetails} >
                        <strong> Order Number </strong> - {orderDetails.documentnumber}
                    </p>
                </Grid>
                <Grid item xs={6} >
                    <Grid container justify="flex-end" >
                        <p className={classes.tranDetails} style={{ textAlign: "right" }} >
                            <strong> Memo </strong> -  {orderDetails.memo} </p>
                    </Grid>
                </Grid>
                <Grid item xs={12} >
                  <Divider style={{ width: "100%" }} />
                </Grid>
                <Grid item xs={6} >
                    <Grid container justify="flex-end" >
                        <p className={classes.tranDetails} style={{ textAlign: "left" }} >
                            <strong>Sent From </strong> -  {orderDetails.sendinglocation.locationname}
                        </p>
                    </Grid>
                </Grid>
                <Grid item xs={6} >
                    <p className={classes.tranDetails} style={{ textAlign: "right" }} >
                        <strong> Receiving At </strong> - {orderDetails.receivinglocation.locationname}
                    </p>
                </Grid>
            </Grid>
        )
    }
}

const styles = theme => ({
    tranDetails: {
      flexBasis: "100%",
      textAlign: "left",
      margin: "0",
      fontSize: "14px"
    }
})

OrderDetails.propTypes = {
    classes: PropTypes.object.isRequired
  }

export default withStyles(styles)(OrderDetails)
