import gql from 'graphql-tag';

const SET_COUNT_UPC = gql`
    mutation setCountUpc($upccode: String!, $quantity: Int!) {
        setCountUpc(upccode: $upccode, quantity: $quantity) @client {
            upccode
        }
    }
`

export default SET_COUNT_UPC