import React, { Component } from 'react'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal'
// import ITEM_DETAILS from '../queries/ITEM_DETAILS'
// import gql from 'graphql-tag'
// import TRANSACTION_QUERY from '../queries/TRANSACTION_QUERY'
import { compose, graphql } from 'react-apollo'
// import INCREMENT_QTY_SKU from '../mutations/INCREMENT_QTY_SKU'
import ADD_ITEM_SKU from '../mutations/ADD_ITEM_SKU'
import AddCircleOutline from '@material-ui/icons/AddCircleOutline'
import CircularProgress from '@material-ui/core/CircularProgress'
import firebase from '../../firebaseConfig';

import axios from 'axios';


export class AddBySku extends Component {
    constructor(props) {
        super(props)
        this.state = ({
            sku: '',
            open: false,
            added: false,
            error: null,
            isLoading: false
        })
        this._isMounted = false;
        this.unsubscribeFromAuth = null;
    }

    componentDidMount() {
        this._isMounted = true;
        this.setState({
            error: null,
            added: false
        })
    }

    handleSkuChange = (e) => {
        this.setState({ sku: e.target.value })
    }

    onSkuSubmit = (e) => {

        e.preventDefault();
        
        this.setState({
            added: false,
            error: null,
            isLoading: true
        })
        const { client, addItemSku, orderDetails } = this.props;
        const locationid = orderDetails.receivinglocation.locationid+"";
        const self = this;

        if (this.state.sku.trim() !== "") {

            this.unsubscribeFromAuth = firebase.auth().onAuthStateChanged(user => {
                if(user && self._isMounted) {
                    

                    firebase.auth()
                        .currentUser
                        .getIdToken(true)
                        .then(token => {


                            //let sample_expired = 'eyJhbGciOiJSUzI1NiIsImtpZCI6IjU1NGE3NTQ3Nzg1ODdjOTRjMTY3M2U4ZWEyNDQ2MTZjMGMwNDNjYmMiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vb2FrLWFuZC1mb3J0LWludmVudG9yeS1mZWRjMSIsImF1ZCI6Im9hay1hbmQtZm9ydC1pbnZlbnRvcnktZmVkYzEiLCJhdXRoX3RpbWUiOjE1OTU4MDY2NTksInVzZXJfaWQiOiJxTHVUOFVjZ0hSUm9BR0NrR2d6WlVkNno4anIxIiwic3ViIjoicUx1VDhVY2dIUlJvQUdDa0dnelpVZDZ6OGpyMSIsImlhdCI6MTU5NTgxODM1MCwiZXhwIjoxNTk1ODIxOTUwLCJlbWFpbCI6ImNvdW50aW5nbWFzdGVyQG9ha2FuZGZvcnQuY29tIiwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJmaXJlYmFzZSI6eyJpZGVudGl0aWVzIjp7ImVtYWlsIjpbImNvdW50aW5nbWFzdGVyQG9ha2FuZGZvcnQuY29tIl19LCJzaWduX2luX3Byb3ZpZGVyIjoicGFzc3dvcmQifX0.qFxsld7w7AXidRIIoJgixTFFu-zXF7favdB--TLVLBDKrffT1i6VWlvFkKd3LEZ7_6ZXAbey3l1ysv9scx3xaS2wdVAYB1-UZYV2biBkfEVO7GG9WjS1y-IfXkHR68BND6ulfZmhDON2tkjGTsGgMwuLmtyxz8a2LOAqwJBgX-BUUDBUHs2IfhCnxLYAg0_QcLh5C4CNYlse3FZLzufWT4S4dTn3569l2UgltgiDa5P5U2PI0dhBZeOOWeCpFG6bLsLLvG5nEU4QzQb7AFmowIcdSVN1dX8DIkrpJy9i27qcJ7p0pvyC8SIaSfAtzbbWou1dTqAAfY5ACkJHB79ujA'

                            return axios({ method: "post",
                                           url: 'https://3huv5xrb2d.execute-api.ca-central-1.amazonaws.com/dev/get_item_details',
                                           headers: {
                                            Authorization: token
                                           },
                                           data: {
                                            transaction: orderDetails.documentnumber.toUpperCase(),
                                            location: sessionStorage.getItem('loc'),
                                            sku: self.state.sku.trim()
                                           }
                                        })
                                        .then(res => {
                                            
                                           let item = res['data']['transaction']

                                           if(item['err'])
                                           {
                                                this.setState({
                                                    ...this.state,
                                                    isLoading: false
                                                })
                                               return alert("Nothing To Retrieve. Kindly double check SKU and try again.")
                                           }
                                           
                                           client.writeData({
                                                data: { 
                                                    itemDetails: JSON.stringify(item),
                                                    lastScanned: item.name.split(":")[1].trim()
                                                }
                                           });
                        
                                           addItemSku({ variables: { 
                                                sku: self.state.sku.trim().toUpperCase() ,
                                                upccode: item.upccode,
                                                locationid: locationid
                                           } })
                        
                                            
                                            this.setState({
                                                added: true,
                                                sku: '',
                                                isLoading: false
                                            })
                                            
                                            
                                           return true;
                                        })
                                        .catch(err => {

                                            console.log("error fetching transaction details: ", err)
                                            self.setState({ isLoading: false });
                                            return true;
                                        })
                    
                        })
                        .catch(err => {
                            console.log("error refreshing token: ", err)
                        })


                    /*
                    // let db_node = firebase.database().ref(`${user.uid}/physical_stores/store_node`);

                    let db_node = firebase.database().ref(`receiving/physical_stores/store_node`);

                    db_node.child(orderDetails.receivinglocation.locationname).child('search_item').child(orderDetails.documentnumber.toUpperCase()).remove()
                    .then(() => {

                        db_node.child(orderDetails.receivinglocation.locationname).child('search_item').update({
                            [orderDetails.documentnumber.toUpperCase()] : {
                                store: orderDetails.receivinglocation.locationname,
                                item: { sku:  self.state.sku},
                                transaction: orderDetails.documentnumber,
                                date: new Date(),
                                status: "submit"
                            }
                        }).then(() => {
                            self.fetchItemDetails(db_node);
                        })
                        .catch(err => {
                            console.error(err);
                            alert(err);
                        })
                    })
                    .catch(err => {
                        console.error(err);
                        alert(err);
                    })

                    */
                }
            })
        }
        else {
            this.setState({
                added: false,
                isLoading: false
            })
        }
        
    }

    fetchItemDetails = (db_node) => {
        const self = this;
        const { client, addItemSku, orderDetails } = this.props;
        let retrieved_node = db_node.child(orderDetails.receivinglocation.locationname).child('search_item').child(orderDetails.documentnumber);
        let locationid = orderDetails.receivinglocation.locationid+"";

        retrieved_node.on('value', (snapshot) => {

            if(self._isMounted && snapshot.val() && snapshot.val() !== null) {

                if(snapshot.val().status === "done" && !snapshot.val().item && !self.state.added) {

                    let itemDetails = snapshot.val().data;

                    client.writeData({
                        data: { 
                            itemDetails: JSON.stringify(itemDetails),
                            lastScanned: itemDetails.name.split(":")[1].trim()
                        }
                    });

                    addItemSku({ variables: { 
                        sku: self.state.sku.trim().toUpperCase() ,
                        upccode: itemDetails.upccode,
                        locationid: locationid
                    } })

                    
                    this.setState({
                        added: true,
                        sku: '',
                        isLoading: false
                    })

                }
                else if(snapshot.val().status && snapshot.val().status === "Nothing To Retrieve") {
                    this.setState({
                        isLoading: false,
                        error: "Something wrong! Please check if sku is valid"
                    })
                    
                }
            }
        })
    }

    componentWillUnmount() {
        this._isMounted = false;
        //this.unsubscribeFromAuth();
    }

    render() {
        const { classes, disabled } = this.props;

        return (
            <div >
                <Button disabled={disabled} variant="contained" size="small"
                    color="secondary"
                    onClick={(e) => { this.setState({ open: true }) }}
                >
                    Add By Sku
                    <AddCircleOutline style={{ marginRight: "10px" }} />
                </Button>
                <Modal
                    open={this.state.open}
                    onClose={this.handleClose}
                >
                    <div style={getModalStyle()} className={classes.paper}>
                        <Typography variant="h6" id="modal-title">
                            Add Item
                        </Typography>

                        <form style={{ margin: "10px 0" }} onSubmit={this.onSkuSubmit} >
                            <div style={{ marginBottom: "20px" }} >
                                <TextField
                                    fullWidth
                                    label="Enter SKU"
                                    placeholder="EX: BA-1256-117-W-OLIVE-O"
                                    value={this.state.sku}
                                    onChange={this.handleSkuChange}
                                />
                            </div>
                            {
                                this.state.isLoading ?
                                    <div style={{ textAlign: "center" }} >
                                        <CircularProgress />
                                    </div> :
                                    <div>
                                        <Button size="small" type="submit"
                                            variant="contained" color="primary" >
                                            Submit
                                    </Button>
                                        <Button size="small"
                                            onClick={() => { this.setState({ open: false, error: null, added: false, sku: '' }) }}
                                            variant="outlined" color="primary" style={{ marginLeft: "20px" }} >
                                            Exit
                                    </Button>
                                    </div>
                            }
                        </form>
                        <div>
                            {
                                this.state.added ?
                                    <p style={{ color: "green" }}>
                                        Item has been added!
                                    </p> :
                                    null
                            }
                        </div>
                        <div>
                            {
                                this.state.error === null ?
                                null :
                                <p style={{ color: "red" }}>
                                    {this.state.error}
                                </p>
                            }
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }
}

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const styles = theme => ({
    paper: {
        position: 'absolute',
        width: theme.spacing.unit * 50,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 4,
        outline: 'none',
    },
});

AddBySku.propTypes = {
    classes: PropTypes.object.isRequired
}

const AddBySkuWithStyles = withStyles(styles)(AddBySku)

export default compose(
    // graphql(INCREMENT_QTY_SKU, { name: 'incrementQuantitySku' }),
    graphql(ADD_ITEM_SKU, { name: 'addItemSku' })
)(AddBySkuWithStyles)
