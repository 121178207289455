import React, { Component } from 'react'
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles';
// import CardContent from '@material-ui/core/CardContent'
// import TextField from '@material-ui/core/TextField'
// import Modal from '@material-ui/core/Modal'
import Button from '@material-ui/core/Button'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress'
import locations from '../../utils/Locations'
import Select from 'react-select';
import OrderStatusModal from './OrderStatusModal';
import Input from '@material-ui/core/Input';
import SearchIcon from '@material-ui/icons/Search';
import Divider from '@material-ui/core/Divider';
import firebase from '../../firebaseConfig';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import qs from 'query-string';

const CustomTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
        letterSpacing: "1px",
        textTransform: "uppercase"
    }
}))(TableCell);

export class ReceiveStatus extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loader: false,
            location: null,
            orders: [],
            filteredOrders: [],
            page: 0,
            rowsPerPage: 10,
            showModal: false,
            selectedOrder: null
        }

        this.handleChange = this.handleChange.bind(this);
        this.filterTable = this.filterTable.bind(this);
        this._isMounted = false;
        
    }

    componentWillMount() {
        let query = qs.parse(this.props.location.search);
        if(query.store) {
            let location = locations.find((elem) => elem.label === query.store);
            if(location) {
                this.handleChange(location);
            }
        }
    }

    componentDidMount() {
        this._isMounted = true;
    }

    handleChange = (location) => {
        let orders = [], self = this;
        this.setState({ 
            location: location,
            loader: true
        });

        firebase.auth().onAuthStateChanged(user => {
            if(user && self._isMounted) {
                // let db_node = firebase.database().ref(`${user.uid}/physical_stores/store_node`);
                let db_node = firebase.database().ref(`receiving/physical_stores/store_node`);
                db_node.child(location.label).child('submitted_transactions').on('value', (snapshot) => {
                    if(self._isMounted) {
                        self.setState({
                            loader: true
                        })
                        orders = [];
                        snapshot.forEach(order => {
                            orders.push({
                                id: order.key,
                                details: order.val(),
                                status: order.val().status
                            })
                            orders.reverse();
                        })
                        self.setState({
                            orders: orders,
                            filteredOrders: orders,
                            loader: false
                        })
                    }
                })
            }
        })
    }

    showOrderDetails(e, order) {

        this.setState({
            showModal: true,
            selectedOrder: order
        })
    }

    filterTable(e) {
        let filteredOrders = [];

        for(let i = 0; i < this.state.orders.length; i++) {
            let order = this.state.orders[i];
            if(order.id.indexOf(e.target.value.toUpperCase()) > -1) {
                filteredOrders.push(order)
            }
        }
        this.setState({
            filteredOrders
        })
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {

        const { classes } = this.props;
        const { page, rowsPerPage, location } = this.state;

        return (
            <div className={classes.container}>
                <Paper className={classes.root}>
                    <Typography variant="h5" gutterBottom>
                        Check Receiving Status
                    </Typography>
                </Paper>
                <Grid container spacing={24} justify="center" >
                    <Grid item xs={12} >
                        <Typography variant="h6" gutterBottom style={{maxWidth: "650px", margin: "auto"}} >
                            To check the status of a Transfer/Purchase order that was submitted through the system, please select the appropriate receiving location.
                        </Typography>
                    </Grid>

                    <Grid item xs={3}  >
                        <Select
                            value={location}
                            onChange={this.handleChange}
                            options={locations}
                            placeholder="Select your Location..."
                        />
                    </Grid>
                    
                    
                    <Grid item xs={10}>
                        <Divider style={{ width: "100%" }} />
                    </Grid>
                    <Grid style={{marginTop: "10px"}} item xs={9}>
                        {
                            this.state.loader ? 
                                <div style={{ textAlign: "center" }} >
                                    <CircularProgress style={{marginBottom: "10px"}} />
                                    <div>Hold On...</div>
                                </div>
                            :
                            this.state.orders.length === 0 ?
                            <Typography>No records found!</Typography>
                            :
                            <div style={{borderRadius: "0"}}>
                                {
                                    this.state.location === null ?
                                    null:
                                    <div style={{padding: "20px 0px", maxWidth: "350px", textAlign: "left"}}>
                                        <Input
                                            id="outlined-bare"
                                            placeholder="Filter by order number..."
                                            onChange={(e) => this.filterTable(e) }
                                        />
                                        <SearchIcon style={{verticalAlign: "middle"}} />
                                    </div>
                                }
                                <Table style={{border: "1px solid rgba(224, 224, 224, 1)"}}>
                                    <TableHead>
                                        <TableRow>
                                            <CustomTableCell >Order Number</CustomTableCell>
                                            <CustomTableCell> Last Updated On (mm-dd-yyyy)</CustomTableCell>
                                            <CustomTableCell> Last Received By </CustomTableCell>
                                            <CustomTableCell>Status</CustomTableCell>
                                            <CustomTableCell >  </CustomTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody style={{backgroundColor: "white"}}>
                                        {
                                            this.state.filteredOrders.length === 0 ?
                                            null
                                            :
                                            this.state.filteredOrders.slice(page*rowsPerPage, page*rowsPerPage + rowsPerPage)
                                            .map((order, index) => (
                                                <TableRow key={index} >
                                                    <TableCell> { order.id } </TableCell>
                                                    <TableCell> { order.details.lastUpdated } </TableCell>
                                                    <TableCell> { order.details.lastReceivedBy } </TableCell>
                                                    <TableCell> { order.status } </TableCell>
                                                    <TableCell> 
                                                        <Button
                                                        style={{flex: "1"}}
                                                         variant="text"
                                                         onClick={(e) => this.showOrderDetails(e, order)}
                                                         color="primary" size="medium" >
                                                            View Details
                                                        </Button>    
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        }
                                        
                                    </TableBody>
                                </Table>
                                <TablePagination
                                    rowsPerPageOptions={[10, 15, 20]}
                                    component="div"
                                    count={this.state.orders.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    backIconButtonProps={{
                                        'aria-label': 'Previous Page',
                                    }}
                                    nextIconButtonProps={{
                                        'aria-label': 'Next Page',
                                    }}
                                    onChangePage={(e, newPage) => {
                                        this.setState({
                                            page: newPage
                                        })
                                    }}
                                    onChangeRowsPerPage={(e) => { this.setState({ rowsPerPage: e.target.value }) }}
                                />
                            </div>
                        }
                    </Grid>
                </Grid>
                {
                    this.state.selectedOrder === null ? 
                        null 
                        :
                        <Dialog
                            open={this.state.showModal} 
                            onClose={() => this.setState({ showModal: false })}
                            fullWidth={true}
                            maxWidth='md'
                            style={{borderRadius: "0"}}
                            
                        >
                            <IconButton aria-label="Close" className={classes.closeButton} onClick={() => {this.setState({ showModal: false })}}>
                                <CloseIcon />
                            </IconButton>
                            <OrderStatusModal order={this.state.selectedOrder} />
                        </Dialog>
                }
                
            </div>
        )
    }
}

const styles = theme => ({
    container: {
        marginTop: "65px",
        textAlign: "center",
        minHeight: "100vh",
        backgroundColor: "#f5f5f5"
    },
    root: {
        // ...theme.mixins.gutters(),
        paddingTop: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
        marginBottom: "50px",
        borderRadius: "0"
    },
    closeButton: {
        alignSelf: "flex-end"
    }
})

export default withStyles(styles)(ReceiveStatus)
