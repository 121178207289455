import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
// import ReceiptIcon from '@material-ui/icons/Receipt';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button'
import logo from '../assets/Oak+Fort-logo-thin-white.svg'

import {ApolloConsumer} from 'react-apollo'
import firebase from '../firebaseConfig'
import { Link } from 'react-router-dom'

export class Navbar extends Component {

  constructor(props) {
    super(props);
    this.state = {
      left: false,
    }
  }

  toggleDrawer = (open) => {
    this.setState({
      left: open,
    })
  }

  signOut = (client) =>  {

    firebase.auth().signOut()
    .then(() => {
      client.clearStore()
    })
    .catch((err) => {
      console.error(err)
      alert(err)
    })
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <AppBar color="primary" >
          <Toolbar>
            <IconButton color="inherit" aria-label="Open Drawer" onClick={ this.toggleDrawer.bind(this, true) }>
              <MenuIcon />
            </IconButton>
            <div className={classes.grow}>
              <Link to="/home" >
                <img src={logo} style={{maxWidth: "200px"}} alt="logo" />
              </Link>
            </div>
            <ApolloConsumer>
              {
                client => (
                  <Button color="inherit" onClick={ (e) => { this.signOut(client); } } >
                    <i className="fas fa-sign-out-alt"></i>
                  </Button>
                )
              }
            </ApolloConsumer>
            
          </Toolbar>
        </AppBar>
        <SwipeableDrawer anchor="left" open={this.state.left}
          onClose={this.toggleDrawer.bind(this, false)}
          onOpen={this.toggleDrawer.bind(this, true)}
        > 
          <div
            tabIndex={0}
            role="button"
            onClick={this.toggleDrawer.bind(this, false)}
          >
            <div className={classes.list}>
              <div style={{textAlign: "right"}} >
                <IconButton style={{ color: "#000" }} onClick={this.toggleDrawer.bind(this, true)}  >
                  <ChevronLeftIcon />
                </IconButton>
              </div>
              <Divider />
              <List>
                <ListItem color="primary" button component={Link} to='/receive' >
                    <ListItemIcon style={{ color: "#000" }}> 
                      <i className="fas fa-truck-loading"></i>
                    </ListItemIcon>
                    <ListItemText style={{ color: "#000" }} primary="Receive Order" />
                </ListItem>
                <ListItem  button component={Link} to="/count" >
                    <ListItemIcon style={{ color: "#000" }}> 
                      <i className="fas fa-warehouse"></i>
                    </ListItemIcon>
                    <ListItemText style={{ color: "#000" }} primary="Count Inventory" />
                </ListItem>
                
                <ListItem button component={Link} to={{ pathname: "/orderstatus" }}  >
                    <ListItemIcon style={{ color: "#000" }}> 
                      <i className="fas fa-info-circle"></i>
                    </ListItemIcon>
                    <ListItemText style={{ color: "#000" }} primary="Receiving Status" />
                </ListItem>
                
                <ListItem button component="a" href="https://support.oakandfort.com/home" >
                    <ListItemIcon style={{ color: "#000" }}> 
                      <i className="fas fa-question-circle"></i>
                    </ListItemIcon>
                    <ListItemText style={{ color: "#000" }} primary="Support" />
                </ListItem>
              </List>
            </div>
          </div>
        </SwipeableDrawer>
      </div>
    )
  }
}

const styles = {
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
}

Navbar.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(Navbar);


