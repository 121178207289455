import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import DateRangeModal from './DateRangeModal'
import { withApollo, compose, graphql } from 'react-apollo'
import gql from 'graphql-tag'
import Fab from '@material-ui/core/Fab'
import SearchCriteria from './SearchCriteria'
import ADD_CHECKBOX from '../mutations/ADD_CHECKBOX'
import INCLUDE_ALL_COUNTS from '../mutations/INCLUDE_ALL_COUNTS'
import Edit from '@material-ui/icons/Edit'
import Grid from '@material-ui/core/Grid'
import PartialCounts from './PartialCounts'
// import CHECK_IC_STATUS from '../queries/CHECK_IC_STATUS'

const DateRangeModalWithClient = withApollo(DateRangeModal);
const SearchCriteriaWithClient = withApollo(SearchCriteria);
const PartialCountsWithClient = withApollo(PartialCounts);

export class SaveCount extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            open: true,
            loader: false,
            fetchLoader: false,
            selectAll: false
        }
    }

    componentDidMount() {
        this._isMounted = true;

        this.props.client.writeData({
            data: {
                includeAllFic: false
            }
        })

    }

    componentDidUpdate() {
        // console.log('update')
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        const {
            submitLocation: { submitLocation },
            startDate: { startDate },
            endDate: { endDate }, client
        } = this.props;


        return (
            <div style={{ paddingBottom: "100px" }}>
                {submitLocation === null ?
                    <DateRangeModalWithClient open={submitLocation === null} />
                    :
                    <div>
                        <Grid container justify="flex-end" >
                            <Fab color="secondary" size="small"
                                onClick={(e) => { client.writeData({ data: { submitLocation: null } }) }}
                            >
                                <Edit />
                            </Fab>
                        </Grid>
                        <SearchCriteriaWithClient startDate={startDate} endDate={endDate} submitLocation={submitLocation} />
                        <PartialCountsWithClient />
                    </div>
                }
            </div>
        )
    }
}

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: "20px",
        backgroundColor: "#fff",
        position: 'relative',
        overflow: 'auto',
        maxHeight: 500,
    },
    listSection: {
        backgroundColor: 'inherit',
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    },
    paper: {
        position: 'absolute',
        width: theme.spacing.unit * 50,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 4,
        outline: 'none',
        textAlign: "center"
    },
    rightIcon: {
        marginLeft: theme.spacing.unit,
    },
})

const ComposedComponent = compose(
    graphql(gql`{ submitLocation @client}`, { name: 'submitLocation' }),
    graphql(gql`{ startDate @client }`, { name: 'startDate' }),
    graphql(gql`{ endDate @client }`, { name: 'endDate' }),
    graphql(gql`{ fetchCounts @client }`, { name: 'fetchFlag' }),
    // graphql(CHECK_IC_STATUS, { name: 'checkIcStatus' }),
    graphql(ADD_CHECKBOX, { name: 'addCheckbox' }),
    graphql(INCLUDE_ALL_COUNTS, { name: 'includeAllCounts' })
)(SaveCount)

export default withStyles(styles)(ComposedComponent)
