import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'

/* PRODUCTION */
const config = {
    apiKey: "AIzaSyBwfV1EE1LeqKoIAJZqJa3lI3prm_y6aTs",
    authDomain: "oak-and-fort-inventory-fedc1.firebaseapp.com",
    databaseURL: "https://oak-and-fort-inventory-fedc1.firebaseio.com",
    projectId: "oak-and-fort-inventory-fedc1",
    storageBucket: "oak-and-fort-inventory-fedc1.appspot.com",
    messagingSenderId: "726669853096",
    appId: "1:726669853096:web:ddb48294d3094a3b"
}



/* DEVELOPMENT */
/*
 const config = {
     apiKey: "AIzaSyCwJs1K_RvVz7h_OOQ4x61HtcJEOXf6Ljo",
     authDomain: "inventory-development-aceac.firebaseapp.com",
     databaseURL: "https://inventory-development-aceac.firebaseio.com",
     projectId: "inventory-development-aceac",
     storageBucket: "inventory-development-aceac.appspot.com",
     messagingSenderId: "544862130381",
     appId: "1:544862130381:web:997abb924a1166027d9984",
     measurementId: "G-G5VXV8RB8R"
   };
*/

firebase.initializeApp(config);

export default firebase;