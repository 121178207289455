import React, { Component } from 'react'
import { withApollo, compose, graphql } from 'react-apollo'
import gql from 'graphql-tag'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal'
import Typography from '@material-ui/core/Typography'
import Timer from '@material-ui/icons/Timer'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import PlayCircleFilled from '@material-ui/icons/PlayCircleFilled'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Stop from '@material-ui/icons/Stop'
// import TRANSACTION_QUERY from '../queries/TRANSACTION_QUERY'
import TransactionItemsTable from './TransactionItemsTable'
import AddBySku from './AddBySku'
import LAST_SCANNED from '../queries/LAST_SCANNED'
import ms from 'pretty-ms'
import OrderDetails from './OrderDetails'
import QuantityDetails from './QuantityDetails'
import SCANNED_ITEMS from '../queries/SCANNED_ITEMS'
import Done from '@material-ui/icons/Done'
// import Fab from '@material-ui/core/Fab'
// import ADD_CONFIRM_DIFF from '../mutations/ADD_CONFIRM_DIFF'
import Cancel from '@material-ui/icons/Cancel'
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';

const TransactionItemsTableWithClient = withApollo(TransactionItemsTable);
const AddBySkuWithClient = withApollo(AddBySku);

const RECEIVE_STEP = gql` query {
  receiveStep @client
} `

export class ScanItems extends Component {

  constructor(props) {
    super(props)

    this.state = {
      start: false,
      startTime: 0,
      time: 0,
      interval: null,
      offset: 0,
      error: '',
      open: false,
      openTableModal: false,
      errorTableModal: '',
    }
    
    this.startTimer = this.startTimer.bind(this)
    this.resetTimer = this.resetTimer.bind(this)
    this.stopTimer = this.stopTimer.bind(this);
    this.nextStep = this.nextStep.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  componentWillMount() {
    this.props.client.writeData({
      data: { lastScanned: '' }
    })
  }

  componentDidUpdate() {
    // console.log('update')
  }

  startTimer() {
    this.setState({
      time: this.state.time,
      startTime: Date.now() - this.state.time
    })
    this.timer = setInterval(() => this.setState({
      time: Date.now() - this.state.startTime
    }), 1000)
  }

  stopTimer() {
    clearInterval(this.timer)
  }

  resetTimer() {
    this.setState({ time: 0 })
  }

  componentWillUnmount() {
    this.stopTimer()
    const { client } = this.props;

    client.writeData({
      data: { receiveDuration: this.state.time }
    })
  }

  nextStep() {
    const { client } = this.props;
    let verified = true;

    const { scannedItems } = client.readQuery({
      query: SCANNED_ITEMS
    })

    let parsedScannedItems = JSON.parse(scannedItems);
    if (parsedScannedItems) {
      for (const key in parsedScannedItems) {
          if ( (parseInt(parsedScannedItems[key].actualqty) !== parseInt(parsedScannedItems[key].expectedqty) ) && !parsedScannedItems[key].verified) {
            verified = false
          }
      }
    }

    if (verified) {
      client.query({
        query: RECEIVE_STEP
      }).then(({ data }) => {
        client.writeData({
          data: { receiveStep: data.receiveStep + 1 }
        })
      })
    }
    else {
      this.setState({
        errorTableModal: 'Please confirm the differences in the quantities for the items',
        openTableModal: true,
      })
    }
  }

  handleCancel() {
    this.stopTimer();

    this.setState({
      time: 0,
      start: false
    })

    this.props.client.writeData({
      data: {
        scannedItems: {},
        employeeName: '',
        lastScanned: '',
        receiveStep: 0,
        receiveDuration: '',
      }
    })
  }

  render() {
    const { classes, client, scan } = this.props;
    const lastScanned = scan.lastScanned;
    const { currentOrder } = client.readQuery({
      query: gql`{ currentOrder @client } `
    });

    var orderDetails = JSON.parse(currentOrder);

    return (
      <div className={classes.root}>

        <Grid container justify="center" direction="row" wrap="wrap" >
          <Grid style={{margin: "20px"}} item xs={12} >
            <OrderDetails orderDetails={orderDetails} />
          </Grid>
          <Grid style={{margin: "20px"}} item xs={12} >
            <QuantityDetails orderDetails={orderDetails} />
          </Grid>
          <Grid item xs={6} style={{ alignSelf: "center", padding: "10px", marginTop: "50px" }}  >
            <Grid container alignItems="center" alignContent="center" justify="flex-start">
              <Grid item style={{ margin: "5px 10px 5px 0" }}>
                {
                  this.state.start ?
                    <Button variant="contained"
                      size="small"
                      onClick={(e) => {
                        this.setState({ start: false })
                        this.stopTimer()
                      }}
                    >
                      Stop Scan
                      <Stop className={classes.rightIcon} />
                    </Button>
                    :
                    <Button color="secondary" variant="contained"
                      size="small"
                      onClick={(e) => {
                        this.setState({ start: true })
                        this.startTimer()
                      }}
                    >
                      Start Scan
                      <PlayCircleFilled className={classes.rightIcon} />
                    </Button>
                }
              </Grid>
              <Grid item style={{ margin: "5px 10px 5px 0" }} >
                <AddBySkuWithClient disabled={!this.state.start} orderDetails={orderDetails} />
              </Grid>
              <Grid item style={{ margin: "5px 10px 5px 0" }}>
                <Button variant="contained"
                  size="small"
                  className={classes.fabGreen}
                  onClick={(e) => this.nextStep()}
                >
                  Mark Done
                  <Done style={{ marginLeft: "8px" }} />
                </Button>
              </Grid>
              <Grid item style={{ margin: "5px 0 5px 0" }}>
                <Button variant="contained"
                  className={classes.fabRed}
                  size="small"
                  onClick={() => {
                    //  this.handleCancel(getTransactionToReceive)
                    this.stopTimer();

                    this.setState({
                      time: 0,
                      start: false
                    })
                    window.location.reload()
                  }
                  }
                >
                  Cancel
                  <Cancel style={{ marginLeft: "8px" }} />
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} style={{ marginTop: "50px", padding: "10px" }} >
            <p style={{ fontSize: "24px", textAlign: "right" }}>
              <strong>
                <Timer style={{ fontSize: "40px", verticalAlign: "middle" }} />
              </strong>
              <span style={{ color: "red" }} > {ms(Math.floor(this.state.time))} </span>
            </p>
          </Grid>
          <Grid style={{padding: "10px"}} item xs={12} >
            <Grid container justify="flex-start" >
              <Grid item >
                {
                  lastScanned !== "" ?
                    <p> <strong> Last Scanned Item</strong>: {lastScanned} </p>
                    :
                    null
                }
              </Grid>
            </Grid>
          </Grid>
          <ClickAwayListener onClickAway={(e) => {
          }} >
            <Grid style={{padding: "10px"}} item xs={12}>
              <TransactionItemsTableWithClient
                startScan={this.state.start}
                lastScanned={lastScanned}
                orderDetails={orderDetails}
                />
            </Grid>
          </ClickAwayListener>
        </Grid>
        <Modal
          open={this.state.openTableModal}
        >
          <div style={getModalStyle()} className={classes.paper}>
            <Typography variant="subtitle1" style={{ color: "red" }} id="simple-modal-description">
              {this.state.errorTableModal}
            </Typography>
            <Button style={{ marginTop: "20px" }} color="primary" variant="outlined" size="small"
              onClick={(e) => { this.setState({ openTableModal: false, errorTableModal: '' }) }}
            >
              OK
            </Button>
          </div>
        </Modal>
      </div>
    )
  }
}

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const styles = theme => ({
  root: {
    width: '100%',
    margin: "0 auto",
    paddingBottom: "100px"
    // marginTop: theme.spacing.unit * 3,
  },
  paper: {
    position: 'absolute',
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: 'none',
    textAlign: "center"
  },
  tranDetails: {
    flexBasis: "100%",
    textAlign: "left",
    margin: "0",
    fontSize: "12px"
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  fabGreen: {
    color: theme.palette.common.white,
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[600],
    }
  },
  fabRed: {
    color: theme.palette.common.white,
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[600],
    }
  }
})

ScanItems.propTypes = {
  classes: PropTypes.object.isRequired
}

export default compose(
  graphql(LAST_SCANNED, { name: 'scan' })
)(withStyles(styles)(ScanItems))
