import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withApollo, compose, graphql } from 'react-apollo';
import gql from 'graphql-tag'
import firebase from '../../firebaseConfig'
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button'
import List from '@material-ui/core/List'
import CircularProgress from '@material-ui/core/CircularProgress'
import TablePagination from '@material-ui/core/TablePagination';
import CountRecordTable from './CountRecordTable'
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IncludeAllCounts from './IncludeAllCounts'
import INCLUDE_ALL_COUNTS from '../mutations/INCLUDE_ALL_COUNTS'
// import CHECK_IC_STATUS from '../queries/CHECK_IC_STATUS'
import ListItem from '@material-ui/core/ListItem'
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Delete from '@material-ui/icons/Delete';
import FileCopy from '@material-ui/icons/FileCopy'
import Divider from '@material-ui/core/Divider';
import SubmitToNetsuiteModal from './SubmitToNetsuiteModal'

const SubmitToNetsuiteModalWithClient = withApollo(SubmitToNetsuiteModal);
const IncludeAllCountsWithClient = withApollo(IncludeAllCounts);

export class PartialCounts extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loader: false,
            countRecords: [],
            selectAll: false,
            page: 0,
            rowsPerPage: 10
        }

        this.includeInFic = this.includeInFic.bind(this);
        this.exportToCSV = this.exportToCSV.bind(this);
        this.exportAll = this.exportAll.bind(this);
    }

    handleRemove = (record) => {

        if (window.confirm('Are you sure you want remove this record ? Please take note that once removed, partial counts are not retrievable.')) {

            firebase.database().ref('counting').child(record.key).remove();
            window.alert("Successfully removed partial count!")
        }
    }

    componentDidMount() {
        let self = this;
        self._isMounted = true;

        this.setState({
            loader: true
        })

        const { submitLocation: { submitLocation },
            startDate: { startDate },
            endDate: { endDate },
            client
        } = this.props;

        let records = [];
        // let recordsWithIC = [];

        if (submitLocation) {

            firebase.database().ref('counting').orderByChild('location/name').equalTo(submitLocation)
                .on('value', snapshot => {

                    // console.log(err)
                    records = [];

                    self.setState({
                        loader: true
                    })

                    snapshot.forEach(count => {

                        let countRecord = {
                            key: count.key,
                            details: count.val(),
                            checked: false
                        }

                        if (self.dateInRange(startDate, endDate, count)) {

                            // countRecord.details.savedToNetsuite = false;
                            records.push(countRecord);
                        }
                    })


                    if (self._isMounted) {
                        self.setState({
                            countRecords: records,
                            loader: false
                        })
                    }

                })

        }


        /*
        firebase.database().ref('inventoryCount').orderByChild('location/name').equalTo(submitLocation)
            .on('value', function (snapshot) {
                records = [];
                recordsWithIC = [];

                self.setState({
                    loader: true
                })
                snapshot.forEach((count) => {
                    let countRecord = {
                        key: count.key,
                        details: count.val(),
                        checked: false
                    }

                    if (self.dateInRange(startDate, endDate, count)) {

                        // check if IC is not voided, if voided or IC doesnt exist, savedToNetsuite field should be false
                        if (count.val().inventoryCountId && count.val().inventoryCountId !== '') {
                            recordsWithIC.push(countRecord);
                        }
                        else {
                            countRecord.details.savedToNetsuite = false;
                            records.push(countRecord);
                            // addCheckbox({
                            //     variables: { key: count.key, checked: false }
                            // })
                        }
                    }
                })

                if (recordsWithIC.length === 0) {

                    if (self._isMounted) {
                        self.setState({
                            countRecords: records,
                            loader: false
                        })
                    }
                }
                else {
                    let processed = 0;

                    const token = sessionStorage.getItem('token');

                    for (let i = 0; i < recordsWithIC.length; i++) {

                        client.query({
                            query: CHECK_IC_STATUS,
                            variables: {
                                token: token,
                                tranid: recordsWithIC[i].details.inventoryCountId
                            },
                            fetchPolicy: 'network-only'
                        }).then(({ data: { findFicDetails: { err, memo, status } } }) => {

                            processed++;

                            if (err === null && memo.toLowerCase() !== "void") {

                                recordsWithIC[i].details.savedToNetsuite = true;

                                // firebase.database().ref('inventoryCount/' + count.key).update({
                                //     savedToNetsuite: true
                                // })
                            }
                            else {
                                recordsWithIC[i].details.savedToNetsuite = false;

                                // firebase.database().ref('inventoryCount/' + count.key).update({
                                //     inventoryCountId: '',
                                //     savedToNetsuite: false
                                // })
                            }

                            records.push(recordsWithIC[i]);
                            records.sort(function (a, b) {
                                return new Date(a.details.date).getTime() - new Date(b.details.date).getTime();
                            });

                            if (self._isMounted && processed === recordsWithIC.length) {
                                self.setState({
                                    countRecords: records,
                                    loader: false
                                })
                            }
                        })
                    }
                }

            })
        */

    }

    dateInRange(startDate, endDate, count) {
        let sd = new Date(startDate);
        let ed = new Date(endDate);
        let rd = new Date(count.val().date);
        sd.setHours(0, 0, 0);
        ed.setHours(0, 0, 0);
        rd.setHours(0, 0, 0);

        let startTime = sd.getTime();
        let endTime = ed.getTime();
        let recordDate = rd.getTime();

        if (startTime === endTime) {
            if (sd.getDate() === rd.getDate() && sd.getMonth() === rd.getMonth() && sd.getFullYear() === rd.getFullYear()) {
                return true
            }
        }
        else {
            if (recordDate >= startTime && recordDate <= endTime) {
                return true
            }
        }
        return false;
    }

    componentDidUpdate(prevProps, prevState) {
        const { includeAllFlag: { includeAllFic } } = this.props;

        if (includeAllFic !== prevProps.includeAllFlag.includeAllFic) {
            const prevState = this.state.countRecords;
            let newState = prevState;

            if (includeAllFic === true) {
                for (let i = 0; i < prevState.length; i++) {
                    newState[i].checked = true;
                }
                this.setState({
                    countRecords: newState
                })
            }
            else {
                for (let i = 0; i < prevState.length; i++) {
                    newState[i].checked = false;
                }
                this.setState({
                    countRecords: newState
                })
            }
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    includeInFic(index) {
        const prevState = this.state.countRecords;
        let newState = prevState;
        newState[index].checked = !newState[index].checked;
        this.setState({
            countRecords: newState
        })
    }

    exportToCSV(record) {

        let csv_file = "Item ID, Item Name,Upc Code, Quantity,Memo\n";
        
        for (let i = 0; i < record.details.countedItems.length; i++) {

            let item = record.details.countedItems[i];
            csv_file += item.internalid + "," + item.name + "," + item.upccode + "," + item.quantity + "," + record.details.memo + "\n";
        }

        let anchor = document.createElement("a");
        anchor.setAttribute('href', "data:text/csv;charset=utf-8," + encodeURIComponent(csv_file));
        anchor.setAttribute('download', "Item_Count_" + new Date().getTime() + ".csv");
        anchor.click();
        anchor.remove();
    }

    exportAll() {
        const { countRecords } = this.state;

        let csv_file = "Item ID, Item Name,Upc Code, Quantity,Memo\n";

        for(let i = 0; i < countRecords.length; i++) {

            let countedItems = countRecords[i].details.countedItems;

            for(let j = 0; j < countedItems.length; j++) {

                csv_file += countedItems[j].internalid + "," + countedItems[j].name + "," + countedItems[j].upccode + "," + countedItems[j].quantity + "," + countRecords[i].details.memo + "\n";
            }
            csv_file += "\n";
        }

        let anchor = document.createElement("a");
        anchor.setAttribute('href', "data:text/csv;charset=utf-8," + encodeURIComponent(csv_file));
        anchor.setAttribute('download', "Combined_Count_" + new Date().getTime() + ".csv");
        anchor.click();
        anchor.remove();
    }

    render() {
        const { classes } = this.props;
        const { page, rowsPerPage } = this.state;

        return (
            <div>
                {
                    this.state.loader ?
                        <div>
                            <CircularProgress style={{ marginTop: "30px" }} />
                            <p> Hold On...This might take some time </p>
                        </div>
                        :
                        this.state.countRecords.length === 0 ?
                            <p style={{ marginTop: "50px" }}>
                                No records found for the above criteria. Click on Edit button and modify search criteria to try again.
                        </p>
                            :
                            <div>
                                <IncludeAllCountsWithClient></IncludeAllCountsWithClient>
                                <Button 
                                    color="secondary"
                                    style={{ cursor: "pointer" }}
                                    onClick={(e) => this.exportAll()}
                                >
                                    <FileCopy />  Export All Records
                                </Button>
                                <List className={classes.root} >
                                    {
                                        this.state.countRecords.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((record, index) => (
                                            <ListItem key={record.key} className={classes.listItem}>

                                                <div style={{
                                                    marginRight: "20px", display: "flex", flexDirection: "column",
                                                    justifyContent: "center"
                                                }} >
                                                    <div>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox checked={record.checked}
                                                                    onChange={(e) => {
                                                                        this.includeInFic(index + page * rowsPerPage);
                                                                        // call mutation
                                                                        // toggleCheckbox({ variables: { key: record.key } })
                                                                    }}
                                                                    value=""
                                                                    color="secondary"
                                                                />
                                                            }
                                                            label="Include in FIC"
                                                            color="secondary"
                                                        />
                                                    </div>
                                                    <div>
                                                        {
                                                            record.details.savedToNetsuite ?
                                                                <p style={{ color: "green", fontSize: "14px", paddingBottom: "15px" }}> *Already Exported </p> : null
                                                        }
                                                    </div>
                                                </div>

                                                <ExpansionPanel style={{ width: "90%" }} >
                                                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                                        <div>
                                                            <Typography variant="subtitle1">
                                                                Employee: <strong>{record.details.employeeName}</strong>
                                                            </Typography>
                                                            <Typography variant="subtitle1">
                                                                Memo: <strong>{record.details.memo}</strong>
                                                            </Typography>
                                                            <Typography variant="subtitle1">
                                                                Date: <strong>{new Date(record.details.date).toDateString()}</strong>
                                                            </Typography>
                                                            <br />
                                                            <Typography>
                                                                Total Entered: {record.details.scannedQty},
                                                            Total Manual Count: {record.details.manualCount},
                                                            Total Variance: {record.details.variance}
                                                            </Typography>
                                                        </div>

                                                    </ExpansionPanelSummary>

                                                    <ExpansionPanelDetails>
                                                        <CountRecordTable details={record.details} />
                                                    </ExpansionPanelDetails>

                                                    <Typography color="secondary" style={{ textAlign: 'left', float: 'left', padding: '25px' }}>
                                                        <Button
                                                            color="secondary"
                                                            style={{ cursor: "pointer" }}
                                                            onClick={(e) => this.exportToCSV(record)}
                                                        >
                                                            <FileCopy />  Export to CSV
                                                    </Button>
                                                    </Typography>

                                                    <Typography color="error" style={{ textAlign: 'left', padding: '25px' }}>
                                                        <Button
                                                            onClick={(e) => this.handleRemove(record)}
                                                            style={{ cursor: "pointer", color: 'red' }}
                                                        >
                                                            <Delete />  Remove This Record
                                                    </Button>
                                                    </Typography>
                                                </ExpansionPanel>

                                                {/* {
                                                this.state.countRecords.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).length == (index + 1) ?
                                                null :
                                                <Divider style={{ width: "100%", marginTop: '40px' }} />
                                            } */}

                                            </ListItem>
                                        ))
                                    }
                                </List>
                                <TablePagination
                                    rowsPerPageOptions={[10, 15, 20]}
                                    component="div"
                                    count={this.state.countRecords.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    backIconButtonProps={{
                                        'aria-label': 'Previous Page',
                                    }}
                                    nextIconButtonProps={{
                                        'aria-label': 'Next Page',
                                    }}
                                    onChangePage={(e, newPage) => {
                                        this.setState({
                                            page: newPage
                                        })
                                    }}
                                    onChangeRowsPerPage={(e) => { this.setState({ rowsPerPage: e.target.value }) }}
                                />
                                <SubmitToNetsuiteModalWithClient records={this.state.countRecords} />
                            </div>
                }
            </div>
        )
    }
}

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: "20px",
        // backgroundColor: "#fff",
        position: 'relative',
        overflow: 'auto',
        // maxHeight: 500,
    },
    listSection: {
        backgroundColor: 'inherit',
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    },
    paper: {
        position: 'absolute',
        width: theme.spacing.unit * 50,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 4,
        outline: 'none',
        textAlign: "center",
    },
    rightIcon: {
        marginLeft: theme.spacing.unit,
    },
    listItem: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        backgroundColor: "#fff",
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            padding: "20px 0",
            margin: "5px 0",
            boxShadow: theme.shadows[3],
        }
    }
})

const ComposedComponent = compose(
    graphql(gql`{ submitLocation @client}`, { name: 'submitLocation' }),
    graphql(gql`{ startDate @client }`, { name: 'startDate' }),
    graphql(gql`{ endDate @client }`, { name: 'endDate' }),
    graphql(gql`{ fetchCounts @client }`, { name: 'fetchFlag' }),
    graphql(gql`{ includeAllFic @client }`, { name: 'includeAllFlag' }),
    graphql(INCLUDE_ALL_COUNTS, { name: 'includeAllCounts' })
)(PartialCounts)

export default withStyles(styles)(ComposedComponent);
