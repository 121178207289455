import gql from 'graphql-tag';

const NEW_ITEM_SKU = gql`
    mutation newItemSku($sku: String!, $locationid: String) {
        newItemSku(sku: $sku, locationid: $locationid) @client {
            sku
        }
    }
`

export default NEW_ITEM_SKU