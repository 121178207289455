import React, { Component } from 'react'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Modal from '@material-ui/core/Modal'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
// import Checkbox from '@material-ui/core/Checkbox'
import Formcontrol from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { Divider } from '@material-ui/core';
import { compose, graphql } from 'react-apollo'
import SUBMIT_FIC from '../mutations/SUBMIT_FIC'
import firebase from '../../firebaseConfig'
import CircularProgress from '@material-ui/core/CircularProgress'
// import LOCATION_QUERY from '../../Receiving/queries/LOCATION_QUERY'
import gql from 'graphql-tag'
import locations from '../../utils/Locations'

export class SubmitToNetsuiteModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            open: false,
            totalQty: 0,
            totalManualQty: 0,
            isCalculatedCount: false,
            calculatedCountId: '',
            empName: '',
            finalMemo: '',
            finalEmail: '',
            error: null,
            loader: false,
            submitError: null,
            option: 'worksheet'
        }

        this.openModal = this.openModal.bind(this);
        this.submitToNetsuite = this.submitToNetsuite.bind(this);
        this.prepareInventoryWorksheet = this.prepareInventoryWorksheet.bind(this);
        this.prepareInventoryAdjustment = this.prepareInventoryAdjustment.bind(this);
    }

    openModal() {
        const { records } = this.props;

        let filteredRecords = records.filter((item) => {
            return item.checked === true
        })

        if (filteredRecords.length > 0) {

            let totalQty = 0;
            let totalManualQty = 0;

            filteredRecords.forEach(record => {
                totalQty += parseInt(record.details.scannedQty);
                totalManualQty += parseInt(record.details.manualCount)
            });

            this.setState({
                open: true,
                totalQty: totalQty,
                totalManualQty: totalManualQty
            })
        }
        else {
            this.setState({
                error: 'Please include at least one of the records'
            })
        }
    }

    submitToNetsuite() {

        let finalCount = {};
        // let totalCountDuration = 0;
        const { submitFic, records, client, submitLocation: { submitLocation } } = this.props;
        let items = [];
        this.setState({ loader: true, submitError: null })

        let filteredRecords = records.filter(item => (item.checked === true))

        // console.log(filteredRecords)

        // combine qty for all different items
        filteredRecords.forEach(record => {
            record.details.countedItems.forEach(recordItem => {
                let index = items.findIndex(item => item.internalid === recordItem.internalid);
                if (index !== -1) {
                    items[index].quantity += parseInt(recordItem.quantity);
                    items[index].value = parseFloat(items[index].quantity * Number(items[index].location_average_cost)).toFixed(4);
                    items[index].adjustment = items[index].quantity - items[index].on_hand;

                }
                else {
                    items.push({
                        internalid: recordItem.internalid,
                        quantity: parseInt(recordItem.quantity),
                        on_hand: parseInt(recordItem.on_hand),
                        sku: recordItem.name,
                        upc: recordItem.upccode,
                        price: recordItem.baseprice,
                        location_average_cost: recordItem.location_average_cost,
                        value: parseFloat(parseInt(recordItem.quantity)*Number(recordItem.location_average_cost)).toFixed(4),
                        adjustment: parseInt(recordItem.quantity) - parseInt(recordItem.on_hand)
                    })
                }
            })

            // combine total count duration
            // totalCountDuration += parseInt(record.details.timeTaken);
        })
        
        // totalCountDuration = totalCountDuration / (1000*60);

        // console.log(items)

        let location = locations.filter(loc => loc.label === submitLocation);
        finalCount = {
            items: items,
            date: new Date(),
            tranid: this.state.calculatedCountId,
            countername: this.state.empName,
            memo: this.state.finalMemo,
            isCalculated: this.state.isCalculatedCount + "",
            emailto: this.state.finalEmail,
            location: location[0].value
            // countduration: totalCountDuration+""
        }

        if(this.state.option === "worksheet") {

            this.prepareInventoryWorksheet(finalCount, location, filteredRecords);
        }
        else if(this.state.option === "adjustment") {

            this.prepareInventoryAdjustment(finalCount, location, filteredRecords);
        }


        /*
        submitFic({ variables: { finalCount: finalCount, token: token } }).then(({ data: { submitFic } }) => {

            if (submitFic.err === null) {
                let count = 0;

                // Save the IC number in the partial count in firebase and set savedToNetsuite field to true
                filteredRecords.forEach(record => {
                    firebase.database().ref('inventoryCount/' + record.key).update({
                        savedToNetsuite: true,
                        inventoryCountId: this.state.isCalculatedCount ? this.state.calculatedCountId : submitFic.tranid
                    }).then(() => {
                        count++;
                        if (count === filteredRecords.length) {
                            this.setState({
                                loader: false
                            })
                            client.writeData({
                                data:
                                {
                                    countStep: 3,
                                    inventoryCountId: this.state.isCalculatedCount ? this.state.calculatedCountId : submitFic.tranid
                                }
                            });
                        }
                    })
                })
            }
            else {
                this.setState({
                    submitError: submitFic.err,
                    loader: false
                })
            }
        })
        .catch(err => {
            console.log(err)
            alert('Something went wrong. Please contact IT team.')
            this.setState({
                loader: false
            })
        })
        */
    }

    prepareInventoryWorksheet(finalCount, location, filteredRecords) {

        const { client } = this.props;
        let worksheets = [];

        let worksheet = "Date,Adjustment Account,Subsidiary,Department,Location,Memo,Transaction Order,Item Internal Id,Item,New Qty,New Value,External Id,Expected Quantity,Variance\n";

        let currdate = new Date().toLocaleDateString();
        let num = 0;

        for(let i = 0; i < finalCount.items.length; i++) {

            var netsuite_variance = "0";
             
            if(finalCount.items[i].on_hand === 0) {
                netsuite_variance = "100%";
            }
            else {
                netsuite_variance = (Math.abs(finalCount.items[i].on_hand - finalCount.items[i].quantity)/finalCount.items[i].on_hand) * 100;
                netsuite_variance = parseFloat(netsuite_variance).toFixed(2) + "%";
            }

            worksheet += currdate + ",6334," + location[0].subsidiary + "," + location[0].departmentid + "," + location[0].value + "," + finalCount.memo + ",First transaction in day," + finalCount.items[i].internalid + "," + finalCount.items[i].sku + "," + + finalCount.items[i].quantity + "," + finalCount.items[i].value + "," + finalCount.memo + "," + finalCount.items[i].on_hand + "," + netsuite_variance + "\n";  

            num++;

            if(num >= 10000) {
                worksheets.push(worksheet);

                worksheet = "Date,Adjustment Account,Subsidiary,Department,Location,Memo,Transaction Order,Item Internal Id,Item,New Qty,New Value,External Id,Expected Quantity,Variance\n";

                num = 0;
            }
        }

        if(num < 10000) {
            worksheets.push(worksheet);
        }

        let count = 0;

        filteredRecords.forEach(record => {

            firebase.database().ref('counting/' + record.key).update({
                savedToNetsuite: true,
            }).then(() => {

                count++;
                if (count === filteredRecords.length) {

                    this.setState({
                        loader: false
                    })
                    client.writeData({
                        data:
                        {
                            countStep: 3,
                            documents: JSON.stringify(worksheets),
                            countOption: 'worksheet'
                        }
                    });
                }
            })
            .catch(err => {
                console.log(err);
            })
        })
    }

    prepareInventoryAdjustment(finalCount, location, filteredRecords) {
        const { client } = this.props;

        let documents = [];
        let adjustment = "Date,Adjustment Account,Subsidiary,Department,Location,Memo,Transaction Order,Item Internal Id,Item,Adjust By Qty,External Id,Est. Unit Cost\n";

        let currdate = new Date().toLocaleDateString();
        let num = 0;

        for(let i = 0; i < finalCount.items.length; i++) {
            
            adjustment += currdate + ",6334," + location[0].subsidiary + "," + location[0].departmentid + "," + location[0].value + "," + finalCount.memo + ",First transaction in day," + finalCount.items[i].internalid + "," + finalCount.items[i].sku + "," + finalCount.items[i].adjustment + "," + finalCount.memo + "," + finalCount.items[i].location_average_cost + "\n";  

            num++;

            if(num >= 10000) {
                documents.push(adjustment);

                adjustment = "Date,Adjustment Account,Subsidiary,Department,Location,Memo,Transaction Order,Item Internal Id,Item,Adjust By Qty,External Id,Est. Unit Cost\n";

                num = 0;
            }
        }

        if(num < 10000) {
            documents.push(adjustment);
        }

        let count = 0;

        filteredRecords.forEach(record => {

            firebase.database().ref('counting/' + record.key).update({
                savedToNetsuite: true,
            }).then(() => {

                count++;
                if (count === filteredRecords.length) {

                    this.setState({
                        loader: false
                    })
                    client.writeData({
                        data:
                        {
                            countStep: 3,
                            documents: JSON.stringify(documents),
                            countOption: 'adjustment'
                        }
                    });
                }
            })
        })

    }

    render() {
        const { client, classes, records } = this.props;

        return (
            <Grid container justify="center" style={{ marginTop: "40px" }} >
                <Grid item >
                    <Button variant="contained" color="primary" size="large"
                        style={{ marginRight: "20px" }}
                        onClick={(e) => { this.openModal() }}
                    >
                        Verify/Submit
                    </Button>
                </Grid>
                <Grid item >
                    <Button size="large" variant="outlined"
                        style={{ marginLeft: "20px" }}
                        onClick={(e) => {
                            client.writeData({
                                data: {
                                    countStep: 0,
                                    submitLocation: null,
                                    fetchCounts: false
                                }
                            })
                        }}
                    >
                        Cancel
                    </Button>
                </Grid>
                <Modal open={this.state.open} >
                    <div style={getModalStyle()} className={classes.paper}>
                        <Typography variant="h6" id="modal-title">
                            {
                                this.state.option === "worksheet" ?
                                <span> You are about to combine all of the selected counts and export to Inventory Worksheets. </span>
                                :
                                <span> You are about to combine all of the selected counts and export to Inventory Adjustments. </span>
                            }
                            
                        </Typography>
                        <Divider style={{ width: "100%" }} />
                        <Grid container style={{ marginTop: "30px" }} >
                            <Grid item xs={12} >
                                Total Quantity to be submitted: {this.state.totalQty}
                            </Grid>
                            <Grid item xs={12} >
                                Total Quantity that was counted manually: {this.state.totalManualQty}
                            </Grid>
                            <Grid item xs={12} >
                                Total Variance: {this.state.totalQty - this.state.totalManualQty}
                            </Grid>
                        </Grid>
                        <form onSubmit={(e) => { e.preventDefault(); this.submitToNetsuite() }} style={{ marginTop: "30px" }} >
                            <Formcontrol fullWidth>
                                <FormLabel></FormLabel>
                                <RadioGroup
                                    value={this.state.option} row
                                    onChange={(e) => { this.setState({ option: e.target.value, value: '' }) }}
                                >
                                    <FormControlLabel
                                    value="worksheet"
                                    control={<Radio color="secondary" />}
                                    label="Worksheet"
                                    labelPlacement="end"
                                    />
                                    <FormControlLabel
                                    value="adjustment"
                                    control={<Radio color="secondary" />}
                                    label="Adjustment"
                                    labelPlacement="end"
                                    />
                                </RadioGroup>
                            </Formcontrol>
                            {/* <div style={{ marginTop: "20px" }} >
                                <TextField
                                    inputProps={{ required: true }}
                                    fullWidth
                                    label="Name"
                                    placeholder="Enter your Name here"
                                    value={this.state.empName}
                                    onChange={(e) => { this.setState({ empName: e.target.value }) }}
                                />
                            </div> */}
                            <div style={{ marginTop: "10px" }} >
                                <TextField
                                    inputProps={{ required: true }}
                                    fullWidth
                                    label="Memo"
                                    placeholder="Enter a Memo"
                                    value={this.state.finalMemo}
                                    onChange={(e) => this.setState({ finalMemo: e.target.value })}
                                />
                            </div>
                            {/* <div style={{ marginTop: "10px" }} >
                                <TextField
                                    inputProps={{ required: true, type: 'email' }}
                                    fullWidth
                                    label="Email"
                                    placeholder="Enter an email to send verification"
                                    value={this.state.finalEmail}
                                    onChange={(e) => this.setState({ finalEmail: e.target.value })}
                                />
                            </div> */}
                            
                            {/* <div style={{ marginTop: "10px" }} >
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={this.state.isCalculatedCount}
                                            onChange={(e) => {
                                                this.setState({ isCalculatedCount: e.target.checked })
                                            }}
                                            color="secondary"
                                        />
                                    }
                                    label="Calculated Count?"
                                />
                            </div> */}
                            
                            {/* <div style={{ marginTop: "10px" }} >
                                <TextField
                                    inputProps={{ required: this.state.isCalculatedCount }}
                                    disabled={!this.state.isCalculatedCount}
                                    fullWidth
                                    value={this.state.calculatedCountId}
                                    onChange={(e) => this.setState({ calculatedCountId: e.target.value })}
                                    placeholder="If this is a calculated count, enter the existing count transaction id"
                                />
                            </div> */}

                            <div style={{ marginTop: "30px" }} >
                                {
                                    this.state.loader ?
                                        <div style={{ textAlign: "center" }}>
                                            <CircularProgress />
                                        </div>
                                        :
                                        <div>
                                            <Button variant="contained" color="primary" type="submit"
                                            >
                                                Submit
                                            </Button>
                                            <Button variant="outlined" color="primary" style={{ marginLeft: "20px" }}
                                                onClick={(e) => { this.setState({ open: false }) }}
                                            >
                                                Cancel
                                            </Button>
                                        </div>
                                }

                            </div>
                            <div style={{ marginTop: "30px" }} >
                                {
                                    this.state.submitError === null ?
                                        null :
                                        <div>
                                            <p style={{ color: "red" }}> {this.state.submitError} </p>
                                        </div>
                                }
                            </div>
                        </form>
                    </div>
                </Modal>
                <Grid item xs={12} >
                    {
                        this.state.error === null ?
                            null :
                            <Modal open={this.state.error !== null} >
                                <div style={getModalStyle()} className={classes.paper} >
                                    <Typography style={{ color: "red" }} >
                                        {this.state.error}
                                    </Typography>
                                    <Button variant="contained"
                                        style={{ marginTop: "20px" }}
                                        onClick={(e) => { this.setState({ error: null }) }}
                                    >
                                        OK
                                    </Button>
                                </div>
                            </Modal>
                    }
                </Grid>
            </Grid>
        )
    }
}

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const styles = theme => ({
    paper: {
        position: 'absolute',
        width: theme.spacing.unit * 60,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 4,
        outline: 'none',
        textAlign: "left",
        [theme.breakpoints.down('xs')]: {
            width: theme.spacing.unit * 40,
            padding: theme.spacing.unit * 3
        }
    },
})

const ComposedComponent = compose(
    graphql(SUBMIT_FIC, { name: 'submitFic' }),
    graphql(gql`{ submitLocation @client}`, { name: 'submitLocation' }),
)(SubmitToNetsuiteModal)


export default withStyles(styles)(ComposedComponent)
