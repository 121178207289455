import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid'
import SCANNED_ITEMS from '../queries/SCANNED_ITEMS'
import { compose, graphql } from 'react-apollo'

export class QuantityDetails extends Component {
    render() {
        const { orderDetails, scannedItemsQuery: { scannedItems } } = this.props;

        let parsedItems = JSON.parse(scannedItems);
        let totalActual = 0;
        for (const key in parsedItems) {
            totalActual += parseInt(parsedItems[key].actualqty)
        }

        return (
            <Grid container spacing={16} style={{ padding: "10px 20px", backgroundColor: "#fff" }} >
                <Grid item xs={4} >
                    <Grid container justify="flex-start" >
                        <span> <strong>Total Expected: </strong> {orderDetails.totalquantityexpected} </span>
                    </Grid>
                </Grid>
                <Grid item xs={4} >
                    <Grid container justify="center" >
                        <span> <strong>Total Actual: </strong> {totalActual} </span>
                    </Grid>
                </Grid>
                <Grid item xs={4} >
                    <Grid container justify="flex-end" >
                        <span><strong>Total Difference: </strong> {totalActual - orderDetails.totalquantityexpected} </span>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

const styles = theme => ({
    tranDetails: {
        flexBasis: "100%",
        textAlign: "left",
        margin: "0",
        fontSize: "12px"
    }
})

QuantityDetails.propTypes = {
    classes: PropTypes.object.isRequired
}

const composedComponent = compose(
    graphql(SCANNED_ITEMS, { name: 'scannedItemsQuery' })
)(QuantityDetails)

export default (withStyles(styles)(composedComponent))



