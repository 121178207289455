import gql from 'graphql-tag';

const SUBMIT_ITEMS = gql`
    mutation(  $tranid: String!,
        $memo: String!, $items: [stcItems], $token: String!, $countDuration: String!, $irmemo: String! ) {
                    
    postTransactionToReceive( tranid: $tranid, memo: $memo, items: $items, token: $token, countDuration: $countDuration, irmemo: $irmemo) {
                        err
                        msg
                    }
                }

`

export default SUBMIT_ITEMS