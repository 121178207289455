import React, { Component } from 'react'
import { compose, graphql } from 'react-apollo'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Home from '@material-ui/icons/Home'
import Info from '@material-ui/icons/Info'
import gql from 'graphql-tag'
// import firebase from '../../firebaseConfig'

export class ShowResponse extends Component {

  getDate() {

    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1; //January is 0!
    var yyyy = today.getFullYear();

    if (dd < 10) { dd = '0' + dd; }
    if (mm < 10) { mm = '0' + mm; }

    today = dd + '-' + mm + '-' + yyyy;
    return today
  }

  render() {
    const { receiveResponse: { receiveResponse }, client, history } = this.props;

    let msg = '';
    // if (receiveResponse.err === null) {
    //   if (receiveResponse.msg.toLowerCase().indexOf('partially') !== -1) {
    //     msg = 'The order has been received partially. Please click below to download the report.'
    //   }
    //   else {
    //     msg = 'The order has been received. Please click below to download the report.'
    //   }
    // }
    // else {
    //   msg = 'Due to the discrepancies in the received items, the order has not been received. The Inventory team has been notified and they will receive the order on your behalf. Please click below to download the report.'
    // }

    if(receiveResponse.err === null) {
      msg = receiveResponse.msg;
    }

    const { transactionNo } = client.readQuery({
      query: gql`{ transactionNo @client } `
    });

    return (
      <div style={{ textAlign: "center", maxWidth: "800px", margin: "0 auto" }}>
        {
          receiveResponse.err === null ?
            <div>
              <div style={{ fontSize: "64px" }} >
                <i className="fas fa-check-circle" style={{ color: "green" }} ></i>
              </div>
              <Typography variant="h6" style={{ textAlign: "center", marginTop: "20px" }} >
                {msg}
              </Typography>
            </div>
            :
            <div>
              <div style={{ fontSize: "64px" }} >
                <i className="fas fa-times-circle" style={{ color: "red" }} ></i>
              </div>
              <Typography variant="h6" style={{ textAlign: "center", marginTop: "20px" }} >
                {msg}
              </Typography>
            </div>
        }
        <div style={{marginTop: "40px"}}>
          <Button size="large" color="primary" variant="contained" >
            <a
              style={{ color: "inherit" }}
              href={"data:text/csv;charset=utf-8," + encodeURIComponent(receiveResponse.varianceReport)}
              download={transactionNo + "_Report_" + this.getDate() + ".csv"} >
              Download Report
            </a>
            <span style={{ marginLeft: "10px" }} className="fas fa-download"></span>
          </Button>
          <Button color="primary" style={{ marginLeft: "20px"}} size="large" variant="outlined"
            onClick={(e) => {
              const { currentOrder } = client.readQuery({
                query: gql`{ currentOrder @client } `
              });
              let orderDetails   = JSON.parse(currentOrder);
              let location = orderDetails.receivinglocation.locationname;
              client.resetStore();
              history.push('/orderstatus?store='+location);
            }} >
            Check Order Status
            <Info style={{ marginLeft: "10px" }} />
          </Button>
          <Button color="primary" style={{ marginLeft: "20px"}} size="large" variant="outlined"
            onClick={(e) => {
              
              client.resetStore();
              window.location.reload()
            }} >
            Home
            <Home style={{ marginLeft: "10px" }} />
          </Button>
        </div>
      </div>
    )
  }
}

const ComposedComponent = compose(
  graphql(gql`{ receiveResponse @client {
    err
    msg
    varianceReport
  } }`, { name: 'receiveResponse' })
)(ShowResponse)

export default ComposedComponent
