import gql from 'graphql-tag'
const GET_CONFIRM_DIFF = gql`{ confirmDiff @client {
    internalid
    notes
    checked
  } 
}`

const addConfirmDiff = (_, { internalid }, { cache }) => {

    const previousState = cache.readQuery({
        query: GET_CONFIRM_DIFF
    })

    let exists = false;

    previousState.confirmDiff.forEach(element => {
        if(element.internalid === internalid) {
            exists = true;
        }
    });

    if(!exists) {
        const newItem = {
            internalid: internalid,
            checked: false,
            notes:'',
            __typename: "stcItems"
        }
    
        cache.writeQuery({
            query: GET_CONFIRM_DIFF,
            data: {
                ...previousState,
                confirmDiff:[
                    ...previousState.confirmDiff,
                    newItem,

                ]
            }
        })
    }

    return null
  }

  export default addConfirmDiff