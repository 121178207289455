import gql from 'graphql-tag';

const SET_QUANTITY_UPC = gql`
    mutation setQuantityUpc($upccode: String!, $quantity: Int!) {
        setQuantityUpc(upccode: $upccode, quantity: $quantity) @client {
            upccode
        }
    }
`

export default SET_QUANTITY_UPC