import gql from 'graphql-tag';

const TOGGLE_CONFIRM_DIFF = gql`
    mutation toggleConfirmDiff($upccode: String!, $checked: Bool!) {
        toggleConfirmDiff(upccode: $upccode, checked: $checked) @client {
            checked
        }
    }
`

export default TOGGLE_CONFIRM_DIFF