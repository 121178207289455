import ApolloClient from 'apollo-boost'
import resolvers from './resolvers'

const defaults = {
    loggedIn: false,
    receiveStep: 0,
    countStep: 0,
    idToken: '',
    transactionNo: '',
    countedItems: [],
    manualCount: 0,
    submitLocation: null,
    fetchCounts: false,
    submitCheckboxes: [],
    inventoryCountId: '',
    documents: '',
    countOption: ''
}

// Initializing Apollo Client
const client = new ApolloClient({
    uri: 'https://b4zb34fg2k.execute-api.us-west-2.amazonaws.com/dev/internalrevisednofirebase',
    // 'https://vbk7tsgpwb.execute-api.us-west-2.amazonaws.com/dev/internalrevised', 
    
    clientState: {
      defaults, resolvers
    }
});

export default client
