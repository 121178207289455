import SCANNED_ITEMS from '../queries/SCANNED_ITEMS'

const setQuantityUpc = (_, { upccode, quantity }, { cache }) => {

    const { scannedItems } = cache.readQuery({
        query: SCANNED_ITEMS
    })

    let parsedItems = JSON.parse(scannedItems);
    parsedItems[upccode].actualqty = quantity;

    cache.writeData({
        data: {
            scannedItems: JSON.stringify(parsedItems)
        }
    })

    return null
  
}

  export default setQuantityUpc