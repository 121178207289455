// import { Mutation } from 'react-apollo'
import addItemUpc from './Receiving/resolvers/addItemUpc'
import addItemSku from './Receiving/resolvers/addItemSku'
import setQuantityUpc from './Receiving/resolvers/setQuantityUpc';
import toggleConfirmDiff from  './Receiving/resolvers/toggleConfirmDiff'
import writeLineNotes from './Receiving/resolvers/writeLineNotes'
import addConfirmDiff from './Receiving/resolvers/addConfirmDiff'
import toggleAllConfirmDiff from './Receiving/resolvers/toggleAllConfirmDiff'

import newItemUpc from './Counting/resolvers/newItemUpc'
import newItemSku from './Counting/resolvers/newItemSku'
import incrementCountUpc from './Counting/resolvers/incrementCountUpc'
import incrementCountSku from './Counting/resolvers/incrementCountSku'
import removeItem from './Counting/resolvers/removeItem'
import setCountUpc from './Counting/resolvers/setCountUpc'
import addCheckbox from './Counting/resolvers/addCheckbox'
import includeAllCounts from './Counting/resolvers/includeAllCounts'
import toggleCheckbox from './Counting/resolvers/toggleCheckbox'

const resolvers = {
    Mutation: {
      addItemUpc: addItemUpc,
      addItemSku: addItemSku,
      setQuantityUpc: setQuantityUpc,
      toggleConfirmDiff: toggleConfirmDiff,
      writeLineNotes: writeLineNotes,
      addConfirmDiff: addConfirmDiff,
      toggleAllConfirmDiff: toggleAllConfirmDiff,

      newItemUpc: newItemUpc,
      newItemSku: newItemSku,
      incrementCountUpc: incrementCountUpc,
      incrementCountSku: incrementCountSku,
      removeItem: removeItem,
      setCountUpc: setCountUpc,
      addCheckbox: addCheckbox,
      includeAllCounts: includeAllCounts,
      toggleCheckbox: toggleCheckbox
    }
}

export default resolvers