import React, { Component } from 'react'
import { withApollo, compose, graphql } from 'react-apollo'
import gql from 'graphql-tag'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal'
import Timer from '@material-ui/icons/Timer'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import PlayCircleFilled from '@material-ui/icons/PlayCircleFilled'
import Stop from '@material-ui/icons/Stop'
import Delete from '@material-ui/icons/Delete'
import Cancel from '@material-ui/icons/Cancel'
import Grid from '@material-ui/core/Grid'
import CountItemsTable from './CountItemsTable'
import ManualEntry from './ManualEntry'
import LocationDetails from './LocationDetails'
import CircularProgress from '@material-ui/core/CircularProgress'
import GET_COUNTED_ITEMS from '../queries/GET_COUNTED_ITEMS'
// import Fab from '@material-ui/core/Fab'
import Save from '@material-ui/icons/Save'
import ms from 'pretty-ms'
import firebase from '../../firebaseConfig'
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';

const COUNT_LOCATION_DETAILS = gql`
  {
    countLocation @client {
      internalid
      name
      subsidiary
      subsidiaryname
    }
    employeeName
    memo
  }
`

const CountItemsTableWithClient = withApollo(CountItemsTable);
const ManualEntryWithClient = withApollo(ManualEntry)

export class CountItems extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loader: true,
      saveLoader: false,
      start: false,
      startTime: 0,
      time: 0,
      locationDetails: null,
      saveModal: false,
      errorModal: false,
      error: '',
      success: false,
      lastModValue:'',
      prevModValue:'',
      manualCount: 0,
      email: ''
    }

    this.startTimer = this.startTimer.bind(this)
    this.stopTimer = this.stopTimer.bind(this);
    this.getTotalCount = this.getTotalCount.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.saveData = this.saveData.bind(this);
    this.nextStep = this.nextStep.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleReset = this.handleReset.bind(this);
  }

  initial = () => {
    const { client } = this.props;

    this.setState({
      loader: true,
    })

    const data = client.readQuery({
      query: COUNT_LOCATION_DETAILS
    })

    client.writeData({
      data: {
        lastModified: '',
      }
    })

    this.setState({
      locationDetails: data,
      loader: false,
      saveModal: false,
      errorModal: false
    })
  }

  componentWillMount() {
    firebase.auth().onAuthStateChanged(user => {
      if(user) {
        this.setState({
          email: user.email
        })
      }
    })
  }

  componentDidMount() {
    this.initial();
  }

  startTimer() {
    this.setState({
      time: this.state.time,
      startTime: Date.now() - this.state.time
    })
    this.timer = setInterval(() => this.setState({
      time: Date.now() - this.state.startTime
    }), 1000)
  }

  stopTimer() {
    clearInterval(this.timer)
  }

  componentWillUnmount() {
    this.stopTimer()
    const { client } = this.props;

    client.writeData({
      data: { countDuration: this.state.time }
    })
  }

  getTotalCount() {
    let total = 0;
    const { getCountedItems: { countedItems } } = this.props;
    
    if(countedItems) {
      countedItems.forEach(item => {
        total += parseInt(item.quantity);
      });
      return total;
    }
    else {
      return 0;
    }
    
  }

  handleSave() {
    if (this.getTotalCount() === parseInt(this.state.manualCount)) {
      this.setState({
        saveModal: true
      })
    }
    else {
      this.setState({
        error: 'There is a variation between the scanned quantity and the manual count. Do you still want to proceed ?',
        errorModal: true
      })
    }
  }

  handleCancel() {
    const { client, getCountedItems: { countedItems } } = this.props;

    if(this.state.start || countedItems.length > 0) {

      if(!window.confirm("If you proceed, all the items that you have scanned will be removed.")) {
        return;
      }
    }

    this.stopTimer();

    this.setState({
      lastModValue: '',
      prevModValue: '',
      manualCount: 0,
      time: 0,
      start: false
    })

    client.writeData({
      data: {
        countLocation: null,
        countDuration: '',
        employeeName: '',
        memo: '',
        countStep: 0,
        countedItems: [],
        lastModified: ''
      }
    })
    // this.props.history.push('/count');
  }

  handleReset() {

    const { getCountedItems: { countedItems } } = this.props;

    if(this.state.start || countedItems.length > 0) {

      if(!window.confirm("If you proceed, all the items that you have scanned will be cleared out.")) {
        return;
      }
    }

    this.props.client.writeData({
      data: { countedItems: [], lastModified: '' }
    })
    this.stopTimer();
    
    this.setState({
      lastModValue: '',
      prevModValue: '',
      manualCount: 0,
      time: 0,
      start: false,
      saveModal: false,
      success: false
    })
  }

  saveData() {
    const { getCountedItems: { countedItems }, client } = this.props;
    const { locationDetails } = this.state;

    this.setState({
      saveLoader: true
    })
    this.stopTimer();

    let payload = {
      location: locationDetails.countLocation,
      memo: locationDetails.memo,
      employeeName: locationDetails.employeeName,
      date: new Date().toString(),
      scannedQty: this.getTotalCount(),
      manualCount: parseInt(this.state.manualCount),
      variance: parseInt(this.state.manualCount) - parseInt(this.getTotalCount()),
      countedItems: countedItems,
      savedToNetsuite: false,
      timeTaken: this.state.time,
      inventoryCountId: ''
    };

    /*
    firebase.database().ref('counting').limitToFirst(1).once('value', snapshot => {

      console.log(snapshot)
      let ref_child;
      snapshot.forEach(node => {
        ref_child = node.ref;
      })

      let countId = ref_child.ref.push().key;

      ref_child.ref.child(countId).set(payload, (err) => {

        if(err) {
          console.log(err)
        }

        client.writeData({
          data: { 
            lastCountId: countId,
            lastModified: '',
            countedItems: [],
            manualCount: 0
          }
        })
  
        this.setState({
          saveLoader: false,
          manualCount: 0,
          errorModal: false,
          success: true,
          time: 0,
          saveModal: true
        })
      })
    })
    .catch(err => {
      console.log(err)
    })
    */

    
    let countID = firebase.database().ref('counting').push().key;

    firebase.database().ref('counting/' + countID).set(payload, (err) => {
      if (err) {
        console.log(err)
      }

      client.writeData({
        data: { 
          lastCountId: countID,
          lastModified: '',
          countedItems: [],
          manualCount: 0
        }
      })

      this.setState({
        start: false,
        saveLoader: false,
        manualCount: 0,
        errorModal: false,
        success: true,
        time: 0,
        saveModal: true
      })

      // this.initial();

      // setTimeout(() => window.location.replace("/count"), 1000);
    })

  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props !== prevProps) {

      let x = this.props['getCountedItems']['countedItems'].length > 0 ?  
      this.props['getCountedItems']['countedItems']
      .filter(d => d['name'] === this.props.lastModified.lastModified).length > 0 ?
      this.setState({lastModValue: this.props['getCountedItems']['countedItems']
      .filter(d => d['name'] === this.props.lastModified.lastModified)[0]['quantity'],
      prevModValue: prevProps['getCountedItems']['countedItems'].length > 0 ?
      prevProps['getCountedItems']['countedItems']
      .filter(d => d['name'] === this.props.lastModified.lastModified).length > 0 ? 
      prevProps['getCountedItems']['countedItems']
      .filter(d => d['name'] === this.props.lastModified.lastModified)[0]['quantity'] : 
      0 : 0}):
      0 : 0 ;

    }
  }

  nextStep() {
    const { client } = this.props;
    const { countStep } = client.readQuery({
      query: gql`{ countStep @client}`
    })
    client.writeData({
      data: { countStep: parseInt(countStep) + 1 }
    })
  }

  render() {
    const { classes } = this.props;
    
    return (
      <div className={classes.root} >
        {
          this.state.loader ?
            <CircularProgress />
            :
            <Grid container spacing={24} >
              <Grid item xs={12} >
                {/* <Paper> */}
                <LocationDetails locationDetails={this.state.locationDetails} />
                {/* </Paper> */}
              </Grid>
              <Grid item xs={12} sm={9} style={{ marginTop: "20px" }} >
                <Grid container justify="flex-start" className={classes.buttonsContainer} >
                  <Grid item xs={5} sm={"auto"} className={classes.startButton} >
                    {
                      this.state.start ?
                        <Button variant="contained" size="small" aria-label="Stop Scan"
                          className={classes.actionButton}
                          onClick={(e) => {
                            this.setState({ start: false })
                            this.stopTimer()
                          }}
                        >
                          Stop Scan
                          <Stop className={classes.rightIcon} />
                        </Button>
                        :
                        <Button variant="contained" color="secondary" size="small" aria-label="Start Scan"
                          className={classes.actionButton}
                          onClick={(e) => {
                            this.setState({ start: true })
                            this.startTimer()
                          }}
                        >
                          Start Scan
                          <PlayCircleFilled className={classes.rightIcon} />
                        </Button>
                    }
                  </Grid>
                  <Grid item xs={5} sm={"auto"} className={classes.manualEntryButton} >
                    <ManualEntryWithClient disabled={!this.state.start} />
                  </Grid>
                  <Grid item xs={5} sm={"auto"} className={classes.saveButton} >
                    <Button variant="contained" size="small" className={classes.fabGreen + " " + classes.actionButton}
                      onClick={(e) => { this.handleSave() }}
                      disabled={this.getTotalCount() === 0}
                    >
                      Save
                      <Save className={classes.rightIcon} />
                    </Button>
                  </Grid>
                  <Grid item xs={5} sm={"auto"} className={classes.cancelButton} >
                    <Button variant="contained" size="small" className={classes.fabRed + " " + classes.actionButton}
                      onClick={(e) => { this.handleCancel() }}
                    >
                      Cancel
                      <Cancel className={classes.rightIcon} />
                    </Button>
                  </Grid>
                  <Grid item xs={5} sm={"auto"} className={classes.clearButton} >
                    <Button variant="outlined" size="small" className={classes.rightIcon, classes.actionButton}
                      style={{ marginLeft: "0" }}
                      onClick={(e) => { this.handleReset() }}
                    >
                      Clear All
                      <Delete className={classes.rightIcon} />
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={3} style={{ marginTop: "20px" }} >
                <Grid container style={{ padding: "0 20px" }} >
                  <Grid item xs={12} >
                    <p className={classes.timer}>
                      <strong>
                        <Timer style={{ fontSize: "56px", verticalAlign: "middle" }} />
                      </strong>
                      <span style={{ color: "red" }} > {ms(this.state.time)} </span>
                    </p>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} >
                {/* <Paper>  */}
                <Grid container justify="space-between" spacing={24} className={classes.quantities} >
                  <Grid item xs={12} style={{ textAlign: "left" }} >
                    <strong> Total Quantity: </strong> <span> {this.getTotalCount()} </span>
                  </Grid>
                  <Grid item xs={12} style={{ textAlign: "left" }} >
                    <strong style={{ verticalAlign: "middle" }} > Manual Count: </strong>
                    <TextField
                      inputProps={{ type: 'text' }}
                      value={ parseInt(this.state.manualCount)}
                      onChange={(e) => {
                        e.preventDefault();
                        let val = e.target.value;
                        if (parseInt(val) > 0) {
                          this.setState({manualCount: parseInt(e.target.value)})
                        }
                        else {
                          this.setState({manualCount: 0})
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} style={{ textAlign: "left" }} >
                    <div><strong> Last Modified Item: </strong> {this.props.lastModified.lastModified}</div>
                  </Grid>
                  <Grid item xs={12} style={{ textAlign: "left" }} >
                    <div><strong> Last Modified Quantity: </strong> 
                    { this.state.prevModValue !== '' ? 
                      this.state.prevModValue + " to " + this.state.lastModValue : 
                      ""
                    }
                    </div>
                  </Grid>
                </Grid>
                {/* </Paper> */}
              </Grid>
              <Grid item xs={12} >
                <CountItemsTableWithClient start={this.state.start} />
              </Grid>
            </Grid>
        }
        <Modal open={this.state.saveModal} >
          <div style={getModalStyle()} className={classes.paper} >
            {
              this.state.success ?
                <div>
                  <Typography variant="subtitle1" style={{}} id="simple-modal-description">
                    {
                      (this.state.email === "countingmaster_dev@oakandfort.com" || this.state.email === "countingmaster@oakandfort.com" ) ? 
                      <span style={{ color: "green" }} >
                        Your data has been saved. Click Next to Submit/Verify FIC or Exit to do another count.
                      </span>
                      :
                      <span style={{ color: "green" }} >
                        Your data has been saved. Click Exit to do another count.
                      </span>
                    }
                    
                  </Typography>
                  {
                    this.state.saveLoader ?
                      <CircularProgress />
                      :
                      <div>
                        {
                          (this.state.email === "countingmaster_dev@oakandfort.com" || this.state.email === "countingmaster@oakandfort.com" ) ?
                          <Button style={{ marginTop: "20px" }} color="primary" variant="contained" size="small"
                            onClick={(e) => { this.nextStep() }}
                          >
                            Next
                          </Button>
                          :
                          null
                        }
                        
                        <Button style={{ marginTop: "20px", marginLeft: "20px" }} color="primary" variant="outlined" size="small"
                          onClick={(e) => { 
                            this.handleCancel();
                          }}
                        >
                          Exit
                        </Button>
                      </div>
                  }
                </div>
                :
                <div>
                  <Typography variant="subtitle1" style={{}} id="simple-modal-description">
                    <span>
                      Are you sure you want to save your count ?
                    </span>
                  </Typography>
                  {
                    this.state.saveLoader ?
                      <CircularProgress />
                      :
                      <div>
                        <Button style={{ marginTop: "20px" }} color="primary" variant="contained" size="small"
                          onClick={(e) => { this.saveData() }}
                        >
                          Yes
                        </Button>
                        <Button style={{ marginTop: "20px", marginLeft: "20px" }} color="primary" variant="outlined" size="small"
                          onClick={(e) => { this.setState({ saveModal: false }) }}
                        >
                          No
                        </Button>
                      </div>
                  }
                </div>
            }
          </div>
        </Modal>
        <Modal open={this.state.errorModal} >
          <div style={getModalStyle()} className={classes.paper} >
            <Typography variant="subtitle1" style={{ color: "red" }} id="simple-modal-description">
              {this.state.error}
            </Typography>
            {
              this.state.saveLoader ?
                <CircularProgress />
                :
                <div>
                  <Button style={{ marginTop: "20px" }} color="primary" variant="contained" size="small"
                    onClick={(e) => { this.saveData() }}
                  >
                    Yes
                  </Button>
                  <Button style={{ marginTop: "20px", marginLeft: "20px" }} color="primary" variant="outlined" size="small"
                    onClick={(e) => { this.setState({ errorModal: false }) }}
                  >
                    No
                  </Button>
                </div>
            }
          </div>
        </Modal>
      </div>
    )
  }
}

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const styles = theme => ({
  root: {
    width: '100%',
    margin: "0 auto",
    paddingBottom: '100px'
    // marginTop: theme.spacing.unit * 3,
  },
  paper: {
    position: 'absolute',
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: 'none',
    textAlign: "center",
    [theme.breakpoints.down('xs')]: {
      width: theme.spacing.unit * 40,
      padding: theme.spacing.unit * 3
    }
  },
  tranDetails: {
    flexBasis: "100%",
    textAlign: "left",
    margin: "0",
    fontSize: "12px"
  },
  rightIcon: {
    marginLeft: theme.spacing.unit / 2,
  },
  fabGreen: {
    color: theme.palette.common.white,
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[600],
    }
  },
  fabRed: {
    color: theme.palette.common.white,
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[600],
    }
  },
  timer: {
    fontSize: "24px", 
    textAlign: "right",
    [theme.breakpoints.down('xs')]: {
      fontSize: "20px",
      textAlign: "center"
    }
  },
  quantities: {
    padding: "0 20px",
    [theme.breakpoints.down('xs')]: {
      padding: "0 10px",
    }
  },
  buttonsContainer: {
    [theme.breakpoints.down('xs')]: {
      padding: "0 10px",
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      height: "100%"
    }
  },
  startButton: {
    display: "flex", 
    alignItems: "center",
    margin: "5px 10px 5px",
    [theme.breakpoints.down('xs')]: {
      margin: "10px 0",
    }
  },
  manualEntryButton: {
    display: "flex", 
    alignItems: "center", 
    margin: "5px 10px 5px 0",
    [theme.breakpoints.down('xs')]: {
      margin: "10px 0",
    }
  },
  saveButton: {
    display: "flex", 
    alignItems: "center", 
    margin: "5px 10px 5px 0",
    [theme.breakpoints.down('xs')]: {
      margin: "10px 0",
    }
  },
  cancelButton: {
    display: "flex", 
    alignItems: "center", 
    margin: "5px 10px 5px 0",
    [theme.breakpoints.down('xs')]: {
      margin: "10px 0",
    }
  },
  clearButton: {
    display: "flex", 
    alignItems: "center", 
    margin: "5px 10px 5px 0",
    [theme.breakpoints.down('xs')]: {
      margin: "10px 0",
    }
  },
  actionButton: {
    [theme.breakpoints.down('xs')]: {
      width: "150px"
    }
  }
})

CountItems.propTypes = {
  classes: PropTypes.object.isRequired
}

const ComposedComponent = compose(
  graphql(gql`{lastModified @client}`, { name: 'lastModified' }),
  graphql(gql`{manualCount @client}`, { name: 'manualCount' }),
  graphql(GET_COUNTED_ITEMS, { name: 'getCountedItems' }),
)(CountItems)

export default withStyles(styles)(ComposedComponent)
