import React, { Component } from 'react'
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

export class IncludeAllCounts extends Component {

    constructor(props) {
        super(props);

        this.state = {
            selectAll: false
        }
    }

    render() {
        const { client } = this.props;

        return (
            <div>
                <Typography variant="h5" style={{ marginTop: "40px", fontSize: '1.25rem' }}  >
                    Select the counts you would like to include in FIC:
                </Typography>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={this.state.selectAll}
                            onChange={(e) => {
                                // includeAllCounts({ variables: { checked: e.target.checked } })
                                client.writeData({
                                    data: {
                                        includeAllFic: e.target.checked
                                    }
                                })
                                this.setState({
                                    selectAll: !this.state.selectAll
                                })
                            }}
                            color="secondary"
                        />
                    }
                    label="Include All"
                    color="secondary"
                />
            </div>
        )
    }
}

export default IncludeAllCounts
