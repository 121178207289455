import ITEM_DETAILS from '../../Receiving/queries/ITEM_DETAILS'
import GET_COUNTED_ITEMS from '../queries/GET_COUNTED_ITEMS'

const newItemUpc = (_, { upccode, locationid }, { cache }) => {

    const token = sessionStorage.getItem('token');
    
    const { findItemDetails } = cache.readQuery({
        query: ITEM_DETAILS,
        variables: { upccode: upccode , token: token, locationid: locationid }
    })

    const previousState = cache.readQuery({
        query: GET_COUNTED_ITEMS
    })

    let exists = false;

    previousState.countedItems.forEach((element) => {
        if(element.upccode === upccode) {
            exists = true;
            cache.writeQuery({
                query: GET_COUNTED_ITEMS,
                data: {
                    countedItems: previousState.countedItems.map((item) => {
                        if (item.upccode !== upccode) {
                            return item
                        }
                        return {
                            ...item,
                            quantity: parseInt(item.quantity) + 1,
                        }
                    })
                }
            })
        }
    });

    if(!exists) {
        const newItem = {
            quantity: 1,
            internalid: findItemDetails.internalid,
            name: findItemDetails.name.split(':')[1].trim() ,
            upccode: findItemDetails.upccode,
            location_average_cost: findItemDetails.location_average_cost ? findItemDetails.location_average_cost : findItemDetails.average_cost,
            on_hand: parseInt(findItemDetails.on_hand)
            // __typename: "ItemDetails"
        }
    
        cache.writeQuery({
            query: GET_COUNTED_ITEMS,
            data: {
                countedItems: [
                    newItem,
                    ...previousState.countedItems,
                ]
            }
        })    
    }

    return null
  }

  export default newItemUpc