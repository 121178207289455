import React, { Component } from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl'
import Input from '@material-ui/core/Input';
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper'
import { compose, graphql } from 'react-apollo'
import SET_QUANTITY_UPC from '../mutations/SET_QUANTITY_UPC'
import CircularProgress from '@material-ui/core/CircularProgress'
import Modal from '@material-ui/core/Modal'
import BarcodeReader from 'react-barcode-reader';
// import gql from 'graphql-tag'
import TOGGLE_CONFIRM_DIFF from '../mutations/TOGGLE_CONFIRM_DIFF'
import TOGGLE_ALL_CONFIRM_DIFF from '../mutations/TOGGLE_ALL_CONFIRM_DIFF'
import WRITE_LINE_NOTES from '../mutations/WRITE_LINE_NOTES'
import SCANNED_ITEMS from '../queries/SCANNED_ITEMS'
// import ITEM_DETAILS from '../queries/ITEM_DETAILS'
import ADD_ITEM_UPC from '../mutations/ADD_ITEM_UPC'
import firebase from '../../firebaseConfig';


const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    letterSpacing: "1px",
    textTransform: "uppercase"
  }
}))(TableCell);

export class TransactionItemsTable extends Component {

  constructor(props) {
    super(props);

    this.state = {
      error: '',
      open: false,
      selectAll: false,
      loader: false
    }

    this.handleScan = this.handleScan.bind(this);
    this.handleError = this.handleError.bind(this);
    this.fetchItemDetails = this.fetchItemDetails.bind(this);
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
  }

  handleError(err) {
    console.log(err)
  }

  handleScan(data, orderDetails) {
    const { client, scannedItemsQuery: { scannedItems }, startScan } = this.props;
    const self = this;

    if (startScan) {

      if (data.split("").length > 9) {

        this.setState({
          loader: true
        })

        let parsedItems = JSON.parse(scannedItems);

        // Item already in the table
        if (parsedItems[data]) {
          parsedItems[data].actualqty = parseInt(parsedItems[data].actualqty) + 1;
          client.writeData({
            data: {
              scannedItems: JSON.stringify(parsedItems),
              lastScanned: parsedItems[data].name.split(":")[1].trim()
            }
          })
          this.setState({
            loader: false
          })
        }
        else {
          // item not in the table
          firebase.auth().onAuthStateChanged(user => {
            if (user && self._isMounted) {

              // let db_node = firebase.database().ref(`${user.uid}/physical_stores/store_node`);
              let db_node = firebase.database().ref(`receiving/physical_stores/store_node`);

              db_node.child(orderDetails.receivinglocation.locationname).child('search_item').child(orderDetails.documentnumber.toUpperCase()).remove()
                .then(() => {

                  db_node.child(orderDetails.receivinglocation.locationname).child('search_item').update({
                    [orderDetails.documentnumber.toUpperCase()]: {
                      store: orderDetails.receivinglocation.locationname,
                      item: { upccode: data },
                      transaction: orderDetails.documentnumber,
                      status: "submit"
                    }
                  })
                    .then(() => {
                      self.fetchItemDetails(orderDetails, db_node, data);
                    })
                    .catch(err => {
                      console.error(err);
                      alert(err);
                    })
                })
                .catch(err => {
                  console.error(err);
                  alert(err);
                })
            }
          })
        }
      }
    }
    else {
      this.setState({
        error: 'Click on "Start Scan" before scanning items',
        open: true
      })

    }
  }

  fetchItemDetails(orderDetails, db_node, data) {

    const self = this;
    const { addItemUpc, client, scannedItemsQuery: { scannedItems } } = this.props;

    let retrieved_node = db_node.child(orderDetails.receivinglocation.locationname).child('search_item').child(orderDetails.documentnumber);

    retrieved_node.on('value', (snapshot) => {

      if (self._isMounted && snapshot.val() && snapshot.val() !== null) {

        if (snapshot.val().status === "done" && !snapshot.val().item) {

          let parsedItems = JSON.parse(scannedItems);
          let itemDetails = snapshot.val().data;

          if(!parsedItems[data]) {
            client.writeData({
              data: { 
                  itemDetails: JSON.stringify(itemDetails),
                  lastScanned: itemDetails.name.split(":")[1].trim()
              }
            });
  
            addItemUpc({ variables: { upccode: data, locationid: orderDetails.receivinglocation.locationid + "" } })
          }

          this.setState({
            loader: false
          })
         
        }
      }
    })


    /*
                    client.query({
                      query: ITEM_DETAILS,
                      variables: { upccode: data, token: idToken, locationid: orderDetails.receivinglocation.locationid + "" }
                    }).then(({ data: { findItemDetails } }) => {
                      if (findItemDetails.err !== null && findItemDetails.err !== '') {
                        console.error(findItemDetails.err)
                        alert('Something wrong! Scan again')
                      }
                      else {
                        addItemUpc({ variables: { upccode: data, locationid: orderDetails.receivinglocation.locationid + "" } })
                        this.props.client.writeData({
                          data: { lastScanned: findItemDetails.name.split(":")[1].trim() }
                        })
                      }
                      this.setState({
                        loader: false
                      })
                    })
                    */
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { scannedItemsQuery: { scannedItems }, setQuantityUpc, lastScanned, startScan, client, classes } = this.props;
    const { writeLineNotes, toggleConfirmDiff, toggleAllConfirmDiff, orderDetails } = this.props;

    let parsedItems = JSON.parse(scannedItems)
    return (
      <div>
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <CustomTableCell padding="dense" >Item ID</CustomTableCell>
                <CustomTableCell padding="dense">Item Name</CustomTableCell>
                <CustomTableCell padding="dense">UPC Code</CustomTableCell>
                <CustomTableCell padding="dense">Price</CustomTableCell>
                <CustomTableCell padding="dense">Expected Quantity</CustomTableCell>
                <CustomTableCell padding="dense">Actual Quantity</CustomTableCell>
                <CustomTableCell padding="dense">Difference</CustomTableCell>
                <CustomTableCell padding="dense">
                  Two-factor Verification
                  <Checkbox
                    checked={this.state.selectAll}
                    classes={{
                      root: classes.root,
                      checked: classes.checked
                    }}
                    onChange={(e) => {
                      this.setState({
                        selectAll: e.target.checked
                      })
                      toggleAllConfirmDiff({ variables: { checked: e.target.checked } });
                    }}
                  />
                </CustomTableCell>
                <CustomTableCell padding="dense">Notes</CustomTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                Object.keys(parsedItems).map(function (key) {
                  let item = parsedItems[key];

                  if (parseInt(item.expectedqty) === 0 && parseInt(item.actualqty) === 0) {
                    return null
                  }
                  else {
                    return (
                      <TableRow key={item.internalid} >
                        <TableCell padding="dense" > {item.internalid} </TableCell>
                        <TableCell padding="dense" > {item.name} </TableCell>
                        <TableCell padding="dense"> {item.upccode} </TableCell>
                        <TableCell padding="dense"> {item.baseprice} </TableCell>
                        <TableCell padding="dense"> {item.expectedqty} </TableCell>
                        <TableCell
                          className={(lastScanned === item.upccode || lastScanned === item.name.split(":")[1].trim()) ? "blink" : ""} padding="dense" >
                          {
                            startScan ?
                              <FormControl variant="outlined" >
                                <Input
                                  type="number"
                                  name={item.upccode}
                                  value={parseInt(item.actualqty)}
                                  onChange={(e) => {
                                    e.preventDefault();
                                    let val = parseInt(e.target.value)

                                    if (val >= 0) {

                                      setQuantityUpc({
                                        variables: {
                                          upccode: item.upccode,
                                          quantity: val
                                        }
                                      })

                                      //  e.target.blur();
                                      client.writeData({ data: { lastScanned: item.name.split(":")[1].trim() } })
                                    }
                                    else {
                                      setQuantityUpc({
                                        variables: {
                                          upccode: item.upccode,
                                          quantity: 0
                                        }
                                      })
                                      // e.target.blur();
                                      client.writeData({ data: { lastScanned: item.name.split(":")[1].trim() } })
                                    }

                                  }}
                                />
                              </FormControl>
                              :
                              item.actualqty
                          }
                        </TableCell>
                        <TableCell padding="dense"> {parseInt(item.actualqty) - parseInt(item.expectedqty)} </TableCell>
                        <TableCell padding="dense">
                          {
                            parseInt(item.actualqty) - parseInt(item.expectedqty) === 0 ?
                              null
                              :
                              <p>
                                <Checkbox
                                  checked={item.verified}
                                  onChange={(e) => {
                                    toggleConfirmDiff({
                                      variables: { upccode: item.upccode, checked: e.target.checked }
                                    })
                                  }}
                                  value={item.internalid}
                                  color="primary"
                                />
                                Confirm Difference
                          </p>
                          }
                        </TableCell>
                        <TableCell padding="dense">
                          <TextField
                            value={item.notes}
                            type="text"
                            placeholder="Enter any notes"
                            name="email"
                            variant="outlined"
                            onChange={
                              (e) => {
                                writeLineNotes({ variables: { upccode: item.upccode, value: e.target.value } })
                              }}
                          />
                        </TableCell>
                      </TableRow>
                    )
                  }
                })
              }
            </TableBody>
          </Table>
          <BarcodeReader
            onScan={
              (data) => {
                this.handleScan(data, orderDetails)
              }
            }
            // onError={(err) => this.handleError(err)}
            onError={(data) => {
              this.handleScan(data, orderDetails)
            }
            }
          />
          {
            this.state.loader ?
              <div style={{ textAlign: "center" }}>
                <CircularProgress />
              </div>
              :
              null
          }
        </Paper>
        <Modal
          open={this.state.open}
        >
          <div style={getModalStyle()} className={classes.paper}>
            <Typography variant="subtitle1" style={{ color: "red" }} id="simple-modal-description">
              {this.state.error}
            </Typography>
            <Button style={{ marginTop: "20px" }} color="primary" variant="outlined" size="small"
              onClick={(e) => { this.setState({ open: false, error: '' }) }}
            >
              OK
            </Button>
          </div>
        </Modal>
      </div>
    )
  }
}

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const styles = theme => ({
  paper: {
    position: 'absolute',
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: 'none',
    textAlign: "center"
  },
  root: {
    color: "#fff",
    '&$checked': {
      color: "#fff",
    },
  },
  checked: {},
})

const ComposedTable = compose(
  graphql(SET_QUANTITY_UPC, { name: 'setQuantityUpc' }),
  graphql(SCANNED_ITEMS, { name: 'scannedItemsQuery' }),
  graphql(TOGGLE_CONFIRM_DIFF, { name: 'toggleConfirmDiff' }),
  graphql(TOGGLE_ALL_CONFIRM_DIFF, { name: 'toggleAllConfirmDiff' }),
  graphql(WRITE_LINE_NOTES, { name: 'writeLineNotes' }),
  graphql(ADD_ITEM_UPC, { name: 'addItemUpc' }),
)(TransactionItemsTable)

export default withStyles(styles)(ComposedTable)
